import React, { useRef, useState } from "react";
import { Box, Button, styled } from "@mui/material";
import { AttachFile, Close } from "@mui/icons-material";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
interface DragAndDropFileUploadWithBrowseProps {
    onChange: (fileData: any) => void;
    defaultValue: any;
    name: string;
    keyName: string;
    isRequired: boolean;
    label: string;
    multiple?: boolean;
    errorStr: string;
    isCurrentFiles?: any[];
    customHeight?: string;
    handleRemove: (fileData: any) => void;
    handleCurrentFilesRemove: (fileData: any) => void;
    handleOpen?: (id: any) => void;
}
const DragAndDropFileUploadWithBrowse: React.FC<
    DragAndDropFileUploadWithBrowseProps
> = ({
    onChange,
    defaultValue,
    name,
    keyName,
    isRequired,
    label,
    multiple,
    errorStr,
    customHeight,
    handleRemove,
    isCurrentFiles,
    handleCurrentFilesRemove,
    handleOpen,
}) => {
        const [files, setFiles] = useState<any[]>(defaultValue);
        const fileInputRef = useRef<HTMLInputElement>(null);
        // console.log(`isCurrentFiles`);
        // console.log(isCurrentFiles);
        // console.log(`isCurrentFiles`);
        const removeFile = (fileData: any) => {
            const updatedFiles = files.filter((file) => file !== fileData); // Create a new array
            setFiles(updatedFiles);
            if (updatedFiles.length === 0 && fileInputRef.current) {
                fileInputRef.current.value = ""; // Reset the file input
            }
            handleRemove(updatedFiles);
        };

        const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            const filesArr: any = event.target.files;
            if (filesArr) {
                setFiles((prevFiles) => [...prevFiles, ...Array.from(filesArr)]);
                onChange(Array.from(filesArr));
            }
        };

        return (
            <div className="mb-3 fileUploadComponent" key={"autoC_" + keyName}>
                <label className="form-label" key={"lbk_auto_" + keyName}>
                    {label}
                    {isRequired && (
                        <small key={"sml_auto_" + keyName} style={{ color: "red" }}>
                            {" "}
                            *
                        </small>
                    )}
                </label>
                {isCurrentFiles && isCurrentFiles.length > 0 && (
                    <div>
                        <div className="fileElement-root-current">
                            {Array.from(isCurrentFiles).map((fileData: any) => {
                                return (
                                    <Box className="fileElement">
                                        <Box sx={{textOverflow:'ellipsis',overflow:'hidden',height:'65px'}}
                                            
                                            onClick={() => {
                                                if (handleOpen) {
                                                    handleOpen(fileData.id);
                                                }
                                            }}
                                        >
                                            {fileData.name}

                                        </Box>
                                        <Close
                                            className="fileElementClose"
                                            onClick={() => {
                                                handleCurrentFilesRemove(fileData);
                                            }}
                                        />
                                    </Box>

                                );
                            })}
                        </div>
                    </div>
                )}
                <CompWrapper>
                    <div className="mt-2">
                        <CloudUploadOutlinedIcon />
                    </div>
                    <div className="mt-2">Choose file to upload</div>
                    <div className="mt-2 mb-2">
                        <small>PNG,JPG & Document Formats,Upto 50MB</small>
                    </div>
                    <Button
                        component="label"
                        role={undefined}
                        color="secondary"
                        variant="contained"
                        tabIndex={-1}
                    >
                        Browse
                        <VisuallyHiddenInput
                            type="file"
                            accept=".doc,.docx,pdf,.pdfs,.pdf,image/*,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                            onChange={handleFileChange}
                            multiple={multiple}
                        />
                    </Button>
                </CompWrapper>

                <Box

                    style={{
                        top: isCurrentFiles && isCurrentFiles.length > 0 ? "120px" : "30px",
                    }}
                >
                    {files.map((fileData: any) => {
                        return (
                            <span className="fileElement">
                                {fileData.name}
                                <Close
                                    className="fileElementClose"
                                    onClick={() => {
                                        removeFile(fileData);
                                    }}
                                />
                            </span>
                        );
                    })}
                </Box>

                {errorStr && <span className="bad-field">{errorStr}</span>}
            </div>
        );
    };
const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
});
const CompWrapper = styled("div")({
    background: "rgba(0, 244, 255, 0.08)",
    border: "1px dashed black",
    padding: "20px",
    margin: "0 auto",
    textAlign: "center",
    borderRadius: "2px",
});
export default DragAndDropFileUploadWithBrowse;
