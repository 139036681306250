
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import { GridSearchIcon } from '@mui/x-data-grid';
interface FilterComponentProps {
    onSearch: (
      event: any
    ) => void;
  }
  const FilterComponent: React.FC<FilterComponentProps> = ({ onSearch: onSearch }) => {
  return (
    <Paper
      component="form"
      sx={{ p: '2px 4px', display: 'flex', alignItems: 'center',backgroundColor:'#000b29', width: 350,border: '1px solid #848EA9', marginLeft:'28px',height:'43px',boxShadow:'none' }}
    >
        <IconButton type="button" sx={{ p: '10px',color:'#848EA9' }} aria-label="search" >
        <GridSearchIcon sx={{color:'#848EA9'}}  />
      </IconButton>
      <InputBase
        sx={{ ml: 1, flex: 1,color:'#848EA9', }}
        placeholder="Search"
        inputProps={{ 'aria-label': 'search' }}
        onChange={onSearch}
      />
    
      
     
    </Paper>
  );
}
export default FilterComponent;