import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Divider } from '@mui/material';
import ReasonComponent from './ReasonComponent';
import {
    List,
    ListItem,
    ListItemIcon,
    Checkbox,
    ListItemText,
  } from "@mui/material";
  import ListItemButton from '@mui/material/ListItemButton';
interface WarningDialogProps {
    handleClose: (

        type: string,
        reasons?: any[]
    ) => void;
    open: boolean;
    message: string;
    buttonTitle: string;
    title: string;
}
const WarningDialog: React.FC<WarningDialogProps> = ({ handleClose, open, message, buttonTitle, title }) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const reasonsNormal = [
        "Margin",
        "T&C's",
        "Resources",
        "Project details",
        "Missing info/documents",
      ];
      const reasonsClient = [
        "Price",
        "Not submitted on time",
        "Excessive Risk",
        "Unable meet customer timeline",
        "Unable to deliver - skill shortage",
        "Asset unable",
        "Location",
        "Material shortage",
        "Other",
      ];
      const reason = buttonTitle === 'Quote Rejected' ? reasonsClient : reasonsNormal;
    const [selectedReasons, setSelectedReasons] = React.useState<any[]>([]);
    
    const handleReason = async (data: any) => {
      
        setSelectedReasons((prevItems) => {
            const newItems = [...prevItems];
            if (!newItems.includes(data)) {
              newItems.push(data);
            } else{
              newItems.splice(newItems.indexOf(data),1);
            }
            return newItems;
          });
        
      }
      const renderReasons = ()=> {
      return  (<List sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}>
      {reason.map((value) => {
        const labelId = `checkbox-list-label-${value}`;

        return (
          <ListItem key={value} disablePadding>
            <ListItemButton
              className="custom-list-item" 
              onClick={()=>{
                handleReason(value)
              }}>
              <ListItemIcon>
                <Checkbox
                  edge="start"
                  checked={selectedReasons.includes(value)}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ "aria-labelledby": labelId }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={value} />
            </ListItemButton>
          </ListItem>
        );
      })}
    </List>)
      }
    return (
        <React.Fragment>
            <Dialog
                fullScreen={fullScreen}
                open={open}
                onClose={()=>{
                    handleClose('Cancel');
                }}
                aria-labelledby="responsive-dialog-title"
               
            >
                <DialogTitle id="responsive-dialog-title" fontWeight={'bold'} fontSize={'20px'}  sx={{color: '#000000'}}> 
                    {title}
                </DialogTitle>
                <IconButton
          aria-label="close"
          onClick={()=>{
            handleClose('Cancel')
          }}
          sx={(theme) => ({
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
          })}
        >
          <CloseIcon />
        </IconButton>
                <DialogContent >
                    <DialogContentText>
                        {message} 
                    </DialogContentText>
                    {buttonTitle.indexOf('Reject')!== -1  && renderReasons()}
                </DialogContent>
                <Divider />
                <DialogActions >
                <Button  sx={{color:'#00f4ff !important' ,backgroundColor:'#000', }} onClick={() => {
                     handleClose('Cancel')
                    }} autoFocus>
                        {'Cancel'}
                    </Button>
                    <Button  sx={{color:'#00f4ff !important' ,backgroundColor:'#000', }} onClick={() => {
                        if(buttonTitle.indexOf('Reject')!== -1) {
                            handleClose(buttonTitle,selectedReasons)
                        }else{
                            handleClose(buttonTitle)
                        }
                       
                    }} autoFocus>
                        {buttonTitle}
                    </Button>
                  
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}
export default WarningDialog;