"use client";
import React, { useEffect, useState } from "react";
import "./Index.css";
import SideBar from "../../../components/Sidebar";
import Header from "../../../components/Header";
import { useNavigate, useParams } from "react-router-dom";
import PageTitle from "../../../components/PageTitle";
import Constants from "../../../utils/contants";
import HttpService from "../../../services/HttpService";
import { useStore } from "../../../utils/store";
import TextFieldComponent from "../../../components/form/TextFieldComponent";
import RadioCheckBoxFieldComponent from "../../../components/form/RadioCheckBoxFields";
import TextFieldAreaComponent from "../../../components/form/TextFieldAreaComponent";
import PrimaryButtonComponent from "../../../components/form/PrimaryButtonComponent";
import ResetButtonComponent from "../../../components/form/ResetButtonComponent";
import { Box, Paper, Stack } from "@mui/material";
import Grid from "@mui/material/Grid2";
enum InductionStatus {
  NotApplicable = "0",
  Yes = "1",
  No = "2",
}
const InductionList = [{
  id: InductionStatus.Yes, label: 'Yes'
},
{ id: InductionStatus.No, label: 'No' },
{
  id: InductionStatus.NotApplicable, label: 'N/A',
}];
const ContractorForm: React.FC = () => {
  const { getAdmin } = useStore();
  const { currentId } = useParams<{ currentId: any }>();
  const navigate = useNavigate();


  // State Management
  const [errorMessage, setErrorMessage] = useState<string | null>(null);



  const [loading, setLoading] = useState(false);

  const [currentRecord, setCurrentRecord] = useState<any>(null);

  const [contractorForm, setContractorForm] = useState({
    name: "",
    id: null,
    email: "",
    address: "",
    phone: "",
    contractorID: "",
    creditLimit: "",
    notes: "",
    completed: InductionStatus.NotApplicable,
    abn: "",
  });
  const [errors, setErrors] = useState({
    name: "",
    email: "",
    phone: "",
    contractorID: "",
    abn: ""
  });


  useEffect(() => {
    if (currentId) {
      if (currentId !== 'Add') {
        fetchSingleRecord(currentId);
      }
    } else {
      setCurrentRecord(null);
    }
  }, [currentId]);
  // Fetch Data

  const fetchSingleRecord = async (id: any) => {
    try {
      const response = await HttpService.get(
        Constants.apiURL + Constants.contractorsURL + '/' + id,
        { headers: { Authorization: "Bearer " + getAdmin() } },
        null
      );
      if (response.success) {

        handleRowClick(response.contractor);
      } else {
        setErrorMessage("Failed to Fetch Contractor. Please try again.");
      }

      console.log("GET Response:", response);
    } catch (error) {
      setErrorMessage("Failed to Fetch Contractor. Please try again.");
      console.error("Error fetching data:", error);
    }
  };
  // Form Handling
  const handeContractorFormChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setContractorForm((prev) => ({ ...prev, [name]: value }));
    if (name !== 'address' && name !== 'completed' && name !== 'notes' && name !== 'creditLimit') {
      validateContractorFormField(e.target);
    }

  };



  const clearForm = () => {

    setContractorForm({
      name: "",
      email: "",
      address: "",
      phone: "",
      contractorID: "",
      creditLimit: "",
      notes: "",
      completed: InductionStatus.NotApplicable,
      abn: "",
      id: null
    });
    setErrorMessage(null);
    setCurrentRecord(null);
    navigate('/management/contractors')
  };

  const handleSave = async () => {

    if (validateContractorForm()) {
      setLoading(true);
      const endpoint = Constants.apiURL + Constants.contractorsURL;
      if (currentRecord) {
        contractorForm['id'] = currentRecord.id;
      }
      let response: any;
      try {
        if (currentRecord) {
          response = await HttpService.put(endpoint, contractorForm, {
            headers: { Authorization: `Bearer ${getAdmin()}` },
          });
        } else {
          response = await HttpService.post(endpoint, contractorForm, {
            headers: { Authorization: `Bearer ${getAdmin()}` },
          });
        }
        if (response.success) {
          clearForm();
        } else {
          setErrorMessage(Constants.COMMON_SERVER_ERROR);
        }
      } catch {
        setErrorMessage(Constants.COMMON_SERVER_ERROR);
      } finally {
        setLoading(false);
      }
    }

  };

  const handleRowClick = (record: any) => {
    setCurrentRecord(record);
    setContractorForm({ ...record });
    //validateContractorForm();

  };


  const checkEmail = (email: string) => {
    return new Promise(async (resolve, reject) => {
      try {
        let url = Constants.apiURL + Constants.contractorsURL + '/findByEmail/' + email;
        if (currentRecord) {
          url = url + '?contractorId=' + currentRecord.id
        }
        const response = await HttpService.get(
          url,
          { headers: { Authorization: "Bearer " + getAdmin() } },
          null
        );
        return resolve(response.contractor);
      } catch (error) {
        return resolve(null);
      }
    })

  };
  const validateContractorFormField = async (element: any) => {
    const elementName = element.name;
    const newErrors: any = {};
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phoneRegex = /^(\+?\(61\)|\(\+?61\)|\+?61|\(0[1-9]\)|0[1-9])?( ?-?[0-9]){7,9}$/; // Validates a 10-digit phone number
    switch (elementName) {
      case 'name':
        if (!element.value.trim()) {
          newErrors.name = "Firstname is required.";
        }
        break;
      case 'contractorID':
        if (!element.value.trim()) {
          newErrors.contractorID = "ContractorID is required.";
        }
        break;
      case 'email':
        if (!element.value) {
          newErrors.email = "Email is required.";
        } else if (!emailRegex.test(element.value)) {
          newErrors.email = "Invalid email address.";
        } else {
          const isEmailExists = await checkEmail(element.value);
          console.log(isEmailExists);
          if (isEmailExists) {
            newErrors.email = "Email ID already Exists";
          } else {
            newErrors.email = '';
          }
        }
        break;

      case 'phone':
        if (!phoneRegex.test(element.value) && element.value) {
          newErrors.phone = "Invalid Australian phone number.";
        }
        break;
      case 'abn':
        const abnRegex = /^\d{11}$/;
        if (!abnRegex.test(element.value) && element.value) {
          newErrors.abn = "Invalid ABN. Must be 11 digits.";
        }
        break;


    }
    setErrors(newErrors);
    // Return true if no errors
    return Object.keys(newErrors).length === 0;
  }

  const validateContractorForm = () => {
    const newErrors: any = {};

    // Name Validation
    if (!contractorForm.name.trim()) {
      newErrors.name = "Name is required.";
    }
    if (!contractorForm.contractorID) {
      newErrors.contractorID = "ContractorID is required.";
    }
    const abnRegex = /^\d{11}$/;
    if (!abnRegex.test(contractorForm.abn)) {
      newErrors.abn = "Invalid ABN. Must be 11 digits.";
    }
    // Email Validation
    // Basic email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!contractorForm.email) {
      newErrors.email = "Email is required.";
    } else if (!emailRegex.test(contractorForm.email)) {
      newErrors.email = "Invalid email address.";
    }
    // Phone Number Validation
    const phoneRegex = /^(\+?\(61\)|\(\+?61\)|\+?61|\(0[1-9]\)|0[1-9])?( ?-?[0-9]){7,9}$/; // Validates a 10-digit phone number
    if (!phoneRegex.test(contractorForm.phone) && contractorForm.phone) {
      newErrors.phone = "Invalid Australian phone number.";
    }


    setErrors(newErrors);
    // Return true if no errors
    return Object.keys(newErrors).length === 0;
  };

  return (
    <Stack>
      <Header onSearch={(e) => console.log(e)} links={[{ title: "Home", link: "/dashboard" }, { title: "Data Management", link: "/management" }, { title: "Contractor Management", link: "" }]} />
      <SideBar />
      <Box className="mainForm dashboardmain" >

        <Paper sx={{ padding: '50px' }}>
          <h1>{currentId!=='Add' ? 'Update Contractor' : 'Add New Contractor'}</h1>
          <Grid container spacing={2} style={{ width: '100%', textAlign: "left" }}>
          <Grid size={6}>
            <TextFieldComponent
              name="name"
              isRequired={true}
              keyName="nameForm"
              placeholder="Enter contractor name"
              value={contractorForm.name}
              onChange={handeContractorFormChange}
              errorStr={errors.name}
              label="Name"
            />
            </Grid>
            <Grid size={6}>
            <TextFieldComponent
              name="abn"
              isRequired={true}
              keyName="abnForm"
              placeholder="Enter 11-digit ABN number"
              value={contractorForm.abn}
              onChange={handeContractorFormChange}
              errorStr={errors.abn}
              label="ABN Number"
            />
            </Grid>
            <Grid size={12}>
            <TextFieldComponent
              name="address"
              isRequired={false}
              keyName="addressForm"
              placeholder="Enter address"
              value={contractorForm.address}
              onChange={handeContractorFormChange}
              errorStr={''}
              label="Address"
            />
            </Grid>
            <Grid size={6}>
            <TextFieldComponent
              name="email"
              isRequired={true}
              keyName="emailForm"
              placeholder="Enter email address"
              value={contractorForm.email}
              onChange={handeContractorFormChange}
              errorStr={errors.email}
              label="Email Address"
            />
            </Grid>
            <Grid size={6}>
            <TextFieldComponent
              name="phone"
              isRequired={true}
              keyName="phoneForm"
              placeholder="Enter phone number"
              value={contractorForm.phone}
              onChange={handeContractorFormChange}
              errorStr={errors.phone}
              label="Phone Number"
            />
            </Grid>
            <Grid size={6}>
            <TextFieldComponent
              name="contractorID"
              isRequired={true}
              keyName="contractorIDForm"
              placeholder="Enter contractor ID"
              value={contractorForm.contractorID}
              onChange={handeContractorFormChange}
              errorStr={errors.contractorID}
              label="Contractor ID"
            />
             </Grid>
             <Grid size={6}>
            <TextFieldComponent
              name="creditLimit"
              isRequired={false}
              keyName="creditLimitForm"
              placeholder="Enter credit limit"
              value={contractorForm.creditLimit}
              onChange={handeContractorFormChange}
              errorStr={''}
              label="Credit Limit"
            /></Grid>
             <Grid size={12}>
            <RadioCheckBoxFieldComponent
              label="Loumac Induction Completed"
              checkField={contractorForm.completed}
              errorStr=""
              isRequired={false}
              items={InductionList}
              keyName="induction"
              name="completed"
              onCheckBoxChange={handeContractorFormChange}
            />
            </Grid>
            <Grid size={12}>
            <TextFieldAreaComponent
              name='notes'
              label="Notes"
              placeholder="Enter notes"
              errorStr=""
              isRequired={false}
              keyName="txtareanotes"
              onChange={handeContractorFormChange}
              value={contractorForm.notes}
              minRows={7}
            />
            </Grid>
            <Grid  size={12}
        sx={{ textAlign: 'right' }}
            >
              
              <ResetButtonComponent
                onClear={(isClear: boolean) => {
                  clearForm();
                }}
                keyName="clrContractor"
              />
              <PrimaryButtonComponent
                keyName="btnMainContractor"
                errors={errors}
                loading={loading}
                onSave={handleSave}
                currentRecord={currentRecord}
              />
              {errorMessage && (
                <p className="text-danger">{errorMessage}</p>
              )}
            </Grid>
          </Grid>
        </Paper>
      </Box>
    </Stack>
  );
};



export default ContractorForm;
