import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

interface ConfirmDialogProps {
  handleClose: (type: string) => void;
  open: boolean;
  message: string;
}
const ConfirmDialog: React.FC<ConfirmDialogProps> = ({
  handleClose,
  open,
  message,
}) => {
  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={() => {
          handleClose("No");
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirmation"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              handleClose("No");
            }}
          >
            No
          </Button>
          <Button
            onClick={() => {
              handleClose("Yes");
            }}
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};
export default ConfirmDialog;
