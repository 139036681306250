"use client";
import { useEffect } from 'react';
import './Index.css';
import Constants from '../../utils/contants';
import Grid from "@mui/material/Grid2";
import { Box, Button, Container } from '@mui/material';
const Login: React.FC = () => {
  useEffect(() => {
    document.getElementsByTagName('body')[0].classList.add('loginbody');

  }, []);
  // useEffect(() => {
  const Login = async () => {
    return CognitoLogin();
  };
  const CognitoLogin = async () => {
    const urlPart = encodeURI('client_id=' + Constants.CLIENTID + '&response_type=code&scope=aws.cognito.signin.user.admin+email+openid+profile&redirect_uri=' + Constants.apiURL + '/cognito/callback');
    window.location.href = 'https://loumac.auth.ap-southeast-2.amazoncognito.com/login?' + urlPart;
  }
  return (
    <Box >
      <Grid container spacing={2} alignItems="center">
        <Grid size={8} className="leftside">
          <Box sx={{
            display: "flex",
            alignItems: 'top',
            flexDirection: "column",
            justifyContent: "space-between", // Pushes one to top, one to bottom
            height: "100vh", // Adjust height as needed
            border: "1px solid black", // Just for visibility
            padding: "16px",
          }}>
            <div className="logologin">
              <img src="../../assets/img/loumac-logo.png" alt='LoumacPro Logo' />
            </div>
            <div className="leftbottombox">
              <h2 className="mainhead">
                Loumac Pro
              </h2>
              <p className="para1">
                Commercial and Infrastructure construction in Sydney. Confident and experienced, our team
                ensures quality builds no matter the project.
              </p>
              <div className="emptyLines" style={{  marginTop: '10px', marginBottom: '10px' }}>
                <div className="emptyline">

                </div>
                <div className="emptyline white">

                </div>
                <div className="emptyline">

                </div>
              </div>
              <div className="contact-fields">
                <p>Ph - 0401 600 935</p>
                <p>Email - admin@loumac.com.au</p>
                <p>Address - Unit 2 34-36 Plasser Rd North St Marys</p>
              </div>
            </div>
          </Box>

        </Grid>
        <Grid size={4} className="rightbox" sx={{margin:0,padding:0}}> 
          <div className="rightside">
            <div className="loginbox">
              <h3 className="maintitle">Welcome back!</h3>
              <h4 className="subtitle">Login into Your Account</h4>
              <Container className="container loginform">

                <div className="txt-center" style={{ textAlign: 'center', marginTop: '10px', marginBottom: '10px' }}>
                  <Button variant='contained' size='large' sx={{ color: '#fff' }} onClick={Login}>Login</Button>

                </div>
                <div className="txtloginbottom" style={{ textAlign: 'center', marginTop: '10px', marginBottom: '10px' }}>
                  Contact Loumac for access
                </div>

              </Container>
              <div className="footer" style={{ textAlign: 'center', marginTop: '10px', marginBottom: '10px' }}>
                <span style={{ color: '#898989', fontSize: '14px' }}>2024 Copyrights </span>
                <span style={{ color: '#000B29', fontSize: '14px' }}>© Loumac Pro</span>
              </div>
            </div></div>
        </Grid>
      </Grid>
    </Box>
      );
};

export default Login;
