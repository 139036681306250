import * as React from 'react';
import Grid from "@mui/material/Grid2";
import { Box, Divider, Typography } from '@mui/material';
import TextFieldComponent from '../../../components/form/TextFieldComponent';
interface TotalComponentProps {
  billableFormItems: any[];
  companyDetails: any;
  projectForm: any;
  viewOnly:boolean;
  overheads:number;
  forcasts:number;
  handleOverHeads(num: number):void;
  handleForecasts(num: number):void;
  projectPercentage:number;
  handleProjectPercentage(num: number):void;
  GST:number;
  
}
const TotalComponent: React.FC<TotalComponentProps> = ({ billableFormItems,
   companyDetails,
   projectForm,
   viewOnly,
   forcasts,
   overheads,
   handleOverHeads,
   handleForecasts,
   projectPercentage,
   handleProjectPercentage,
   GST
   }) => {
 
  


  
  const [resourceTotal, setResourceTotal] = React.useState({});
 
  React.useEffect(() => {
    if (billableFormItems.length > 0) {
      generateTotal(billableFormItems);
    }
  }, [billableFormItems]);
  React.useEffect(() => {
    if(companyDetails) {
      if (companyDetails.financial.length > 0) {
        calculteIndirectCost(companyDetails.financial);
      }
    }
    
  }, [companyDetails]);
  const generateTotal = (itemsData: any) => {
    const totals = itemsData.reduce((acc: any, item: any) => {
      if (item.resources && item.resources.length > 0) {
        item.resources.forEach((detail: any) => {
          const price  = parseFloat(detail['items'][0]['price']) * parseInt(detail['items'][0]['quantity']);
          const resourceType  = typeof detail['resourceType'] === 'object' ? detail['resourceType']['name'] : detail['resourceType'];
          const numericCost = price;
          if (!acc[resourceType]) {
            acc[resourceType] = 0;
          }
          acc[resourceType] += numericCost;
        });
      }
      return acc;
    }, {});
    
    return setResourceTotal(totals);
  }
  const calculteIndirectCost = (financial: any) => {
    let totalOverHeads: number = 0;
    let totalForecasts: number = 0;
    financial.forEach((detail: any) => {
      totalOverHeads += detail.overhead ?  parseFloat(detail.overhead) : 0;
      totalForecasts += detail.overhead ?  parseFloat(detail.forecast) : 0;
    });
    handleOverHeads(totalOverHeads);
    handleForecasts(totalForecasts);
  }
 const getDirectCostPercentage = (value: any)=>{
  if(value){
    if(parseFloat(projectForm.cost) > 0){
      const percentage = (parseFloat(value)*100)/(projectForm.cost  ? parseFloat(projectForm.cost) : 0);
      return (percentage.toFixed(2));
    }else{
      return '0';
    }
   
  }else{
    return 0.00;
  }
    
 }
  const handleInputChange = (e: any) => {
    handleProjectPercentage(parseFloat(e.target.value))
 
   };
   const getProjectPercentageValue = ()=>{
    const subTotal = (overheads ? overheads : 0) + (forcasts ? forcasts : 0) + parseFloat(projectForm.cost ? projectForm.cost : '0');
    const percentage = ((projectPercentage ? projectPercentage : 0)*subTotal)/100;
    return (percentage.toFixed(2));
 }
 const getSubmissionPrice = ()=>{
  const subTotal = (overheads ? overheads : 0) + (forcasts ? forcasts : 0) + parseFloat(projectForm.cost ? projectForm.cost : '0');
  const percentage = ((projectPercentage ? projectPercentage : 0)*subTotal)/100;
  return subTotal+percentage;
}
const getGST = ()=>{
  const subTotal = overheads + forcasts + parseFloat(projectForm.cost);
  const percentage = (projectPercentage*subTotal)/100;
  return (getSubmissionPrice()*GST)/100;
}
  return (
    <Box sx={{ padding: 4 }}>
      {/* Direct Costs */}
      <Box sx={{ marginBottom: 4 }} >
        <Typography variant="h6" fontWeight="bold" gutterBottom>
          Direct Cost
          
        </Typography>
        
        {Object.entries(resourceTotal).map(([key, value]: any) => (
         
          ( <Grid container spacing={2} key={key+'_tottla'} sx={{ marginBottom: 1 }}>
            <Grid size={6}>
              <Typography>{key}</Typography>
            </Grid>
            <Grid size={3} sx={{ textAlign: "right" }}>
              <Typography>{getDirectCostPercentage(value)}%</Typography>
            </Grid>
            <Grid size={3} sx={{ textAlign: "right" }}>
              <Typography>${value.toFixed(2)}</Typography>
            </Grid>
          </Grid>)
        ))}
        <Divider />
        <Box sx={{ display: "flex", justifyContent: "space-between", padding: "8px", backgroundColor: "#f5f5f5" }}>
          <Typography fontWeight="bold">Direct Subtotal</Typography>
          <Typography fontWeight="bold">${projectForm.cost ? parseFloat(projectForm.cost).toFixed(2): 0.00}</Typography>
        </Box>
      </Box>

      {/* Indirect Costs */}
      <Box>
        <Typography variant="h6" fontWeight="bold" gutterBottom>
          Indirect Cost
        </Typography>
        
          <Grid container spacing={2} key={'indirectCost_grid'} sx={{ marginBottom: 1 }}>
            <Grid size={6}>
              <Typography>{'Overheads'}</Typography>
            </Grid>
            <Grid size={3} sx={{ textAlign: "right" }}>
              <Typography>{getDirectCostPercentage(overheads)}%</Typography>
            </Grid>
            <Grid size={3} sx={{ textAlign: "right" }}>
              <Typography>${overheads.toFixed(2)}</Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2} key={'indirectCost_grid_forcast'} sx={{ marginBottom: 1 }}>
            <Grid size={6}>
              <Typography>{'Contigencies'}</Typography>
            </Grid>
            <Grid size={3} sx={{ textAlign: "right" }}>
              <Typography>{getDirectCostPercentage(forcasts)}%</Typography>
            </Grid>
            <Grid size={3} sx={{ textAlign: "right" }}>
              <Typography>${forcasts.toFixed(2)}</Typography>
            </Grid>
          </Grid>
        
        <Divider />
        <Box sx={{ display: "flex", justifyContent: "space-between", padding: "8px", backgroundColor: "#f5f5f5" }}>
          <Typography fontWeight="bold">Indirect Subtotal</Typography>
          <Typography fontWeight="bold">${(overheads+forcasts).toFixed(2)}</Typography>
        </Box>
        <Divider />
      <Box sx={{ display: "flex",marginTop:"20px",fontSize:'18px', justifyContent: "space-between", padding: "8px", backgroundColor: "#f5f5f5" }}>
          <Typography fontWeight="bold" fontSize={'20px'}>Subtotal</Typography>
          <Typography fontWeight="bold" fontSize={'20px'}>${((overheads ? overheads : 0)+(forcasts ? forcasts : 0)+parseFloat(projectForm.cost ? projectForm.cost : '0'))}</Typography>
        </Box>

        <Grid container spacing={2} key={'projectPercentage'} sx={{ marginBottom: 1 }}>
            <Grid size={6}>
              <Typography marginTop={'20px'}>{'Project Margin(%)'}</Typography>
            </Grid>
            {/* <Grid size={3} sx={{ textAlign: "right" }}>
             
            </Grid> */}
            <Grid size={6} sx={{display: 'flex',alignItems:'center',justifyContent:'center'}}>
           
             {viewOnly === false && <TextFieldComponent
                  label=""
                  keyName="projectPercentageField"
                  name="projectPercentageField"
                  value={projectPercentage.toString()}
                  onChange={handleInputChange}
                  errorStr={''}
                  isRequired={false}
                  type='number'
                 
                  boxStyle={{width: '50px',marginTop:'-5px',marginLeft: 'auto'}}
                  style={{width: '50px'}}
                />}
                {viewOnly && <small style={{marginLeft: 'auto'}}>{projectPercentage}%</small>}
                <Typography style={{marginLeft: 'auto',fontWeight: 'bold'}}>${getProjectPercentageValue()}</Typography>
            </Grid>
          </Grid>

          <Grid container spacing={2} key={'projectPercentage_submissinprie'} sx={{ marginBottom: 1 }}>
            <Grid size={6}>
              <Typography marginTop={'20px'}>{''}</Typography>
            </Grid>
            {/* <Grid size={3} sx={{ textAlign: "right" }}>
             
            </Grid> */}
            <Grid size={6} sx={{display: 'flex',alignItems:'center',justifyContent:'center'}}>
            <Typography style={{marginLeft: 'auto'}}>Submission Price</Typography>
             <Typography style={{marginLeft: 'auto',fontWeight: 'bold'}}>${getSubmissionPrice().toFixed(2)}</Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2} key={'projectPercentage_gst_price'} sx={{ marginBottom: 1 }}>
            <Grid size={6}>
              <Typography marginTop={'20px'}>{''}</Typography>
            </Grid>
            {/* <Grid size={3} sx={{ textAlign: "right" }}>
             
            </Grid> */}
            <Grid size={6} sx={{display: 'flex',alignItems:'center',justifyContent:'center'}}>
            <Typography style={{marginLeft: 'auto'}}>GST</Typography>
             <Typography style={{marginLeft: 'auto',fontWeight: 'bold'}}>${getGST().toFixed(2)}</Typography>
            </Grid>
          </Grid>
          <Divider />
      <Box sx={{ display: "flex",marginTop:"20px",fontSize:'18px', justifyContent: "space-between", padding: "8px", backgroundColor: "#f5f5f5" }}>
          <Typography fontWeight="bold" fontSize={'20px'}>Price incl. GST</Typography>
          <Typography fontWeight="bold" fontSize={'20px'}>${(getSubmissionPrice()+getGST()).toFixed(2)}</Typography>
        </Box>
      </Box>
      
       
    </Box>
  );
}
export default TotalComponent;