import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Box, Checkbox, Divider, FormControlLabel, Radio, TableHead, Typography } from '@mui/material';
import StandardTermsStep from './standardterms';
import  { getFormatDate } from '../../../utils/contants';
import { Button, Table, TableBody, TableCell, TableContainer, TableRow } from "@mui/material";
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import AdhocTermsStep from './adhocterms';
import SimpleDialogComponent from '../../../components/SimpleDialogComponent';
import TextFieldAreaComponent from '../../../components/form/TextFieldAreaComponent';
import SwitchComponent from '../../../components/SwitchComponent';
import BillableExclusionSubStep from './step-4-billable-exclusion';
import QuoteIncluExclusionSubStep from './step-4-quote-exclu-inclusion';
import Grid from "@mui/material/Grid2";
interface TermsStepTabProps {
  termsList: any[];
  selectedAdhocTerms: any[];
  adhocTermsList: any[];
  billableFormItems: any[];
  handleTermSelect: (selected: any,type: any) => void;
  handleBillableExclusionSelect: (selected: any) => void;
  selectedExclusions: any[];
  selectedQuoteInclusion: any;
  selectedQuoteExclusion: any;
  handleQuoteIncluExclusionSelect: (selected: any,type: string) => void;
  currentStandardTermsList: any[]
}
const TermsStepTab: React.FC<TermsStepTabProps> = ({ termsList,
   selectedAdhocTerms, adhocTermsList,billableFormItems,
    handleTermSelect,
    handleBillableExclusionSelect,
    selectedExclusions,
    selectedQuoteInclusion,
    selectedQuoteExclusion,
    handleQuoteIncluExclusionSelect,
    currentStandardTermsList

  }) => {
  const [currentTab, setCurrentTab] = React.useState('1');
  const [termText, setTermText] = React.useState('');
  const [termTextError, setTermTextError] = React.useState('');
  const [adHocTermsOpen, setAdHocTermsOpen] = React.useState(false);
  const [isActive, setIsActive] = React.useState(false);
  const [currentOption, setCurrentOption] = React.useState('old');
  const [checkedTerms, setCheckedTerms] = React.useState<any[]>([]);

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setCurrentTab(newValue);
  };
  const getCurrentTabTitle = () => {
    switch (currentTab) {
      case '1':
        return 'Standard Terms & Conditions'
      case '2':
        return 'Adhoc Terms & Conditions'
      case '3':
        return 'Billable Exclusions'
      case '4':
        return 'Quote Inclusions & Exclusions'
    }
  };
  const validateField = async (element: any) => {
    const newErrors: any = {};
    if (!element.value.trim()) {
      setTermTextError("Text is required.");
    }else{
      setTermTextError("");
    }
   
    // Return true if no errors
    return Object.keys(newErrors).length === 0;
  }
  const handleTerm = async (row: any) => {
    if (row === 'All') {
      if(adhocTermsList.length === checkedTerms.length) {
        setCheckedTerms([]);
      }else{
        setCheckedTerms(adhocTermsList);
      }
      
    } else {
      setCheckedTerms((prevItems) => {
        const newItems = [...prevItems];
        if (!newItems.includes(row)) {
          newItems.push(row);
        } else{
          newItems.splice(newItems.indexOf(row),1);
        }
        return newItems;
      });
    }
  }

  
  const clearForm = ()=>{
    setCurrentOption('old');
    setCheckedTerms([]);
    setIsActive(false);
    setTermText('');

  }

  const renderTermsDialogContent = () => {
    return (
      <div className="row mb-3 mt-2"> <div style={{ width: '800px' }}>

        <div className='radiobox mb-3'>
          <FormControl>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
            >
              <FormControlLabel value="old" onChange={() => {
                setCurrentOption('old');
              }} checked={currentOption === 'old'} control={<Radio />} label="Select Previous" />
              <FormControlLabel value="new" checked={currentOption === 'new'}
                onChange={() => {
                  setCurrentOption('new');
                }}
                control={<Radio />} label="Add New" />

            </RadioGroup>
          </FormControl>
        </div>


        {currentOption === 'old' && <TableContainer>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell> <Checkbox
                  edge="start"
                  checked={checkedTerms.length === adhocTermsList.length}
                  tabIndex={-1}
                  disableRipple
                  onChange={() => {
                    handleTerm('All')
                  }}

                /></TableCell>
                <TableCell align="left">Text</TableCell>
                <TableCell align="left">Last Update</TableCell>
                <TableCell align="left">Date Created</TableCell>

              </TableRow>
            </TableHead>
            <TableBody>
              {adhocTermsList.map((row: any) => (
                <TableRow
                  key={row.name}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    <Checkbox
                      edge="start"
                      checked={checkedTerms.includes(row)}
                      tabIndex={-1}
                      disableRipple
                      onChange={() => {
                        console.log('here');
                        handleTerm(row)
                      }}

                    />
                  </TableCell>
                  <TableCell align="left">{row.termText}</TableCell>
                  <TableCell align="left">{getFormatDate(row.updated)

                  }</TableCell>
                  <TableCell align="left">{getFormatDate(row.created)}</TableCell>


                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>}
        {currentOption === 'new' &&  <Grid container spacing={2} sx={{ width: '100%',m:'10px', textAlign: "left" }}>
        <Grid size={12}>
            <TextFieldAreaComponent
              label='Text'
              errorStr={termTextError}
              isRequired={true}
              keyName='adhoctextarea'
              minRows={5}
              name='adhoctextarea'
              onChange={(e: any) => {
                setTermText(e.target.value);
                validateField(e.target);
              }}
              value={termText}
              />
              </Grid>
              <Grid size={12}>
               <Typography component={'h6'}>Active Adhoc Terms and Conditions</Typography> 
              <SwitchComponent checked={isActive} handleInputChange={(e: any)=>{
                setIsActive(e.target.checked);
              }} name={'chckactiveterms'} value={isActive} color={'primary'}  />
              </Grid>
              
        </Grid>}
        <Divider className='mt-3 mb-3' />
        <div className='mt-3 mb-3' style={{ textAlign: 'right' }}>
          <Button color='primary' sx={{mt:2,color:'#fff'}} variant="contained" onClick={() => {
            if(currentOption === 'new') {
              if(termText) {
                handleTermSelect({termText,isActive},'new');
                clearForm();
              } else{
                setTermTextError('Text is required.');
              }
            }else{
              if(checkedTerms.length > 0){
                handleTermSelect(checkedTerms,'old');
                clearForm();

                
              }
            }
           
            setAdHocTermsOpen(false)
          }} >
            Add
          </Button>
        </div>


      </div>
      </div>
    );
  }
  return (
    <Box sx={{ width: '100%', marginTop: '35px' }}>
      <h1 className="form-label">{getCurrentTabTitle()}</h1>
      <Tabs
        sx={{
          "& .MuiTab-root": {
            textTransform: "none",
            color: "#000B29",
          },
          "& .Mui-selected": {
            color: "#000B29",
            borderBottom: "2px solid #000B29",
            fontSize: '14px'

          },
        }}
        value={currentTab}
        onChange={handleTabChange}

      >
        <Tab
          value="1"
          label="Standard Terms & Conditions"
          wrapped
        />
        <Tab value="2" label="Adhoc Terms & Conditions" />
        <Tab value="3" label="Billable Exclusions" />
        <Tab value="4" label="Quote Inclusions & Exclusions" />

      </Tabs>
      {currentTab === '1' && <StandardTermsStep terms={termsList} currentStandardTermsList={currentStandardTermsList}></StandardTermsStep>}
      {currentTab === '2' && <AdhocTermsStep handleTermsDialog={(open: boolean) => {
        setAdHocTermsOpen(open);
      }} selectedAdhocTerms={selectedAdhocTerms} ></AdhocTermsStep>}
       {currentTab === '3' && <BillableExclusionSubStep  selectedExclusions={selectedExclusions} handleBillableExclusionSelect={handleBillableExclusionSelect} billableFormItems={billableFormItems} ></BillableExclusionSubStep>}
       {currentTab === '4' && <QuoteIncluExclusionSubStep  selectedQuoteExclusion={selectedQuoteExclusion} handleQuoteIncluExclusionSelect={handleQuoteIncluExclusionSelect} selectedQuoteInclusion={selectedQuoteInclusion} ></QuoteIncluExclusionSubStep>}

      {<SimpleDialogComponent title={'Select / Add Adhoc T & C'} dialogContent={renderTermsDialogContent()} open={adHocTermsOpen} handleClose={() => {
        clearForm();
        setAdHocTermsOpen(false)
      }} />}
    </Box>

  );
}
export default TermsStepTab;