import PersonIcon from "@mui/icons-material/Person";

import BuildIcon from "@mui/icons-material/Build";
import DescriptionIcon from "@mui/icons-material/Description";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import PaymentsIcon from "@mui/icons-material/Payments";
import AutoAwesomeMosaicIcon from "@mui/icons-material/AutoAwesomeMosaic";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import EngineeringIcon from "@mui/icons-material/Engineering";
import PeopleIcon from "@mui/icons-material/People";
import ListIcon from "@mui/icons-material/List";
import FactCheckIcon from "@mui/icons-material/FactCheck";
const Constants = {
  //baseURL: "http://localhost:3000",
  //apiURL: "http://localhost:3000",
  apiURL: "https://api.loumacpro.com.au",
  usersURL: "/api/v1/users",
  rolesURL: "/api/v1/users/roles",
  contractorsURL: "/api/v1/contractors",
  customersURL: "/api/v1/customers",
  termsURL: "/api/v1/terms",
  adhocTermsURL: "/api/v1/adhoc-terms",
  tendersURL: "/api/v1/tenders",
  resourcesURL: "/api/v1/resources",
  mediaURL: "/api/v1/media",
  billableURL: "/api/v1/billables",
  projectsURL: "/api/v1/projects",
  companyProfileURL: "/api/v1/company-profile",
  refBundleURL: "/api/v1/refbundle",
  quoteURL: "/api/v1/quote",

  USER_POOL_REGION: "ap-southeast-2",
  REFRESH_TOKEN_URL: `/cognito/refresh-token`,
  CLIENTID: "3n7896ir9vpphef1jjkqmbkh8l",
  DEFAULT_PAGE_SIZE: 25,
  COMMON_SERVER_ERROR: "Process Failed",
};
export const LoumcMenuItems: any = {
  management: [
    {
      label: "Users",
      icon: <PersonIcon />,
      link: "/management/users",
    },
    {
      label: "Contractor",
      icon: <BuildIcon />,
      link: "/management/contractors",
    },
    {
      label: "Customer",
      icon: <PersonIcon />,
      link: "/management/customers",
    },
    {
      label: "Standard T & C’s",
      icon: <DescriptionIcon />,
      link: "/management/terms",
    },
    {
      label: "Adhoc T & C’s",
      icon: <InsertDriveFileIcon />,
      link: "/management/adhoc-terms",
    },
    {
      label: "Tender Documents",
      icon: <FileCopyIcon />,
      link: "/management/tenders",
    },
    {
      label: "Resource Items",
      icon: <BuildIcon />,
      link: "/management/resources",
    },
    {
      label: "Billables",
      icon: <PaymentsIcon />,
      link: "/management/billables",
    },
    {
      label: "Project Templates",
      icon: <AutoAwesomeMosaicIcon />,
      link: "/management/projects",
    },
    {
      label: "Company Profile",
      icon: <PeopleIcon />,
      link: "/management/company-profile",
    },
    {
      label: "Mobile Assets",
      icon: <LocalShippingIcon />,
      link: "/",
    },
    {
      label: "Labour",
      icon: <EngineeringIcon />,
      link: "/",
    },
    {
      label: "Crews",
      icon: <PeopleIcon />,
      link: "/",
    },
    {
      label: "SWMS",
      icon: <ListIcon />,
      link: "/",
    },
    {
      label: "Critical Checks",
      icon: <FactCheckIcon />,
      link: "/",
    },
  ],
};
export default Constants;
export const getLocalIDToken = () => {
  return localStorage.getItem("louidtoken");
};

export const setLocalIDToken = (IdToken: any) => {
  localStorage.setItem("louidtoken", IdToken);
};
export const getLocalAccessToken = () => {
  return localStorage.getItem("louaccesstoken");
};

export const setLocalAccessToken = (IdToken: any) => {
  localStorage.setItem("louaccesstoken", IdToken);
};
export const getLocalRefreshToken = () => {
  return localStorage.getItem("louRefreshToken");
};

export const setLocalRefreshToken = (IdToken: any) => {
  localStorage.setItem("louRefreshToken", IdToken);
};
export const  setLoginName= (username: any) => {
  localStorage.setItem("username", username);
};
export const getLoginName = () => {
  localStorage.getItem("username");
};
export const getCurrentDate = () => {
  return (
    new Date().getFullYear() +
    "-" +
    (new Date().getMonth() + 1) +
    "-" +
    new Date().getDate()
  );
};
export const getFormatDate = (value: any) => {
  return new Date(value).toLocaleDateString("default", {
    month: "short",
    day: "numeric",
    year: "numeric",
  });
};
