import * as React from 'react';
import Grid from "@mui/material/Grid2";
import { List, ListItem, ListItemText } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import {  Button } from "@mui/material";
interface AdhocTermsStepProps {
  selectedAdhocTerms: any[];
  handleTermsDialog:(open:boolean)=>void;
}
const AdhocTermsStep: React.FC<AdhocTermsStepProps> = ({selectedAdhocTerms,handleTermsDialog }) => {
  return (
    <Grid container spacing={2} style={{ marginTop: "30px" }}>
    
      <Grid size={12}>
        <List>
          {selectedAdhocTerms.map((item, index) => {
            return <ListItem sx={{
              display: "list-item",
              listStyle: 'disc',
            }} key={'terms_list_' + index}><ListItemText>{item.termText}</ListItemText></ListItem>
          })}
        </List>
        <Button variant="contained" sx={{color: '#ffffff !important'}} startIcon={<AddIcon />} className="primarybtn" onClick={() => {
            handleTermsDialog(true);
          }}>
            Select / Add Addhoc T & C
          </Button>
      </Grid>
    </Grid>

  );
}
export default AdhocTermsStep;