import React, { useEffect } from 'react';
import './Index.css';

import SideBar from '../../components/Sidebar';
import Header from '../../components/Header';
import { BiSolidUserPlus } from "react-icons/bi";
import { Link } from 'react-router-dom';



const Inventory: React.FC = () => {

    useEffect(() => {
        document.getElementsByTagName('body')[0].classList.remove('loginbody');
       
      }, []);
      const handleSerchInput = async (event: any) => {
        console.log(event);
      }
  return (
    <div>
    <Header onSearch={handleSerchInput} />
    <SideBar/>
    <main id="main" className="main dashboardmain">

    <div className="pagetitle">
      <label>Inventory</label>
    </div>

    <section className="section dashboard">
      <div className="row">

    
        <div className="col-lg-8">
            
            
           
           
        </div>




      </div>
    </section>

  </main>
  </div>
  );
};

export default Inventory;
