import * as React from 'react';
import Grid from "@mui/material/Grid2";
import PricingStepSummary from './pricing-step-3';
import StandardTermsStep from './standardterms';
import { Box, List, ListItem, ListItemText } from '@mui/material';

interface ReviewFinalStepProps {
  detailForm: any;
  currentCustomer: any;
  currentProject: any;
  projectForm: any;
  fileQuoteMedia: any[];
  companyDetails: any;
  expandRowState: any[];
  billableFormItems: any[];
  projectTemplateList: any[];
  termsList: any[];
  selectedAdhocTerms: any[];
  selectedExclusions: any[];
  selectedQuoteInclusion: any;
  selectedQuoteExclusion: any;
  overheads:number;
  forcasts:number;
  projectPercentage:number;
  GST:number;
  status:number;

  induction:any[];
  currentStandardTermsList:any[]

}
const ReviewFinalStep: React.FC<ReviewFinalStepProps> = ({
  detailForm,
  currentCustomer,
  currentProject,
  projectForm,
  fileQuoteMedia,
  companyDetails,
  expandRowState,
  billableFormItems,
  projectTemplateList,
  termsList,
  selectedAdhocTerms,
  selectedExclusions,
  selectedQuoteInclusion,
  selectedQuoteExclusion,
  forcasts,overheads,
  projectPercentage,
  GST,
  status,
  
  induction,
  currentStandardTermsList

}) => {
  return (<Grid container spacing={2} style={{ marginTop: "30px" }}>
    {/* Section Header */}
    <h1 className="form-label">{'Details'}</h1>
    <Grid size={12}>

      <h5 className='mt-4 mb-3'>Quote Info</h5>
      <Grid container spacing={2}>
        <Grid size={4}>
          <h6 className="form-label">{'Quote Status'}</h6>
          {status}
        </Grid>
      
      </Grid>
    </Grid>
    {/* Customer Sections */}
    <Grid size={12}>

      <h5 className='mt-4 mb-3'>Customer Details</h5>
      <Grid container spacing={2}>
        <Grid size={3}>
          <h6 className="form-label">{'Customer'}</h6>
          {currentCustomer.name}
        </Grid>
        <Grid size={3}>
          <h6 className="form-label">{'Main Contact'}</h6>
          {detailForm.mainContact}

        </Grid>
        <Grid size={3}>
          <h6 className="form-label">{'Customer Email'}</h6>
          {detailForm.customerEmail}

        </Grid>
        <Grid size={3}>
          <h6 className="form-label">{'Main Contact Number'}</h6>
          {detailForm.contactNumber}

        </Grid>
      </Grid>
    </Grid>
    {/* Customer Sections  Ends*/}

    {/* Project Sections */}
    <Grid size={12}>

      <h5 className='mt-4 mb-3'>Project Details</h5>
      <Grid container spacing={2}>
        <Grid size={3}>
          <h6 className="form-label">{'Project Name'}</h6>
          {detailForm.projectName}
        </Grid>
        <Grid size={3}>
          <h6 className="form-label">{'Category of work'}</h6>
          {detailForm.projectWorkCategory}

        </Grid>
        <Grid size={3}>
          <h6 className="form-label">{'Site Address'}</h6>
          {detailForm.projectSiteAddress}

        </Grid>
        <Grid size={3}>
          <h6 className="form-label">{'Estimate Start Date'}</h6>
          {detailForm.estimateStartDate}

        </Grid>

        <Grid size={3}>
          <h6 className="form-label">{'Quote Submission Due Date'}</h6>
          {detailForm.submissionDueDate}
        </Grid>
        <Grid size={3}>
          <h6 className="form-label">{'Quote Valid To'}</h6>
          {detailForm.validToDate}

        </Grid>
        {induction && <Grid size={3} >
          <h6 className="form-label">{'Induction Required'}</h6>
          {induction.map((inductionData)=>{
            return <Box>{inductionData.name}</Box>
          })
            }

        </Grid>}
        {currentProject && <Grid size={3}>
          <h6 className="form-label">{'Project Template'}</h6>
          {currentProject.name}

        </Grid>}
        <Grid size={12}>
          <h6 className="form-label">{'Notes'}</h6>
          {detailForm.internalNotes}

        </Grid>
      </Grid>
    </Grid>
    {/* Project Sections  Ends*/}


    {/* Document Sections */}
    <Grid size={12}>

      <h5 className='mt-4 mb-3'>Documents</h5>
      <Grid container spacing={2}>
        {fileQuoteMedia.map((file) => {
          return <div style={{ backgroundColor: 'rgba(0, 244, 255, 0.08)', color: '#000', margin: '5px', padding: '20px', width: '450px' }}>
            <div style={{ borderBottom: '5px solid black' }}>
              {file.name}
            </div>

          </div>
        })}

      </Grid>
    </Grid>
    {/* Document Sections  Ends*/}

    {/* Pricing Sections */}
    <Grid size={12}>

      <h5 className='mt-4 mb-3'>Pricing</h5>
      <Grid container spacing={2}>
      {currentProject && <Grid size={12}>
          <h6 className="form-label">{'Project Template'}</h6>
          {currentProject.name}
        </Grid>}  
        <Grid size={12}>
          <PricingStepSummary
            billableFormItems={billableFormItems}
            companyDetails={companyDetails}
            currentProject={currentProject}
            expandRowState={expandRowState}
            handleArrow={() => { }}
            handleAutoCompleteChange={() => { }}
            handleBillableDetailChange={() => { }}
            handleBillableDialog={() => { }}
            projectForm={projectForm}
            projectTemplateList={projectTemplateList}
            removeDetailsItem={() => { }}
            removeItem={() => { }}
            viewOnly={true}
            overheads={overheads}
            forcasts={forcasts}
            handleForecasts={()=>{}}
            handleOverHeads={()=>{}}
            projectPercentage={projectPercentage}
            handleProjectPercentage={()=>{}}
            handleAttached={()=>{}}
            handleOpen={()=>{
            }}
            GST={GST}

          />
        </Grid>
      </Grid>
    </Grid>
    {/* Pricing Sections  Ends*/}

    {/* Terms Sections Starts */}
    <Grid size={12}>

      <h5 className='mt-4 mb-3'>Term & Condition</h5>
      <Grid container spacing={2}>
        <Grid size={12}>
          <h6 className="form-label">{'Standard Terms & Condition'}</h6>
          <StandardTermsStep terms={termsList} currentStandardTermsList={currentStandardTermsList} />
        </Grid>
        <Grid size={12}>
          <h6 className="form-label">{'Adhoc Terms & Condition'}</h6>
          <List>
            {selectedAdhocTerms.map((item, index) => {
              return <ListItem sx={{
                display: "list-item",
                listStyle: 'disc',
              }} key={'terms_list_' + index}><ListItemText>{item.termText}</ListItemText></ListItem>
            })}
          </List>
        </Grid>
        <Grid size={12}>
          <h6 className="form-label">{'Billable Exclusion'}</h6>
          <List>
            {selectedExclusions.map((item, index) => {
              return item.exclusion ? <ListItem sx={{
                display: "list-item",

              }} key={'billable_exclusion_name_list_' + index}>
                <ListItemText sx={{ fontWeight: 'bold' }}>
                  {item.name}

                </ListItemText>
                <ListItem sx={{
                  display: "inline-flex",

                }} key={'billable_exclusion_list_' + index}>

                  <ListItemText sx={{ fontWeight: 'bold' }}>
                    {item.exclusion}
                  </ListItemText>
                </ListItem>
              </ListItem> : <span></span>
            })}
          </List>
        </Grid>
        <Grid size={12}>
          <h6 className="form-label">{'Quote Inclusion & Exclusion'}</h6>
          <List>
            <ListItem sx={{
              display: "list-item",
              width: '350px'

            }} key={'quote_inclusion_name_list_'}>
              <ListItemText sx={{ fontWeight: 'bold' }}>
                Quote Inclusions
              </ListItemText>
              {selectedQuoteInclusion && <ListItem sx={{
                display: "inline-flex",

              }} key={'quote_inclusion_list_'}>

                <ListItemText sx={{ fontWeight: 'bold' }}>
                  {selectedQuoteInclusion}
                </ListItemText>
              </ListItem>}
            </ListItem>
            <ListItem sx={{
            display: "list-item",
             width: '350px'

          }} key={'quote_exclusion_name_list_'} >
            <ListItemText sx={{ fontWeight: 'bold' }} >
              Quote Exclusions

            </ListItemText>
            {selectedQuoteExclusion && <ListItem sx={{
              display: "inline-flex",

            }} key={'quote_exclusion_list_'}

            >

              <ListItemText sx={{ fontWeight: 'bold' }}>
                {selectedQuoteExclusion}
              </ListItemText>
            </ListItem>}
          </ListItem>

          </List>
        </Grid>
      </Grid>
    </Grid>
    {/* Terms Sections Ends */}



  </Grid>
  );
}
export default ReviewFinalStep;