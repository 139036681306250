import DashboardIcon from '@mui/icons-material/Dashboard';

import DnsIcon from '@mui/icons-material/Dns';
import React, { useState } from "react";

import { useLocation } from "react-router-dom";
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import { LoumcMenuItems } from '../utils/contants';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import InventoryIcon from '@mui/icons-material/Inventory';
import { useNavigate } from "react-router-dom";

const SideBar: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const isActive = (path: string) => location.pathname === path;
  const isActiveFlexible = (path: string) => location.pathname.indexOf(path) !== -1;
  var currentModule = location.pathname.split("/");
  var activeMenuItem = '';
  if (currentModule.length > 1) {
    activeMenuItem = currentModule[1];
  }
  const [activeMenu, setActiveMenu] = useState(activeMenuItem);

  const toggleMenu = (menu: any) => {
    setActiveMenu((prevMenu) => (prevMenu === menu ? "" : menu));
  };
  const [open, setOpen] = React.useState(isActiveFlexible('/management'));
  const [quoteOpen, setQuoteOpen] = React.useState(isActiveFlexible('/quote'));
  const handleClickQuote = () => {
    setQuoteOpen(!quoteOpen);
  }
  const handleClick = () => {
    setOpen(!open);
  }
  console.log(location);


  return (
    <aside id="sidebar" className="sidebar">
      <List
        sx={{ width: '100%', color: '#000', fontFamily: 'Poppins', maxWidth: 360, bgcolor: 'background.paper' }}
        component="nav"
        aria-labelledby="nested-list-subheader"
        subheader={
          <ListSubheader component="div" id="nested-list-subheader">
            Menu
          </ListSubheader>
        }
      >
        <ListItemButton  className="custom-list-item"  onClick={handleClickQuote}  >
          <ListItemIcon>
            <DashboardIcon sx={isActive('/quote') ? styles.activeLink : styles.link} />
          </ListItemIcon>
          <ListItemText  primary='Quote Dashboard' ></ListItemText>
          {quoteOpen ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={quoteOpen} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {
             <ListItemButton onClick={()=>{
                  navigate('/quote')
                }} sx={{ pl: 4 }}>
                  <ListItemIcon   className={isActive('/quote') ? 'activeLink' : 'normal'}>
                   <RequestQuoteIcon  sx={isActive('/quote') ? styles.activeLink : styles.link} />
                  </ListItemIcon>
                  <ListItemText primary={'Quotes'} />
                </ListItemButton>
             
            }

          </List>
        </Collapse>
        <ListItemButton>
          <ListItemIcon>
            <AccountTreeIcon />
          </ListItemIcon>
          <ListItemText primary="Project Dashboard" />
        </ListItemButton>

        <ListItemButton>
          <ListItemIcon>
            <CalendarMonthIcon />
          </ListItemIcon>
          <ListItemText primary="Scheduling" />
        </ListItemButton>

        <ListItemButton>
          <ListItemIcon>
            <InventoryIcon />
          </ListItemIcon>
          <ListItemText primary="Inventory" />
        </ListItemButton>




        <ListItemButton onClick={handleClick}>
          <ListItemIcon  >
            <DnsIcon sx={isActive('/management') ? styles.activeLink : styles.link} />
          </ListItemIcon >
          <ListItemText primary="Data Management" />
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {
              LoumcMenuItems.management.map((item: any) => {
                return <ListItemButton onClick={()=>{
                  navigate(item.link)
                }} sx={{ pl: 4 }}>
                  <ListItemIcon   className={isActive(item.link) ? 'activeLink' : 'normal'}>
                   {item.icon}
                  </ListItemIcon>
                  <ListItemText primary={item.label} />
                </ListItemButton>
              })
            }

          </List>
        </Collapse>
      </List>

    </aside>

  );
};
const styles = {
  nav: {
    display: 'flex',
    gap: '20px',
    padding: '10px',



  },
  link: {
    textDecoration: 'none',
    display: 'flex',

    // color: 'black',
  },
  activeLink: {
    textDecoration: 'none',
    color: '#00f4ff',
    fontWeight: 'bold',

  
  },
  dropdown: {
    paddingLeft: "20px",
    display: "block",
  },
};
export default SideBar;