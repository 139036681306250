"use client";
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
interface PageTitleProps {

  links?: any[]
}
const PageTitle: React.FC<PageTitleProps> = ({ links }) => {
  return (
    <div key={'mrtitle_key'} className="" style={containerStyle}>
      <Breadcrumbs separator=">" aria-label="breadcrumb" style={{ verticalAlign: 'middle' }}>
        {links?.map((linkItem) => {
          return linkItem.link === "" ? <Typography sx={{ color: '#fff' }}>{linkItem.title}</Typography> : <Link underline="hover" color="inherit" href={linkItem.link} >
            {linkItem.title}
          </Link>
        })
        }


      </Breadcrumbs>
      {/* {links.length > 1 && <div style={navStyle}>

        {links.map((titleData: any, index: number) => {
          return <span key={'sp_' + index}>
            <Link key={'lsnk_' + index} to={links[index]} style={navStyle}> {titleData}</Link>
            {index !== links.length - 1 && ' > '}
          </span>
        })
        }
      </div>} */}
    </div>
  );
};

const containerStyle = {
  color: '#ffffff'
};
const navStyle = {
  fontSize: '14px',
  textDecoration: 'none',
  color: '#ffffff'
};
export default PageTitle;