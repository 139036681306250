import { TextField,Box } from "@mui/material";

interface TextFieldComponentProps {
  onChange: (
    event: any
  ) => void;
  label: string;
  keyName: string;
  name: string;
  isRequired: boolean;
  value: string;
  placeholder?: string;
  errorStr: string;
  disabled?:boolean;
  style?:any;
  type?:string;
  boxStyle?: any;
  
}
const TextFieldComponent: React.FC<TextFieldComponentProps> = ({ onChange,boxStyle, label, isRequired, value, keyName, name, placeholder, errorStr,disabled,style,type }) => {
  return (
    <Box className="mb-3" sx={{mb:3}} key={'ele_'+keyName}  style={boxStyle}>
      <Box  sx={{mb:1}} className="form-label" key={'lbk_'+keyName}>{label}{isRequired && <small style={{color:'red'}} key={'sml_'+keyName}> *</small>}</Box>
      <TextField
        type={type ? type :  "text"}
        className="form-control form-control-loum"
        id={keyName}
        name={name}
        label=""
        variant="outlined"
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        style={style}
        fullWidth
        disabled={disabled}
       
        
      />
      {errorStr && <span className="bad-field">{errorStr}</span>}
    </Box>
  );
}
export default TextFieldComponent;