import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Dashboard from './pages/Dashboard/Index';
import Login from './pages/Login/Index';
import Users from './pages/DataManagement/Users/Index';
import DataManagement from './pages/DataManagement/Index';
import Quote from './pages/Quote/Index';
import Contractors from './pages/DataManagement/Contractors/Index';
import LoginProcess from './pages/Login/process/Index';
import Tenders from './pages/DataManagement/Tender/Index';
import StandardTerms from './pages/DataManagement/Terms/Standard/Index';
import AdHocTerms from './pages/DataManagement/Terms/AdHoc/Index';
import Customer from './pages/DataManagement/Customers/Index';
import Resources from './pages/DataManagement/Resource/Index';
import Billable from './pages/DataManagement/Billable/Index';
import BillableForm from './pages/DataManagement/Billable/Form';
import UsersForm from './pages/DataManagement/Users/Form';
import ContractorForm from './pages/DataManagement/Contractors/Form';
import CustomerForm from './pages/DataManagement/Customers/Form';
import StandardTermsForm from './pages/DataManagement/Terms/Standard/Form';
import AdHocTermsForm from './pages/DataManagement/Terms/AdHoc/Form';
import TendersForm from './pages/DataManagement/Tender/Form';
import ResourcesForm from './pages/DataManagement/Resource/Form';
import Project from './pages/DataManagement/Project/Index';
import ProjectForm from './pages/DataManagement/Project/ProjectForm';
import { Schedule } from '@mui/icons-material';
import Inventory from './pages/Inventory/Index';
import ProjectDashboard from './pages/ProjectDashboard/Index';
import Scheduling from './pages/Schedule/Index';
import CompanyProfile from './pages/CompanyProfile/Index';
import LayoutDashboard from './pages/Quote/Layout';
import QuoteDashboard from './pages/Quote/Index';
import QuoteDialogComponent from './pages/Quote/QuoteForm';



const AppRoutes: React.FC = () => {
  return (

    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/login" element={<Login />} />
      <Route path="/quote" element={<QuoteDashboard />} />
      <Route path="/quote/:quoteId" element={<QuoteDialogComponent />} />
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="/scheduling" element={<Scheduling />} />
      <Route path="/inventory" element={<Inventory />} />
      <Route path="/project-dashboard" element={<ProjectDashboard />} />

      <Route path="/management">
        <Route index element={<DataManagement />} />
        <Route path="users">
          <Route index element={<Users />} />
          <Route path=":currentId" element={<UsersForm />} />
        </Route>
        <Route path="contractors">
          <Route index element={<Contractors />} />
          <Route path=":currentId" element={<ContractorForm/>} />
        </Route>
        <Route path="customers">
          <Route index element={<Customer />} />
          <Route path=":currentId" element={<CustomerForm />} />
        </Route>
        <Route path="terms">
          <Route index element={<StandardTerms />} />
          <Route path=":currentId" element={<StandardTermsForm />} />
        </Route>
        <Route path="adhoc-terms">
          <Route index element={<AdHocTerms />} />
          <Route path=":currentId" element={<AdHocTermsForm />} />
        </Route>
        <Route path="tenders">
          <Route index element={<Tenders />} />
          <Route path=":currentId" element={<TendersForm />} />
        </Route>
        <Route path="resources">
          <Route index element={<Resources />} />
          <Route path=":currentId" element={<ResourcesForm />} />
        </Route>
        <Route path="billables">
          <Route index element={<Billable />} />
          <Route path=":currentId" element={<BillableForm />} />
        </Route>
        <Route path="projects">
          <Route index element={<Project />} />
          <Route path=":currentId" element={<ProjectForm />} />
        </Route>
        <Route path="company-profile">
          <Route index element={<CompanyProfile />} />
          
        </Route>
        <Route path="layout">
          <Route index element={<LayoutDashboard />} />
          
        </Route>
      </Route>
      <Route path="/login/success" element={<LoginProcess />} />
      <Route path="/login/failed" element={<Login />} />
    </Routes>

  );
};

export default AppRoutes;
