"use client";
import React, { useEffect, useState } from "react";
import "./Index.css";
import SideBar from "../../../components/Sidebar";
import Header from "../../../components/Header";
import {
  Box,
  Fab,
  Paper,
  Stack,
  Typography
} from "@mui/material";
import { Add } from "@mui/icons-material";
import PageTitle from "../../../components/PageTitle";
import Constants from "../../../utils/contants";
import HttpService from "../../../services/HttpService";
import { useStore } from "../../../utils/store";
import Switch from "@mui/material/Switch";
import CustomPaginationActionsTable from "../../../components/TablePagination";
import StatusRequestData from "../../../Models/StatusRequest";
import { useNavigate } from "react-router-dom";
import SwitchComponent from "../../../components/SwitchComponent";
import AddButtonComponent from "../../../components/form/AddButtonComponent";

const Customer: React.FC = () => {
  const { getAdmin } = useStore();
  const navigate = useNavigate();
  // Table Columns Definition
  const columns: any[] = [
    { field: "name", headerName: "Name" },
    { field: "address", headerName: "Address" },
    { field: "phone", headerName: "Phone Number" },
    { field: "email", headerName: "Email Address" },
    { field: "customerID", headerName: "Customer ID" },
    { field: "creditLimit", headerName: "Credit Limit" },
    {
      field: "abn", headerName: "Open Quotes", renderCell: (value: any) => (
        <span>3</span>
      ),
    },
    {
      field: "abn", headerName: "Accepted Quotes &  Live Projects", renderCell: (value: any) => (
        <span>6</span>
      ),
    },
    {
      field: "abn", headerName: "Total Exposure", renderCell: (value: any) => (
        <span>$850,000</span>
      ),
    },
    {
      field: "status",
      headerName: "Active",

      renderCell: (value: any) => (
        <SwitchComponent
          checked={value === "1"}
          value={value}
          size="small"
          color="success"
          inputProps={{ "aria-label": "controlled" }}
          name={'customerstatus'}
          handleInputChange={()=>{}}
        />
      ),
    },
    {
      field: "updated",
      headerName: "Date Modified",
      valueGetter: (value: any) =>
        new Date(value).toLocaleDateString("default", {
          month: "short",
          day: "numeric",
          year: "numeric",
        }
        ),
    },
  ];
  // State Management
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [totalRecords, setTotalRecords] = useState(0);
  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState<any[]>([]);
  const [paginate, setPaginate] = useState({
    limit: Constants.DEFAULT_PAGE_SIZE,
    offset: 0,
  });

  useEffect(() => {
    fetchData();
  }, [searchQuery, paginate]);

  // Fetch Data
  const fetchData = async () => {
    try {
      const response = await HttpService.get(
        Constants.apiURL + Constants.customersURL,
        { headers: { Authorization: `Bearer ${getAdmin()}` } },
        { search: searchQuery, limit: paginate.limit, offset: paginate.offset }
      );
      if (response.success) {
        setRows(response.data.paginateRecords);
        setTotalRecords(response.data.total);
      } else {
        setErrorMessage(Constants.COMMON_SERVER_ERROR);
      }
    } catch (error) {
      setErrorMessage(Constants.COMMON_SERVER_ERROR);
    }
  };
  const handlePagination = (paginationData: any) => setPaginate(paginationData);

  const handleStatus = (row: any) => {
    let status = '1';
    if (row.status === '1') {
      status = '0';
    }
    if (row.status === '0') {
      status = '1';
    }
    StatusUpdate({ id: row.id, status: status });
  };

  const StatusUpdate = async (dataObj: StatusRequestData) => {
    try {
      const response = await HttpService.patch(
        Constants.apiURL + Constants.customersURL,
        dataObj,
        { headers: { Authorization: "Bearer " + getAdmin() } }
      );
      if (response.success) {
        fetchData();
       
      } else {
        setErrorMessage("Failed to Update Status. Please try again.");
      }

      console.log("GET Response:", response);
    } catch (error) {
      setErrorMessage("Failed to add user. Please try again.");
      console.error("Error fetching data:", error);
    }
  };
 
  


  return (
    <Stack>
      <Header onSearch={(e) => setSearchQuery(e.target.value)} links={[{title:"Home",link:"/dashboard"}, {title:"Data Management",link:"/management"}, {title:"Customer Management",link:""}]} />
      <SideBar />
      <Box id="main" className="main dashboardmain">
        <section className="section dashboard">
          <div className="containergrid">
          
              <Paper sx={{ width: "100%" }} className="mt-4">
              <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: '10px', mb: 2 }}>
                  <Typography variant="h6">All Customers</Typography>
                  <AddButtonComponent onClick={() => {
                    navigate("/management/customers/Add")
                    //setDialogOpen(true);

                  }} keyName="quoteaddicon" label="Add New" />
                </Box>
                <CustomPaginationActionsTable
                  rows={rows}
                  columns={columns}
                  totalRecords={totalRecords}
                  handlePagination={handlePagination}
                  handleRowClick={(row: any)=>{
                    navigate('/management/customers/' + row.id)
                  }}
                  handleStatus={handleStatus}
                  paginationModel={{
                    page: paginate.offset,
                    pageSize: paginate.limit,
                  }}
                />
              </Paper>
            
              
          </div>
        </section>
      
        
       
      </Box>
    </Stack>
  );
};

const fabStyle = {
  position: "absolute",
  bottom: 16,
  right: 16,
  backgroundColor: "#000",
  color: "#fff",
  "&:hover": {
    backgroundColor: "#000",
    color: "#fff",
  },
};

export default Customer;
