import React, { useRef, useState } from "react";
import { Button } from "@mui/material";
import { AttachFile, Close } from "@mui/icons-material";

interface DragAndDropFileUploadProps {
  onChange: (fileData: any) => void;
  defaultValue: any;
  name: string;
  keyName: string;
  isRequired: boolean;
  label: string;
  multiple?: boolean;
  errorStr: string;
  isCurrentFiles?: any[];
  customHeight?: string;
  handleRemove: (fileData: any) => void;
  handleCurrentFilesRemove: (fileData: any) => void;
  handleOpen?: (id: any) => void;
}
const DragAndDropFileUpload: React.FC<DragAndDropFileUploadProps> = ({
  onChange,
  defaultValue,
  name,
  keyName,
  isRequired,
  label,
  multiple,
  errorStr,
  customHeight,
  handleRemove,
  isCurrentFiles,
  handleCurrentFilesRemove,
  handleOpen,
}) => {
  const [dragging, setDragging] = useState(false);
  const [files, setFiles] = useState<any[]>(defaultValue);
  const fileInputRef = useRef<HTMLInputElement>(null);
  // console.log(`isCurrentFiles`);
  // console.log(isCurrentFiles);
  // console.log(`isCurrentFiles`);
  const removeFile = (fileData: any) => {
    const updatedFiles = files.filter((file) => file !== fileData); // Create a new array
    setFiles(updatedFiles);
    if (updatedFiles.length === 0 && fileInputRef.current) {
      fileInputRef.current.value = ""; // Reset the file input
    }
    handleRemove(updatedFiles);
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    
    if (event.target.files) {
      setFiles(Array.from(event.target.files));
      onChange(Array.from(event.target.files));
    }
  };
 
  
  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setDragging(true);
  };

  const handleDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setDragging(false);
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setDragging(false);
    if (event.dataTransfer.files && event.dataTransfer.files[0]) {
      // console.log("File dropped:", event.dataTransfer.files[0]);
      setFiles(Array.from(event.dataTransfer.files));
      onChange(Array.from(event.dataTransfer.files));
    }
  };

  return (
    <div className="mb-3 fileUploadComponent" key={"autoC_" + keyName}>
      <label className="form-label" key={"lbk_auto_" + keyName}>
        {label}
        {isRequired && (
          <small key={"sml_auto_" + keyName} style={{ color: "red" }}>
            {" "}
            *
          </small>
        )}
      </label>
      {isCurrentFiles && isCurrentFiles.length > 0 && (
        <div>
          <div className="fileElement-root-current">
            {Array.from(isCurrentFiles).map((fileData: any) => {
              return (
                <span
                  className="fileElement"
                  onClick={() => {
                    if (handleOpen) {
                      handleOpen(fileData.id);
                    }
                  }}
                >
                  {fileData.name}
                  <Close
                    className="fileElementClose"
                    onClick={() => {
                      handleCurrentFilesRemove(fileData);
                    }}
                  />
                </span>
              );
            })}
          </div>
        </div>
      )}
      <div className="fileElement-root">
        {files.map((fileData: any) => {
          return (
            <span className="fileElement">
              {fileData.name}
              <Close
                className="fileElementClose"
                onClick={() => {
                  removeFile(fileData);
                }}
              />
            </span>
          );
        })}
      </div>
      <div
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        style={{
          border: dragging ? "2px dashed #4caf50" : "2px dashed #DDDFE1",
          padding: "20px",
          textAlign: "left",
          color: "#464A53",
          background: dragging ? "#f0fff0" : "transparent",
          transition: "background-color 0.3s",
          height: customHeight ? customHeight : "200px",
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "flex-start",
        }}
      >
        <Button
          style={{
            width: "100%",
            height: "100%",
            color: "#464A53",
            background: "transparent",
            border: "none",
            boxShadow: "none",
          }}
          component="label"
          role={undefined}
          variant="contained"
          tabIndex={-1}
          startIcon={<AttachFile fontSize="large" />}
        >
          <input
            multiple={multiple ? multiple : false}
            type="file"
            hidden
            name={name}
            ref={fileInputRef}
            key={"file_" + keyName}
            style={{ zIndex: 100 }}
            onChange={handleFileChange}
          />
        </Button>
      </div>
      {errorStr && <span className="bad-field">{errorStr}</span>}
    </div>
  );
};

export default DragAndDropFileUpload;
