import * as React from 'react';
import Grid from "@mui/material/Grid2";
import DragAndDropFileUploadWithBrowse from '../../../components/FileUploadComponentWithBrowse';
interface UploadStepProps {
  fileQuoteMedia: any;
  fileQuoteMediaCurrent:[];
  handleFileChange: (
    files: any
  ) => void;
  handleRemoveFile: (
    file: any
  ) => void;
  handleCurrentFilesRemove: (
    file: any
  ) => void;
  handleOpen:(id: any)=>void;
}
const UploadStep: React.FC<UploadStepProps> = ({ handleRemoveFile,handleCurrentFilesRemove, handleFileChange, fileQuoteMedia,fileQuoteMediaCurrent,handleOpen }) => {
  return (<Grid container spacing={2} style={{ marginTop: "30px" }}>
    {/* Section Header */}
    <Grid size={12}>
      <h1 className="form-label">{'Documents'}</h1>
    </Grid>

    {/* Browse File  Field */}
    <Grid size={12}>
      <DragAndDropFileUploadWithBrowse
        handleRemove={handleRemoveFile}
        handleCurrentFilesRemove={handleCurrentFilesRemove}
        errorStr={''} multiple={true}
        label="Document & Image" isRequired={true} keyName="fileQuoteMedia"
        name="fileQuoteMedia"
        defaultValue={fileQuoteMedia}
        isCurrentFiles={fileQuoteMediaCurrent}
        onChange={handleFileChange}
        handleOpen={handleOpen}
        />
    </Grid>





  </Grid>
  );
}
export default UploadStep;