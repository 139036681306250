import { Autocomplete, Box, TextField } from "@mui/material";
interface AutoCompleteTextFieldComponentProps {
  onChange: (event: any, value: any) => void;
  label: string;
  keyName: string;
  name: string;
  isRequired: boolean;
  value: any;
  placeholder?: string;
  errorStr: string;

  items: any[];
}
const AutoCompleteTextFieldComponent: React.FC<
  AutoCompleteTextFieldComponentProps
> = ({
  onChange,
  label,
  isRequired,
  value,
  keyName,
  name,
  items,
  errorStr,
}) => {
  return (
    <div className="mb-3" key={"ele_" + keyName}>
      <Box sx={{ mb: 1 }} className="form-label" key={"lbk_" + keyName}>
        {label}
        {isRequired && (
          <small style={{ color: "red" }} key={"sml_" + keyName}>
            {" "}
            *
          </small>
        )}
      </Box>
      <Autocomplete
        multiple
        id={"aut_" + keyName}
        disableClearable
        options={items}
        onChange={(e: any, value: any) => {
          onChange(e, value);
        }}
        getOptionLabel={(option) => option.label ? option.label : option.name}
        value={value ? value : []}
        // onChange={onChange}
        renderInput={(params) => (
          <TextField
            hiddenLabel
            {...params}
            label={""}
            name={name}
            value={value}
            id={"txt_" + keyName}
            slotProps={{
              input: {
                ...params.InputProps,
                type: "search",
              },
            }}
          />
        )}
      />
      {errorStr && <span className="bad-field">{errorStr}</span>}
    </div>
  );
};
export default AutoCompleteTextFieldComponent;
