import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Divider } from '@mui/material';
interface InfoDialogProps {
    handleClose: (

       
    ) => void;
    open: boolean;


    title: string;
}
const InfoDialog: React.FC<InfoDialogProps> = ({ handleClose, open, title }) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <React.Fragment>
            <Dialog
                fullScreen={fullScreen}
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
               
            >
            
                <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={(theme) => ({
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
          })}
        >
          <CloseIcon />
        </IconButton>
                <DialogContent >
                <DialogTitle id="responsive-dialog-title" fontWeight={'bold'} fontSize={'20px'}  sx={{color: '#000000'}}> 
                    {title}
                </DialogTitle>
                </DialogContent>
                <Divider />
                <DialogActions >
                <Button  sx={{color:'#00f4ff !important' ,backgroundColor:'#000', }} onClick={handleClose} autoFocus>
                        {'Ok'}
                    </Button>
                   
                  
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}
export default InfoDialog;