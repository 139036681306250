import { Button } from "@mui/material";

interface ResetButtonComponent {
  onClear: (isClear: boolean) => void;
  keyName: string;
}
const ResetButtonComponent: React.FC<ResetButtonComponent> = ({
  onClear,
  keyName,
}) => {
  return (
    <Button
      id={keyName}
      key={keyName}
      onClick={() => {
        onClear(false);
      }}
      variant="contained"
      sx={{ color: "#fff", marginRight: "10px" }}
    >
      Cancel
    </Button>
  );
};
export default ResetButtonComponent;
