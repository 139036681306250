"use client";
import React, { useEffect, useRef, useState } from "react";

import "./Index.css";
import SideBar from "../../../components/Sidebar";
import Header from "../../../components/Header";

import Constants, { getCurrentDate } from "../../../utils/contants";
import HttpService from "../../../services/HttpService";
import { useStore } from "../../../utils/store";
import { useNavigate, useParams } from "react-router-dom";
import DragAndDropFileUpload from "../../../components/FileUploadComponent";
import DatePickerComponent from "../../../components/DatePickerComponent";
import SelectFieldComponent from "../../../components/form/SelectFieldComponent";
import TextFieldComponent from "../../../components/form/TextFieldComponent";
import TextFieldAreaComponent from "../../../components/form/TextFieldAreaComponent";
import { Box, Paper, Stack } from "@mui/material";
import Grid from "@mui/material/Grid2";
import PrimaryButtonComponent from "../../../components/form/PrimaryButtonComponent";
import ResetButtonComponent from "../../../components/form/ResetButtonComponent";
import DragAndDropFileUploadWithBrowse from "../../../components/FileUploadComponentWithBrowse";
const TendersForm: React.FC = () => {
  const isInitialLoad = useRef(true);
  const { getAdmin } = useStore();
  const { currentId } = useParams<{ currentId: any }>();
  const navigate = useNavigate();



  const [uploadFile, setUploadFile] = useState<any[]>([]);

  const [errorMessage, setErrorMessage] = useState<any>(null);

  const tenderType = [{ id: "", label: "Select Document Type" }, { id: "Insurances", label: "Insurances" }, { id: "Capability Statement", label: "Capability Statement" }, { id: "Certificate and Accreditations", label: "Certificate and Accreditations" }];
  const [loading, setLoading] = useState(false);

  const [currentRecord, setCurrentRecord] = useState<any>(null);
  const [currentFilesMedia, setCurrentFilesMedia] = useState<any[]>([]);
  
  const [errors, setErrors] = useState({
    tenderType: "",
    docName: "",
    expiryDate: "",
    fileURL: "",
    uploadFile: ""
  });

  const validateForm = () => {
    const newErrors: any = {};

    // Name Validation
    if (!tenderForm.tenderType.trim()) {
      newErrors.tenderType = "Type  is required.";
    }
    if (!tenderForm.docName) {
      newErrors.docName = "Document Name is required.";
    }

    if (!tenderForm.expiryDate) {
      newErrors.expiryDate = "Expiry Date  is required.";
    }
    if (!uploadFile) {
      newErrors.uploadFile = "File  is required.";
    }
    // Phone Number Validation

    // if (!tenderForm.fileURL) {
    //   newErrors.fileURL = "File is required.";
    // }
    setErrors(newErrors);
    // Return true if no errors
    return Object.keys(newErrors).length === 0;
  };
  interface tenderFormFields {
    tenderType: string,
    docName: string,
    expiryDate: string,
    id?: string | null,
    notes: string,
    fileURL: string,
    fileName?: string

  }
  const [tenderForm, setTenderForm] = useState<tenderFormFields>({
    tenderType: "Insurances",
    docName: "",
    expiryDate: getCurrentDate(),
    id: null,
    notes: "",
    fileURL: "",
    fileName: ""

  });

  useEffect(() => {
    if (currentId) {
      if (currentId !== 'Add') {
        fetchSingleRecord(currentId);
      }
    } else {

      setCurrentRecord(null);
    }
  }, [currentId]);

  const CreateOrUpdate = async (formData: any) => {
    try {
      const response = await HttpService.post(
        Constants.apiURL + Constants.tendersURL,
        formData,
        { headers: { Authorization: "Bearer " + getAdmin() } }
      );
      if (response.success) {
        console.log(JSON.stringify(response),'--------->>>>>>>>>>>>>>>>>>>>>>>>>>>');

        for (var k = 0; k < uploadFile.length; k++) {
           await handleFileUpload(uploadFile[k], "tender", response.tender.id);
        }
        clearForm(false);
      } else {
        setErrorMessage("Failed to add tender. Please try again.");
      }

      console.log("GET Response:", response);
    } catch (error) {
      setErrorMessage("Failed to add tender. Please try again.");
      console.error("Error fetching data:", error);
    }
  };
  const handleFileUpload = async (
    file: any,
    relationType: any,
    relationId: any
  ) => {
    return new Promise(async (resolve, reject) => {
      try {
        if (file) {
          
          const formData = new FormData();
          formData.append("file", file);
          formData.append("relationType", relationType);
          formData.append("relationId", relationId);

          const response = await HttpService.postWithFileUpload(
            Constants.apiURL + Constants.mediaURL,
            formData,
            {
              headers: {
                Authorization: "Bearer " + getAdmin(),
                "Content-Type": "multipart/form-data",
              },
            }
          );
          if (response.success) {
            resolve(response.media);
          } else {
            setErrorMessage("Failed to add Media. Please try again.");
            
            resolve(false);
          }
          console.log("GET Response:", response);
        }
      } catch (error) {
        resolve(false);
        setErrorMessage("Failed to add Media. Please try again.");
        //setAlertOpen(true);
        console.error("Error fetching data:", error);
      }
      //setInProgress(false);
    });
  };



  const fetchSingleRecord = async (id: any) => {
    try {
      const response = await HttpService.get(
        Constants.apiURL + Constants.tendersURL + '/' + id,
        { headers: { Authorization: "Bearer " + getAdmin() } },
        null
      );
      if (response.success) {

        handleRowClick(response.tender);
      } else {
        setErrorMessage("Failed to add tender. Please try again.");
      }

      console.log("GET Response:", response);
    } catch (error) {
      setErrorMessage("Failed to add tender. Please try again.");
      console.error("Error fetching data:", error);
    }
  };

  const clearForm = (isForm: boolean) => {

    setTenderForm({
      expiryDate: "",
      docName: "",
      fileURL: "",
      id: null,
      notes: "",
      tenderType: ''

    });
    setCurrentRecord(null);
    setErrorMessage('');
    navigate('/management/tenders')
  };
  const handleRowClick = async (record: any) => {
    setCurrentRecord(record);

    setTenderForm(
      {
        expiryDate: record.expiryDate,
        docName: record.docName,
        fileURL: record.fileURL,
        id: record.id,
        notes: record.notes,
        tenderType: record.tenderType,
        fileName: record.fileName

      }
    );
    setCurrentFilesMedia(record.documents);
  };
  const handleCurrentFilesRemove = (fileData: any) => {
   
    const updatedFiles = currentFilesMedia.filter(
      (file: any) => file !== fileData
    ); // Create a new array
    setCurrentFilesMedia(updatedFiles);
   
  };


  const handleSave = async () => {

    if (validateForm()) {
      setLoading(true);
      const formData: any = {
        expiryDate:tenderForm.expiryDate,
        docName:tenderForm.docName,
        notes:tenderForm.notes,
        tenderType:tenderForm.tenderType,
      };
     // formData.append('file', uploadFile);
      // formData.append('expiryDate', tenderForm.expiryDate);
      // formData.append('docName', tenderForm.docName);
      // formData.append('notes', tenderForm.notes);
      // formData.append('tenderType', tenderForm.tenderType);
      if (currentRecord) {
        formData['id'] =  currentRecord.id;
        formData['documents'] = currentFilesMedia;

      }
      await CreateOrUpdate(formData);
      setLoading(false);
    }
  }
  const handleExpiryDate = (value: any) => {
    const expiryDateValue = value.year() + '-' + (value.month() + 1) + '-' + value.date();
    setTenderForm((prev: any) => {
      return { ...prev, ['expiryDate']: expiryDateValue };
    });
  }
  const handleRemove = (file: any) => {
    setUploadFile([]);
  }
  const handleFileChange = (file: any) => {
    setUploadFile(file);
  }
  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLFormElement>
  ) => {
    const { name, value, type } = e.target;
    setTenderForm((prev: any) => {
      // Check if the input is a checkbox
      if (type === "checkbox") {
        const target: any = e.target;
        const currentValues = prev[name] || []; // Get the current array or initialize it
        if (target.checked && currentValues.includes(value) === false) {
          return { ...prev, [name]: [...currentValues, value] };
        } else {
          return { ...prev, [name]: currentValues.filter((id: any) => id !== value) };
        }
      } else {
        console.log(value);
        // Handle other input types
        return { ...prev, [name]: value };
      }
    });
  };
  const getMediaRecord = async (id: any) => {
    return new Promise(async (resolve, reject) => {
      const response = await HttpService.get(
        Constants.apiURL + Constants.mediaURL + "/" + id,
        { headers: { Authorization: "Bearer " + getAdmin() } },
        null
      );
      if (response.success) {
        resolve(response.media);
      } else {
        resolve(null);
      }
    });
  };
  const handleDownloadFile = async (ID: any) => {
    const media: any = await getMediaRecord(ID);
    if (media) {
      window.open(media, "_blank");
    }
  };
  return (
    <Stack>
      <Header onSearch={() => { }} links={[{ title: "Home", link: "/dashboard" }, { title: "Data Management", link: "/management" }, { title: "Tender Management", link: "" }]} />
      <SideBar />
      <Box className="mainForm dashboardmain">

        <Paper sx={{ padding: '50px' }}>
          <h1>{currentId !== 'Add' ? 'Update Tender' : 'Add New Tender'}</h1>
          <Grid container spacing={2} style={{ width: '100%', textAlign: "left" }}>
            <Grid size={12}>
              <SelectFieldComponent
                errorStr={errors.tenderType}
                isRequired={true}
                items={tenderType}
                keyName="tenderType"
                label="Type"
                name="tenderType"
                value={tenderForm.tenderType}
                onChange={(e: any) => {
                  handleInputChange(e);
                }}
              />
            </Grid>
            <Grid size={6}> <TextFieldComponent
              name="docName"
              value={tenderForm.docName}
              isRequired={true}
              errorStr={errors.docName}
              keyName="docNameForm"
              label="Document Name"
              onChange={handleInputChange}
              placeholder=""
            /></Grid>
            <Grid size={6}>
              <DatePickerComponent keyName="expiryDateTender" isRequired={true} label="Expiry Date" errorStr={errors.expiryDate} name="expiryDate" onChange={handleExpiryDate} defaultValue={tenderForm.expiryDate} />
            </Grid>

            <Grid size={12}>
              <TextFieldAreaComponent
                label="Notes"
                isRequired={false}
                name="notes"
                errorStr={''}
                minRows={7}
                onChange={handleInputChange}
                placeholder="Enter notes"
                keyName="notesForm"
                value={tenderForm.notes}


              />
            </Grid>
            <Grid size={12}>
          

                 <DragAndDropFileUploadWithBrowse
        handleRemove={handleRemove}
        handleCurrentFilesRemove={handleCurrentFilesRemove}
        errorStr={''} multiple={true}
        label="Attachment" isRequired={true} keyName="fileTenderForm"
        name="fileURL"
        defaultValue={uploadFile}
        isCurrentFiles={currentFilesMedia}
        onChange={handleFileChange}
        handleOpen={handleDownloadFile}
        />
            </Grid>

            <Grid size={12}
              sx={{ textAlign: 'right' }}>
                <ResetButtonComponent
                keyName="rstBtn"
                onClear={() => {
                  clearForm(false);
                }}
              />
              <PrimaryButtonComponent
                loading={loading}
                onSave={handleSave}
                errors={[]}
                keyName="termMainBtn"
                currentRecord={currentRecord}
                  
              />
              
              {errorMessage && <p className="text-danger">{errorMessage}</p>}
            </Grid>
          </Grid>

        </Paper>

      </Box>
    </Stack>
  );
};

export default TendersForm;
