import { Autocomplete, TextField, Box } from "@mui/material";
interface SimpleAutoCompleteTextFieldComponentProps {
  onChange: (event: any, value: any) => void;
  label: string;
  keyName: string;
  name: string;
  isRequired: boolean;
  value: any;
  placeholder?: string;
  errorStr: string;

  items: any[];
}
const SimpleAutoCompleteTextFieldComponent: React.FC<
  SimpleAutoCompleteTextFieldComponentProps
> = ({
  onChange,
  label,
  isRequired,
  value,
  keyName,
  name,
  items,
  errorStr,
}) => {
  return (
    <Box sx={{ mb: 3 }} key={"ele_" + keyName}>
      <Box sx={{ mb: 1 }} className="form-label" key={"lbk_" + keyName}>
        {label}
        {isRequired && (
          <small style={{ color: "red" }} key={"sml_" + keyName}>
            {" "}
            *
          </small>
        )}
      </Box>
      <Autocomplete
        id={"simpaut_" + keyName}
        disableClearable
        options={items}
        onChange={(e: any, value: any) => {
          console.log(value);
          onChange(e, value);
        }}
        getOptionLabel={(option) =>
          option.label ? option.label : option.name ? option.name : option.id
        }
        defaultValue={value ? value : null}
        // onChange={onChange}
        renderInput={(params) => (
          <TextField
            hiddenLabel
            {...params}
            label={""}
            name={name}
            value={value}
            id={"txt_" + keyName}
            slotProps={{
              input: {
                ...params.InputProps,
                type: "search",
              },
            }}
          />
        )}
      />
      {errorStr && <span className="bad-field">{errorStr}</span>}
    </Box>
  );
};
export default SimpleAutoCompleteTextFieldComponent;
