import axios, { AxiosRequestConfig } from 'axios';
import Constants, { getLocalRefreshToken, setLocalAccessToken, setLocalIDToken, setLocalRefreshToken } from '../utils/contants';

let MAX_RETRY_NUMBER = 3
let retryCount = 0
const logOut = () => {
  setLocalRefreshToken('');
  setLocalAccessToken('');
  setLocalIDToken('');
  window.location.replace('/');

}
class HttpService {
  // GET method
  async get(url: string, headers: any, params?: any): Promise<any> {
    if (params) {
      const queryString = new URLSearchParams(params).toString();
      url = url + '?' + queryString;
    }
    try {
      const response = await axios.get(url, headers);
      return response.data;
    } catch (error: any) {
      if (error.status === 401) {
        const responseData = await this.refreshToken();
        if (responseData) {
          console.log(responseData);
         
         return await  this.get(url,  { headers: { Authorization: "Bearer " + responseData } }, null)
        }
        // window.location.href = "/"; // 
      }
      console.error('Error during GET request:', error);
      throw error;
    }
  }
  //delete method 
  async delete(url: string, headers: any, params?: any): Promise<any> {
    if (params) {
      const queryString = new URLSearchParams(params).toString();
      url = url + '?' + queryString;
    }
    try {
      const response = await axios.delete(url, headers);
      return response.data;
    } catch (error: any) {
      console.error('Error during DELETE request:', error);
      throw error;
    }
  }
   // POST method
   async postWithFileUpload(url: string, data: any, options: any): Promise<any> {
    try {
      const response = await axios.post(url, data, options);
      return response.data;
    } catch (error: any) {
      console.error('Error during GET request:', error);
      throw error;
    }
  }

  // POST method
  async post(url: string, data: any, options: AxiosRequestConfig): Promise<any> {
   
    try {
      const response = await axios.post(url, data, options);
      return response.data;
    } catch (error: any) {
      if (error.status === 401) {
        const responseData = await this.refreshToken();
        if (responseData) {
          const optionData = { headers: { Authorization: "Bearer " + responseData } };
          return await   this.post(url, data, optionData)
        }
      }
      console.error('Error during GET request:', error);
      throw error;
    }
  }

  // PUT method
  async put(url: string, data: any, headers: any): Promise<any> {
    try {
      const response = await axios.put(url, data, headers);
      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error(`Error: ${response.status}`);
      }
    } catch (error: any) {
      if (error.status === 401) {
        const responseData = await this.refreshToken();
        if (responseData) {
          const optionData = { headers: { Authorization: "Bearer " + responseData } };
          return await  this.put(url, data, optionData)
        }
      }
      console.error('Error during GET request:', error);
      throw error;
    }
  }
  //PATCH method
  async patch(url: string, data: any, headers: any): Promise<any> {
   
    try {
      const response = await axios.patch(url, data, headers);
      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error(`Error: ${response.status}`);
      }
    } catch (error: any) {
      if (error.status === 401) {
        const responseData = await this.refreshToken();
        if (responseData) {
          const optionData = { headers: { Authorization: "Bearer " + responseData } };
          return await   this.patch(url, data, optionData)
        }
      }
      console.error('Error during GET request:', error);
      throw error;
    }
  }

  async refreshToken() {
    return new Promise((resolve, reject) => {
      const refreshToken  = getLocalRefreshToken();
      if(refreshToken === '' || refreshToken === null) {
        logOut();
      }
      axios({
        url: Constants.apiURL + Constants.REFRESH_TOKEN_URL,
       
        method: 'POST',
        data: {
          AuthParameters: {
            REFRESH_TOKEN: refreshToken,
          }
        },
      } as any)
        .then(response => {
          console.log(response);
          if (response.data
            && response.data.accessToken
            && response.data.idToken
          ) {
            const { accessToken,idToken } = response.data;
            setLocalAccessToken(accessToken);
            setLocalIDToken(idToken);
            if (retryCount < MAX_RETRY_NUMBER) {
              retryCount++
              return  resolve(accessToken);
            }else{
              return resolve(null);
            }
          } else {
            console.log('1');
            logOut();
          }
        }).catch((e)=>{
          console.log('2');
          logOut();
        })
    })
  }

}

export default new HttpService();
