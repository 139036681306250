"use client";
import React, { useEffect, useState } from "react";
import "./Index.css";
import SideBar from "../../components/Sidebar";
import Header from "../../components/Header";
import {
  Box,
  Container,
  Paper,
  Stack,
  Typography
} from "@mui/material";

import Constants from "../../utils/contants";
import HttpService from "../../services/HttpService";
import { useStore } from "../../utils/store";
import { useNavigate } from "react-router-dom";
import CustomPaginationActionsTable from "../../components/TablePagination";
import AddButtonComponent from "../../components/form/AddButtonComponent";
import SimpleDialogComponent from "../../components/SimpleDialogComponent";
import QuoteDialogComponent from "./QuoteForm";

const QuoteDashboard: React.FC = () => {
  const { getAdmin } = useStore();
  const navigate = useNavigate();
  // Columns definition for the table
  const columns: any[] = [
    { field: "id", headerName: "Quote" },
   
    {
      field: "created",
      headerName: "Date",
      valueGetter: (value: any) =>
        new Date(value).toLocaleDateString("default", {
          month: "short",
          day: "numeric",
          year: "numeric",
        }),
    },
    {
      field: "customerName",
      headerName: "Customer"
    },
    {
      field: "projectName",
      headerName: "Project Name"
    },
    {
      field: "quoteStatusId",
      headerName: "Status",
    
      valueGetter: (value: any) =>
        getStatusText(value)
    
    },
    {
      field: "quoteTotalPrice",
      headerName: "Total",
      valueGetter: (value: any) =>
        parseFloat(value).toFixed(2)
    }
  ];

  // State variables
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [dialogTitle, setDialogTitle] = useState("Add New Quote");
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  
  const [loading, setLoading] = useState(false);
  const [currentId, setCurrentId] = useState<any>(null);
  const [rows, setRows] = useState<any[]>([]);
  const [paginate, setPaginate] = useState({ limit: Constants.DEFAULT_PAGE_SIZE, offset: 0 });
const [quoteStatusList, setQuoteStatusList] = React.useState<any[]>([]);
  // Fetch terms list
  const fetchData = async () => {
    try {
     
      try {
        const response = await HttpService.get(
          Constants.apiURL + Constants.quoteURL,
          { headers: { Authorization: "Bearer " + getAdmin() } },
          { search: searchQuery, limit: paginate.limit, offset: paginate.offset },
        );
        if (response.success) {
          setRows(response.data.quotes);
  
          setTotalRecords(response.data.total);
        } else {
          setErrorMessage("Failed to add tender. Please try again.");
        }
  
        console.log("GET Response:", response);
      } catch (error) {
        setErrorMessage("Failed to add tender. Please try again.");
        console.error("Error fetching data:", error);
      }
     
    } catch (error) {
      setErrorMessage(Constants.COMMON_SERVER_ERROR);
    }
  };

  useEffect(() => {
    if(quoteStatusList.length === 0)
      fetchStatusList();
  }, []);


  useEffect(() => {
    fetchData();
  }, [searchQuery, paginate]);
  // Handle status toggle
  const handleStatus = async (row: any) => {
    const newStatus = row.status === "1" ? "0" : "1";
    try {
      const response = await HttpService.patch(
        Constants.apiURL + Constants.termsURL,
        { status: newStatus, id: row.id },
        { headers: { Authorization: `Bearer ${getAdmin()}` } }
      );
      if (response.success) {
        fetchData();
      } else {
        setErrorMessage("Failed to update status. Please try again.");
      }
    } catch (error) {
      setErrorMessage("Failed to update status. Please try again.");
    }
  };

  const fetchStatusList = async () => {
    try {
        const statusList: any = await fetchBundle('fetchQuoteStatuses');
        if (statusList) {
            setQuoteStatusList([{ id: '', name: 'Select Status' }, ...statusList]);
            
        } else {
            setErrorMessage(statusList);
        }
    } catch (error) {
        setErrorMessage('');
        console.error(error);
    }
}
const fetchBundle = async (type: string) => {
  return new Promise(async (resolve, reject) => {
      try {
          const URL = `${Constants.apiURL}${Constants.refBundleURL}/${type}`;
          const response = await HttpService.get(
              URL,
              { headers: { Authorization: "Bearer " + getAdmin() } },
              { search: '', limit: -1, offset: 0 },
          );
          if (response.success) {
              resolve(response.data)
          } else {
              reject('Failed to Fetch List. Please try again.');
          }
          console.log("GET Response:", response);
      } catch (error) {
          reject("Failed to Find List. Please try again.");
          console.error("Error fetching data:", error);
      }
  })

}
const getStatusText = (quoteStatusId: any)=>{
  const statusData: any = quoteStatusList.find((item: any) => item.id.toString() === quoteStatusId.toString());
  if(statusData){
    return statusData.name;
  }else{
    return quoteStatusId;
  }
}
  // Clear form and reset state
  const handlePagination = (paginationData: any) => setPaginate(paginationData);
  const handleSearchInput = (event: React.ChangeEvent<HTMLInputElement>) =>
    setSearchQuery(event.target.value);
  return (
    <Stack>
      <Header onSearch={handleSearchInput} links={[{title:"Home",link:"/dashboard"}, {title:"Quote Dashboard",link:""}]} />
      <SideBar />
      <Box  className="main dashboardmain">
      
        <section className="section dashboard">
          <div className="containergrid">
            <div className="mt-2">
                <Paper sx={{ width: "100%" }}>
                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center",padding:'10px', mb: 2 }}>
                  <Typography variant="h6">All Quotes</Typography> 
                  <AddButtonComponent onClick={() => {
                    navigate(`/quote/Add`)
                  }} keyName="quoteaddicon" label="Add New" />
                </Box>
                  <CustomPaginationActionsTable
                    rows={rows}
                    columns={columns}
                    totalRecords={totalRecords}
                    handlePagination={handlePagination}
                    handleRowClick={(row)=>{
                      navigate(`/quote/${row.id}`)
                    }}
                    paginationModel={{ page: paginate.offset, pageSize: paginate.limit }}
                    handleStatus={handleStatus}
                  />
                </Paper>
            </div>
          </div>
        
        </section>
       
      </Box>
    </Stack>
  );
};

export default QuoteDashboard;
