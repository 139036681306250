"use client";
import React, { useEffect, useState } from "react";
import "../Index.css";
import SideBar from "../../../../components/Sidebar";
import Header from "../../../../components/Header";
import {
  Box,
  Paper,
  Stack,
  Typography
} from "@mui/material";
import AddButtonComponent from "../../../../components/form/AddButtonComponent";
import Constants from "../../../../utils/contants";
import HttpService from "../../../../services/HttpService";
import { useStore } from "../../../../utils/store";
import { useNavigate } from "react-router-dom";
import CustomPaginationActionsTable from "../../../../components/TablePagination";
import SwitchComponent from "../../../../components/SwitchComponent";


const StandardTerms: React.FC = () => {
  const { getAdmin } = useStore();
  const navigate = useNavigate();
  // Columns definition for the table
  const columns: any[] = [
    { field: "termText", headerName: "Text" },
    {
      field: "status",
      headerName: "Active",
      renderCell: (value: any) => (
        <SwitchComponent
          checked={value === "1"}
          value={value}
          size="small"
          color="success"
          inputProps={{ "aria-label": "controlled" }}
          handleInputChange={()=>console.log('clickstatus')}
          name={'statuseterm'}
        />
      ),
    },
    {
      field: "updated",
      headerName: "Last Updated",
      valueGetter: (value: any) =>
        new Date(value).toLocaleDateString("default", {
          month: "short",
          day: "numeric",
          year: "numeric",
        }),
    },
    {
      field: "created",
      headerName: "Date Created",
      valueGetter: (value: any) =>
        new Date(value).toLocaleDateString("default", {
          month: "short",
          day: "numeric",
          year: "numeric",
        }),
    },
  ];

  // State variables
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState<any[]>([]);
  const [paginate, setPaginate] = useState({ limit: Constants.DEFAULT_PAGE_SIZE, offset: 0 });

  // Fetch terms list
  const fetchData = async () => {
    try {
      const response = await HttpService.get(
        Constants.apiURL + Constants.termsURL,
        { headers: { Authorization: `Bearer ${getAdmin()}` } },
        { search: searchQuery, limit: paginate.limit, offset: paginate.offset },
      );
      if (response.success) {
        setRows(response.data.paginateRecords);
        setTotalRecords(response.data.total);
      } else {
        setErrorMessage(Constants.COMMON_SERVER_ERROR);
      }
    } catch (error) {
      setErrorMessage(Constants.COMMON_SERVER_ERROR);
    }
  };

  


  useEffect(() => {
    fetchData();
  }, [searchQuery, paginate]);
  // Handle status toggle
  const handleStatus = async (row: any) => {
    const newStatus = row.status === "1" ? "0" : "1";
    try {
      const response = await HttpService.patch(
        Constants.apiURL + Constants.termsURL,
        { status: newStatus, id: row.id },
        { headers: { Authorization: `Bearer ${getAdmin()}` } }
      );
      if (response.success) {
        fetchData();
      } else {
        setErrorMessage("Failed to update status. Please try again.");
      }
    } catch (error) {
      setErrorMessage("Failed to update status. Please try again.");
    }
  };

  // Clear form and reset state


  const handlePagination = (paginationData: any) => setPaginate(paginationData);

  const handleSearchInput = (event: React.ChangeEvent<HTMLInputElement>) =>
    setSearchQuery(event.target.value);

  return (
    <Stack>
      <Header onSearch={handleSearchInput} links={[{title:"Home",link:"/dashboard"}, {title:"Data Management",link:"/management"}, {title:"Standard Terms & Conditions",link:""}]} /> 
      <SideBar />
      <Box className="main dashboardmain">
        
        <section className="section dashboard">
          <div className="containergrid">
            <div className="mt-2">
                <Paper sx={{ width: "100%" }} className="mt-4">
                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: '10px', mb: 2 }}>
                  <Typography variant="h6"> Standard Terms & Conditions</Typography>
                  <AddButtonComponent onClick={() => {
                    navigate("/management/terms/Add")
                    //setDialogOpen(true);

                  }} keyName="quoteaddicon" label="Add New" />
                </Box>
                  <CustomPaginationActionsTable
                    rows={rows}
                    columns={columns}
                    totalRecords={totalRecords}
                    handlePagination={handlePagination}
                    handleRowClick={(row) => navigate(`/management/terms/${row.id}`)}
                    paginationModel={{ page: paginate.offset, pageSize: paginate.limit }}
                    handleStatus={handleStatus}
                  />
                </Paper>
            </div>
          </div>
        </section>
          
      </Box>
    </Stack>
  );
};

const fabStyle = {
  position: "absolute",
  bottom: 16,
  right: 16,
  backgroundColor: "#000",
  color: "#fff",
  "&:hover": {
    backgroundColor: "#000",
    color: "#fff",
  },
};

export default StandardTerms;
