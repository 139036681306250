import AddIcon from '@mui/icons-material/Add';
import { Button } from '@mui/material';
interface ButtonComponentProps {
  onClick: (
  ) => void;
  label: string;
  keyName: string;
}
const AddButtonComponent: React.FC<ButtonComponentProps> = ({ onClick, label, keyName }) => {
  return (
    <Button
      style={{
        textDecoration: 'none',
        textTransform: 'capitalize',
        color: 'rgba(0, 244, 255, 1)',
        background: 'rgb(1, 1, 1)'
      }}
      id={keyName}
      key={keyName}
      variant="contained" onClick={() => {
        onClick()
      }} startIcon={<AddIcon sx={{color:'#fff'}} />}>
      {label}
    </Button>
  );
}
export default AddButtonComponent;