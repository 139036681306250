import * as React from 'react';
import Grid from "@mui/material/Grid2";
import { List, ListItem, ListItemText,ListItemIcon,Checkbox } from '@mui/material';
import SwitchComponent from '../../../components/SwitchComponent';


interface BillableExclusionSubStepProps {
  billableFormItems:any[];
  selectedExclusions: any[];
  handleBillableExclusionSelect: (selected: any) => void;
  
}
  
const BillableExclusionSubStep: React.FC<BillableExclusionSubStepProps> = ({ billableFormItems,handleBillableExclusionSelect,selectedExclusions }) => {
  
 
  return (
 
  <Grid container spacing={2} style={{ marginTop: "30px" }}>
    {/* Section Header */}
   

    <Grid size={12}>
      <List>
      {billableFormItems.map((item,index)=>{
        return item.exclusion ? <ListItem sx={{
          display: "list-item",
         
        }} key={'billable_exclusion_name_list_'+index}>
          <ListItemText sx={{fontWeight: 'bold'}}>
            {item.name}

            </ListItemText>
            <ListItem sx={{
          display: "inline-flex",
         
        }} key={'billable_exclusion_list_'+index}>
            <ListItemIcon>
                <SwitchComponent
                 
                  checked={selectedExclusions.includes(item)}
                  handleInputChange={()=>{
                    handleBillableExclusionSelect(item)
                  }}
                  name={'billable_exclusion_list_'+index}
                  value={item}

                />
              </ListItemIcon>
          <ListItemText sx={{fontWeight: 'bold'}}>
            {item.exclusion}
            </ListItemText>
          </ListItem>
          </ListItem> : <span></span>
      })} 
      </List>
    </Grid>





  </Grid>
  
  );
}
export default BillableExclusionSubStep;