import * as React from "react";
import Grid from "@mui/material/Grid2";
import TextFieldComponent from "../../../components/form/TextFieldComponent";
import SimpleAutoCompleteTextFieldComponent from "../../../components/form/SimpleAutoCompleteTextFieldComponent";
import AddIcon from "@mui/icons-material/Add";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import TotalComponent from "./TotalComponent";
import DescriptionIcon from "@mui/icons-material/Description";
import SimpleDialogComponent from "../../../components/SimpleDialogComponent";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";
import ImageIcon from "@mui/icons-material/Image";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
interface PricingStepSummaryProps {
  companyDetails: any;
  projectForm: any;
  expandRowState: any[];
  billableFormItems: any[];
  currentProject: any;
  projectTemplateList: any[];

  handleAutoCompleteChange: (e: any, dataValue: any, typeData: string) => void;
  handleBillableDialog: (open: boolean) => void;
  handleArrow: (index: number) => void;
  removeItem: (index: number) => void;

  handleBillableDetailChange: (value: any, index: number, type: any, detailIndex: number) => void;
  removeDetailsItem: (index: number, detailIndex: number) => void;
  viewOnly: boolean;
  overheads: number;
  forcasts: number;
  handleOverHeads(num: number): void;
  handleForecasts(num: number): void;
  projectPercentage: number;
  handleProjectPercentage(num: number): void;
  GST: number;
  handleAttached(mainIndex: number, index: number, mediaIndex: number): void;
  handleOpen: (id: any) => void;
}
const PricingStepSummary: React.FC<PricingStepSummaryProps> = ({
  companyDetails,
  projectForm,
  expandRowState,
  billableFormItems,
  currentProject,
  projectTemplateList,
  handleAutoCompleteChange,
  handleBillableDialog,
  handleArrow,
  removeItem,
  handleBillableDetailChange,
  removeDetailsItem,
  viewOnly,
  forcasts,
  overheads,
  handleOverHeads,
  handleForecasts,
  projectPercentage,
  handleProjectPercentage,
  GST,
  handleAttached,
  handleOpen,
}) => {
  const [mediaDialogOpen, setMediaDialogOpen] = React.useState(false);
  const [currentMedia, setCurrentMedia] = React.useState<any>(null);
  const [currentIndex, setCurrentIndex] = React.useState(0);
  const [currentDetailIndex, setCurrentDetailIndex] = React.useState(-1);
  const [mainIndex, setMainIndex] = React.useState(-1);
  const [isAttached, setIsAttached] = React.useState(false);

  const handleNext = () => {
    setCurrentIndex((prevIndex: number) => (prevIndex + 1) % currentMedia.items[0]["medias"].length);
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex: number) => (prevIndex - 1 + currentMedia.items[0]["medias"].length) % currentMedia.items[0]["medias"].length);
  };
  const getFileType = (filename: any, id: any) => {
    if (!filename.includes(".")) return "Unknown";

    const extension = filename.split(".").pop().toLowerCase();

    const imageCatgories = ["jpg", "jpeg", "png", "gif", "bmp", "svg", "webp"];
    const pdfDocCategories = ["pdf"];
    if (imageCatgories.indexOf(extension) !== -1) {
      return (
        <ImageIcon
          onClick={() => {
            handleOpen(id);
          }}
          sx={{ fontSize: "200px", cursor: "pointer" }}
        />
      );
    } else if (pdfDocCategories.indexOf(extension) !== -1) {
      return (
        <PictureAsPdfIcon
          onClick={() => {
            handleOpen(id);
          }}
          sx={{ fontSize: "200px", cursor: "pointer" }}
        />
      );
    } else {
      return (
        <InsertDriveFileIcon
          onClick={() => {
            handleOpen(id);
          }}
          sx={{ fontSize: "200px", cursor: "pointer" }}
        />
      );
    }
  };
  const renderFilesContent = () => {
    return (
      <div className="row mb-3 mt-2">
        {currentMedia && (
          <div style={{ width: "800px" }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                gap: 2,
                mt: 5,
                background: "#f9f9f9",
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <IconButton onClick={handlePrev} color="primary">
                  <ArrowBackIos />
                </IconButton>
                <div className="">
                  {getFileType(currentMedia.items[0]["medias"][currentIndex].name, currentMedia.items[0]["medias"][currentIndex].id)}
                </div>
                <IconButton onClick={handleNext} color="primary">
                  <ArrowForwardIos />
                </IconButton>
              </Box>
              <Box>{currentIndex + 1 + " of " + currentMedia.items[0]["medias"].length}</Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                gap: 2,

                background: "#fff",
              }}
            >
              <Grid
                container
                spacing={2}
                style={{
                  marginTop: "20px",
                  width: "100%",
                  textAlign: "center",
                }}
              >
                <Grid size={4}>
                  <h6>Name</h6>
                  {currentMedia.name}
                </Grid>
                <Grid size={4}>
                  <h6>Upload Date</h6>
                  {currentMedia.items[0]["medias"][currentIndex].created}
                </Grid>
                <Grid size={4}>
                  <h6>Contractor Name</h6>
                  {currentMedia.items[0].contractorName}
                </Grid>
                <Grid size={12} sx={{ textAlign: "left", marginLeft: "30px" }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        value={isAttached}
                        checked={isAttached}
                        onChange={(e: any) => {
                          if (e.target.checked) {
                            setIsAttached(true);
                            return;
                          }
                          if (!e.target.checked) {
                            setIsAttached(false);
                            return;
                          }
                        }}
                      />
                    }
                    label="Do you want to attach resource information to quote?"
                  />
                </Grid>
              </Grid>
            </Box>

            <Box
              sx={{
                display: "flex",
                alignItems: "end",
                justifyContent: "center",
                flexDirection: "column",
                gap: 3,

                background: "#fff",
              }}
            >
              <Divider></Divider>
              <Button
                color="primary"
                sx={{ color: "#fff !important" }}
                variant="contained"
                onClick={() => {
                  if (isAttached) {
                    handleAttached(mainIndex, currentDetailIndex, currentIndex);
                    setMediaDialogOpen(false);
                  }
                }}
              >
                Save
              </Button>
            </Box>
          </div>
        )}
      </div>
    );
  };
  const getLineTotal = (itemData: any) => {
    var lineTotal: number = 0;
    if (itemData.resources.length > 0) {
      lineTotal = 0;
      for (var i = 0; i < itemData.resources.length; i++) {
        lineTotal = lineTotal + parseFloat(itemData.resources[i]["items"][0].price) * parseInt(itemData.resources[i]["items"][0].quantity);
      }
      return lineTotal.toFixed(2);
    } else {
      return 0.0;
    }
  };
  return (
    <Grid container spacing={2} style={{ marginTop: "30px" }}>
      {/* Section Header */}
      {viewOnly === false && (
        <Grid size={12}>
          <h1 className="form-label">{"Pricing"}</h1>
        </Grid>
      )}

      {/* Quote Status Select Field */}
      {viewOnly === false && (
        <Grid size={12}>
          <SimpleAutoCompleteTextFieldComponent
            name="projectTemplateId2"
            value={currentProject}
            isRequired={false}
            errorStr={""}
            keyName="projectTemplateId2"
            label="Project Templates"
            onChange={(e: any, value: any) => {
              handleAutoCompleteChange(e, value, "projectTemplateId");
            }}
            items={projectTemplateList}
          />
        </Grid>
      )}
      <Grid size={12}>
        {viewOnly === false && (
          <Button
            variant="outlined"
            startIcon={<AddIcon />}
            className="primarybtn"
            onClick={() => {
              handleBillableDialog(true);
            }}
          >
            Add Billable Description
          </Button>
        )}
        {billableFormItems.length > 0 && (
          <TableContainer sx={{ mt: 2 }} component={Paper}>
            <Table className="">
              {billableFormItems.map((item: any, index: any) => {
                return (
                  <TableBody key={"tblbody_" + index}>
                    {index === 0 && (
                      <TableRow className="headrowtbl" key={"headrow_" + index} style={{ background: " rgba(0, 244, 255, 0.06)" }}>
                        <TableCell key={"tblheadclldesc_" + index} className="boldfont">
                          Billable Description
                        </TableCell>
                        <TableCell key={"tblheadcllitemc_" + index} className="boldfont">
                          Resource Items
                        </TableCell>
                        <TableCell key={"tblbotblheadclliqty_" + index} className="boldfont" style={{ width: "100px" }}>
                          Quanity
                        </TableCell>
                        <TableCell key={"tblheadcllcost_" + index} className="boldfont" style={{ width: "150px" }}>
                          Cost ($)
                        </TableCell>
                        <TableCell key={"tblheadcluom_" + index} className="boldfont" style={{ width: "100px" }}>
                          UOM
                        </TableCell>
                        <TableCell key={"tblheadclttl_" + index} className="boldfont" style={{ width: "120px" }} colSpan={viewOnly === false ? 2 : 1}>
                          Total ($)
                        </TableCell>
                      </TableRow>
                    )}
                    <TableRow key={"bodyrow_" + index}>
                      <TableCell key={"bodyrow_cell_name" + index}>
                        {expandRowState.indexOf(index) !== -1 && (
                          <KeyboardArrowDownIcon
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              handleArrow(index);
                            }}
                          />
                        )}
                        {expandRowState.indexOf(index) === -1 && (
                          <ExpandLessIcon
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              handleArrow(index);
                            }}
                          />
                        )}
                        {item.name}
                      </TableCell>
                      <TableCell key={"bodyrow_cell_blnk" + index}></TableCell>
                      <TableCell key={"bodyrow_cell_qty" + index}>1</TableCell>
                      <TableCell key={"bodyrow_cell_lst_blnk" + index}></TableCell>
                      <TableCell key={"bodyrow_cell_lst_each" + index}>Each</TableCell>
                      <TableCell key={"bodyrow_cell_lst_linettl" + index}>{getLineTotal(item)}</TableCell>
                      {viewOnly === false && (
                        <TableCell key={"bodyrow_cell_lst_rmitem_main" + index} style={{ width: "50px" }}>
                          <IconButton
                            key={"bodyrow_cell_lst_rmitem_main_icn_btn" + index}
                            aria-label="delete"
                            onClick={() => {
                              removeItem(index);
                            }}
                          >
                            <DeleteForeverIcon key={"bodyrow_cell_lst_rmitem_main_icn_btn_icon" + index} />
                          </IconButton>
                        </TableCell>
                      )}
                    </TableRow>
                    {item["resources"].map((detailItem: any, detailIndex: any) => {
                      return (
                        <TableRow className={expandRowState.indexOf(index) !== -1 ? "hidden" : ""} key={"bodyrow_dtl_" + index + "_" + detailIndex}>
                          <TableCell key={"bodyrow_dtl_cell_blnk-" + index + "_" + detailIndex}></TableCell>
                          <TableCell key={"bodyrow_dtl_cell_name-" + index + "_" + detailIndex}>
                            {detailItem.name}
                            {viewOnly === false && detailItem["items"][0].medias.length > 0 && (
                              <DescriptionIcon
                                onClick={() => {
                                  setCurrentMedia(detailItem);
                                  setCurrentIndex(0);
                                  setCurrentDetailIndex(detailIndex);
                                  setMainIndex(index);
                                  setMediaDialogOpen(true);
                                }}
                                sx={{ cursor: "pointer" }}
                              />
                            )}
                          </TableCell>
                          <TableCell key={"bodyrow_dtl_cell_qty-" + index + "_" + detailIndex}>
                            {viewOnly === false && (
                              <TextFieldComponent
                                label=""
                                keyName={"qtyFormBillable_" + index}
                                name={"qtyFormBillable_" + index}
                                value={detailItem["items"][0].quantity}
                                onChange={(event) => {
                                  handleBillableDetailChange(event.target.value, index, "quantity", detailIndex);
                                }}
                                errorStr={""}
                                type="number"
                                isRequired={false}
                                style={{ width: "60px" }}
                              />
                            )}
                            {viewOnly && <span>{detailItem["items"][0].quantity}</span>}
                          </TableCell>
                          <TableCell key={"bodyrow_dtl_cell_cost-" + index + "_" + detailIndex}>
                            {viewOnly === false && (
                              <TextFieldComponent
                                label=""
                                keyName={"costFormBillable_" + index}
                                name={"costFormBillable_" + index}
                                value={detailItem["items"][0].price}
                                onChange={(event: any) => {
                                  handleBillableDetailChange(event.target.value, index, "price", detailIndex);
                                }}
                                errorStr={""}
                                type="number"
                                isRequired={false}
                                style={{ width: "80px" }}
                              />
                            )}
                            {viewOnly && <span>{detailItem["items"][0].price}</span>}
                          </TableCell>
                          <TableCell key={"bodyrow_dtl_cell_unit-" + index + "_" + detailIndex}>{detailItem.unit}</TableCell>
                          <TableCell key={"bodyrow_dtl_cell_qty-ttl-" + index + "_" + detailIndex}>
                            {parseFloat(detailItem["items"][0].price) * parseInt(detailItem["items"][0].quantity)}
                          </TableCell>
                          <TableCell style={{ width: "50px" }}>
                            {viewOnly === false && (
                              <IconButton
                                key={"bodyrow_dtl_cell_delet-icn-" + index + "_" + detailIndex}
                                aria-label="delete"
                                onClick={() => {
                                  removeDetailsItem(index, detailIndex);
                                }}
                              >
                                <DeleteForeverIcon key={"bodyrow_dtl_cell_only-icn-" + index + "_" + detailIndex} />
                              </IconButton>
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                );
              })}

              {/* </TableBody> */}
            </Table>
          </TableContainer>
        )}
      </Grid>

      <Grid size={12}>
        <TotalComponent
          GST={GST}
          projectPercentage={projectPercentage}
          handleProjectPercentage={handleProjectPercentage}
          overheads={overheads}
          forcasts={forcasts}
          handleForecasts={handleForecasts}
          handleOverHeads={handleOverHeads}
          viewOnly={viewOnly}
          companyDetails={companyDetails}
          billableFormItems={billableFormItems}
          projectForm={projectForm}
        ></TotalComponent>
      </Grid>

      <SimpleDialogComponent
        title="View Resource Item"
        dialogContent={renderFilesContent()}
        open={mediaDialogOpen}
        handleClose={() => {
          setMediaDialogOpen(false);
        }}
      ></SimpleDialogComponent>
    </Grid>
  );
};
export default PricingStepSummary;
