import { Select, Box, MenuItem } from "@mui/material";
interface SelectFieldComponentProps {
  onChange: (event: any) => void;
  label: string;
  keyName: string;
  name: string;
  isRequired: boolean;
  value: string;
  errorStr: string;
  items: any[];
}
const SelectFieldComponent: React.FC<SelectFieldComponentProps> = ({
  onChange,
  label,
  isRequired,
  value,
  keyName,
  name,
  errorStr,
  items,
}) => {
  return (
    <Box sx={{ mb: 3 }} key={"ele_" + keyName}>
      <Box sx={{ mb: 1 }} className="form-label" key={"lbl_" + keyName}>
        {label}
        {isRequired && (
          <small style={{ color: "red" }} key={"sm_" + keyName}>
            {" "}
            *
          </small>
        )}
      </Box>
      <Select
        className="form-select"
        id={keyName}
        key={keyName}
        name={name}
        value={value}
        onChange={(e: any) => {
          onChange(e);
        }}
        fullWidth
      >
        {items.map((item, index) => (
          <MenuItem key={keyName + "_" + index} value={item.id}>
            {item.label ? item.label : item.name ? item.name : item.id}
          </MenuItem>
        ))}
      </Select>
      {errorStr && <span className="bad-field">{errorStr}</span>}
    </Box>
  );
};
export default SelectFieldComponent;
