import { Button } from "@mui/material";

interface PrimaryButtonComponentProps {
  onSave: () => void;
  loading: boolean;
  errors: any;
  currentRecord?: any;
  keyName: string;
}
const PrimaryButtonComponent: React.FC<PrimaryButtonComponentProps> = ({
  onSave,
  loading,
  errors,
  currentRecord,
  keyName,
}) => {
  return (
    <Button
      id={keyName}
      key={keyName}
      variant="contained"
      sx={{ bgcolor: "#000b29", color: "#fff" }}
      disabled={loading || Object.keys(errors).length > 0}
      onClick={() => {
        onSave();
      }}
    >
      {loading ? "Adding wait..." : currentRecord ? "Update" : "Add"}
    </Button>
  );
};
export default PrimaryButtonComponent;
