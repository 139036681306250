import React, { createContext, useContext, useState } from "react";
import { getLocalAccessToken,getLoginName } from "./contants";

interface Store {
  isAdmin: string;
  setIsAdmin: (value: string) => void;
  getAdmin: () => string;
  isLoginUsername: string;
  setIsLoginUsername: (value: string) => void;
  getLoginUsername: () => string;
}

// Create the context with default values
const StoreContext = createContext<Store | undefined>(undefined);

// Provider component
export const StoreProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [isAdmin, setIsAdmin] = useState<any>(null);
  const [isLoginUsername, setIsLoginUsername] = useState<string>('');
  const getAdmin = () => {
    let strToken: any = "";
    if (isAdmin) {
      return isAdmin;
    } else {
      strToken = getLocalAccessToken();
      return strToken;
    }
  };
  const getLoginUsername = () => {
    let strToken: any = "";
    if (isLoginUsername) {
      return isLoginUsername;
    } else {
      strToken = getLoginName();
      return strToken;
    }
  };
  return (
    <StoreContext.Provider value={{ isAdmin, setIsAdmin, getAdmin,isLoginUsername,setIsLoginUsername,getLoginUsername }}>
      {children}
    </StoreContext.Provider>
  );
};

// Custom hook to use the store
export const useStore = (): Store => {
  const context = useContext(StoreContext);
  if (!context) {
    throw new Error("useStore must be used within a StoreProvider");
  }
  return context;
};
