"use client";
import React, { useEffect, useState } from "react";
import "./Index.css";
import SideBar from "../../../components/Sidebar";
import Header from "../../../components/Header";
import Constants from "../../../utils/contants";
import HttpService from "../../../services/HttpService";
import { useStore } from "../../../utils/store";
import TextFieldComponent from "../../../components/form/TextFieldComponent";
import TextFieldAreaComponent from "../../../components/form/TextFieldAreaComponent";
import PrimaryButtonComponent from "../../../components/form/PrimaryButtonComponent";
import ResetButtonComponent from "../../../components/form/ResetButtonComponent";
import { useNavigate, useParams } from "react-router-dom";
import { Box, Paper, Stack } from "@mui/material";
import Grid from "@mui/material/Grid2";
const CustomerForm: React.FC = () => {
  const { getAdmin } = useStore();
  const { currentId } = useParams<{ currentId: any }>();
  const navigate = useNavigate();
  // State Management
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [currentRecord, setCurrentRecord] = useState<any>(null);

  const [customerForm, setCustomerForm] = useState({
    id: null,
    name: "",
    tradingName: "",
    abn: "",
    address: "",
    email: "",
    phone: "",
    customerID: "",
    creditLimit: "",
    notes: "",

  });
  const [errors, setErrors] = useState({
    name: "",
    tradingName: "",
    email: "",
    phone: "",
    customerID: "",
    abn: "",
    address: "",
  });


  useEffect(() => {
    if (currentId) {
      if (currentId !== 'Add') {
        fetchSingleRecord(currentId);
      }
    } else {
      setCurrentRecord(null);
    }
  }, [currentId]);

  const fetchSingleRecord = async (id: any) => {
    try {
      const response = await HttpService.get(
        Constants.apiURL + Constants.customersURL + '/' + id,
        { headers: { Authorization: "Bearer " + getAdmin() } },
        null
      );
      if (response.success) {

        handleRowClick(response.customer);
      } else {
        setErrorMessage(Constants.COMMON_SERVER_ERROR);
      }

      console.log("GET Response:", response);
    } catch (error) {
      setErrorMessage(Constants.COMMON_SERVER_ERROR);
      console.error("Error fetching data:", error);
    }
  };
  // Form Handling
  const handeCustomerFormChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setCustomerForm((prev) => ({ ...prev, [name]: value }));
    if (name !== 'notes' && name !== 'creditLimit') {
      validateCustomerFormField(e.target);
    }

  };



  const clearForm = () => {

    setCustomerForm({
      name: "",
      email: "",
      tradingName: "",
      address: "",
      phone: "",
      customerID: "",
      creditLimit: "",
      notes: "",
      abn: "",
      id: null
    });
    setErrorMessage(null);
    setCurrentRecord(null);
    navigate('/management/customers')
  };

  const handleSave = async () => {
    if (validateCustomerForm()) {
      setLoading(true);
      const endpoint = Constants.apiURL + Constants.customersURL;
      if (currentRecord) {
        customerForm['id'] = currentRecord.id;
      }
      let response: any;
      try {
        if (currentRecord) {
          response = await HttpService.put(endpoint, customerForm, {
            headers: { Authorization: `Bearer ${getAdmin()}` },
          });
        } else {
          response = await HttpService.post(endpoint, customerForm, {
            headers: { Authorization: `Bearer ${getAdmin()}` },
          });
        }
        if (response.success) {

          clearForm();
        } else {
          setErrorMessage(Constants.COMMON_SERVER_ERROR);
        }
      } catch {
        setErrorMessage(Constants.COMMON_SERVER_ERROR);
      } finally {
        setLoading(false);
      }
    }

  };

  const handleRowClick = (record: any) => {
    setCurrentRecord(record);
    setCustomerForm({ ...record });

  };

  const checkEmail = (email: string) => {
    return new Promise(async (resolve, reject) => {
      try {
        let url = Constants.apiURL + Constants.customersURL + '/findByEmail/' + email;
        if (currentRecord) {
          url = url + '?customerId=' + currentRecord.id
        }
        const response = await HttpService.get(
          url,
          { headers: { Authorization: "Bearer " + getAdmin() } },
          null
        );
        return resolve(response.customer);
      } catch (error) {
        return resolve(null);
      }
    })

  };
  const validateCustomerFormField = async (element: any) => {
    const elementName = element.name;
    const newErrors: any = {};
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phoneRegex = /^(\+?\(61\)|\(\+?61\)|\+?61|\(0[1-9]\)|0[1-9])?( ?-?[0-9]){7,9}$/; // Validates a 10-digit phone number
    switch (elementName) {
      case 'name':
        if (!element.value.trim()) {
          newErrors.name = "Name is required.";
        }
        break;
      case 'tradingName':
        if (!element.value.trim()) {
          newErrors.tradingName = "Trading name is required.";
        }
        break;
      case 'address':
        if (!element.value.trim()) {
          newErrors.address = "address is required.";
        }
        break;
      case 'customerID':
        if (!element.value.trim()) {
          newErrors.customerID = "CustomerID is required.";
        }
        break;
      case 'email':
        if (!element.value) {
          newErrors.email = "Email is required.";
        } else if (!emailRegex.test(element.value)) {
          newErrors.email = "Invalid email address.";
        } else {
          const isEmailExists = await checkEmail(element.value);
          console.log(isEmailExists);
          if (isEmailExists) {
            newErrors.email = "Email ID already Exists";
          } else {
            newErrors.email = '';
          }
        }
        break;

      case 'phone':
        if (!phoneRegex.test(element.value) && element.value) {
          newErrors.phone = "Invalid Australian phone number.";
        }
        break;
      case 'abn':
        const abnRegex = /^\d{11}$/;
        if (!abnRegex.test(element.value) && element.value) {
          newErrors.abn = "Invalid ABN. Must be 11 digits.";
        }
        break;


    }
    setErrors(newErrors);
    // Return true if no errors
    return Object.keys(newErrors).length === 0;
  }

  const validateCustomerForm = () => {
    const newErrors: any = {};

    // Name Validation
    if (!customerForm.name.trim()) {
      newErrors.name = "Name is required.";
    }
    if (!customerForm.address.trim()) {
      newErrors.address = "Address is required.";
    }
    if (!customerForm.tradingName.trim()) {
      newErrors.tradingName = "Trading Name is required.";
    }
    if (!customerForm.customerID) {
      newErrors.customerID = "CustomerID is required.";
    }
    const abnRegex = /^\d{11}$/;
    if (!abnRegex.test(customerForm.abn)) {
      newErrors.abn = "Invalid ABN. Must be 11 digits.";
    }
    // Email Validation
    // Basic email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!customerForm.email) {
      newErrors.email = "Email is required.";
    } else if (!emailRegex.test(customerForm.email)) {
      newErrors.email = "Invalid email address.";
    }
    // Phone Number Validation
    const phoneRegex = /^(\+?\(61\)|\(\+?61\)|\+?61|\(0[1-9]\)|0[1-9])?( ?-?[0-9]){7,9}$/; // Validates a 10-digit phone number
    if (!phoneRegex.test(customerForm.phone) && customerForm.phone) {
      newErrors.phone = "Invalid Australian phone number.";
    }


    setErrors(newErrors);
    // Return true if no errors
    return Object.keys(newErrors).length === 0;
  };

  return (
    <Stack>
      <Header onSearch={(e) => console.log(e.target.value)} links={[{ title: "Home", link: "/dashboard" }, { title: "Data Management", link: "/management" }, { title: "Customer Management", link: "" }]} />
      <SideBar />
      <Box className="mainForm dashboardmain">
        <Paper sx={{ padding: '50px' }}>
          <h1>{currentId!== 'Add' ? 'Update Customer' : 'Add New Customer'}</h1>
          <Grid container spacing={2} style={{ width: '100%', textAlign: "left" }}>


            <Grid size={12}>
              <TextFieldComponent
                name="name"
                isRequired={true}
                keyName="nameForm"
                placeholder="Enter customer name"
                value={customerForm.name}
                onChange={handeCustomerFormChange}
                errorStr={errors.name}
                label="Customer Name"
              />
            </Grid>
            <Grid size={6}>
              <TextFieldComponent
                name="tradingName"
                isRequired={true}
                keyName="tradingName"
                placeholder="Enter trading name"
                value={customerForm.tradingName}
                onChange={handeCustomerFormChange}
                errorStr={errors.tradingName}
                label="Trading Name"
              />
            </Grid>
            <Grid size={6}>
              <TextFieldComponent
                name="abn"
                isRequired={true}
                keyName="abnForm"
                placeholder="Enter 11-digit ABN number"
                value={customerForm.abn}
                onChange={handeCustomerFormChange}
                errorStr={errors.abn}
                label="ABN Number"
              />
            </Grid>


            <Grid size={12}>
              <TextFieldComponent
                name="address"
                isRequired={true}
                keyName="addressForm"
                placeholder="Enter address"
                value={customerForm.address}
                onChange={handeCustomerFormChange}
                errorStr={errors.address}
                label="Customer Address"
              />
            </Grid>
            <Grid size={6}>
              <TextFieldComponent
                name="email"
                isRequired={true}
                keyName="emailForm"
                placeholder="Enter email address"
                value={customerForm.email}
                onChange={handeCustomerFormChange}
                errorStr={errors.email}
                label="Email Address"
              />
            </Grid>
            <Grid size={6}>
              <TextFieldComponent
                name="phone"
                isRequired={true}
                keyName="phoneForm"
                placeholder="Enter phone number"
                value={customerForm.phone}
                onChange={handeCustomerFormChange}
                errorStr={errors.phone}
                label="Phone Number"
              />
            </Grid>

            <Grid size={6}>
              <TextFieldComponent
                name="customerID"
                isRequired={true}
                keyName="customerIDForm"
                placeholder="Enter customer ID"
                value={customerForm.customerID}
                onChange={handeCustomerFormChange}
                errorStr={errors.customerID}
                label="Customer ID"
              />
            </Grid>

            <Grid size={6}>
              <TextFieldComponent
                name="creditLimit"
                isRequired={false}
                keyName="creditLimitForm"
                placeholder="Enter credit limit"
                value={customerForm.creditLimit}
                onChange={handeCustomerFormChange}
                errorStr={''}
                label="Credit Limit"
              />
            </Grid>
            <Grid size={12}>
              <TextFieldAreaComponent
                name='notes'
                label="Notes"
                placeholder="Enter notes"
                errorStr=""
                isRequired={false}
                keyName="txtareanotes"
                onChange={handeCustomerFormChange}
                value={customerForm.notes}
                minRows={7}
              />
            </Grid>

            <Grid size={12}
              sx={{ textAlign: 'right' }}>
              
              <ResetButtonComponent
                onClear={(isClear: boolean) => {
                  clearForm();
                }}
                keyName="clrCustomer"
              />
              <PrimaryButtonComponent
                keyName="btnMainCustomer"
                errors={errors}
                loading={loading}
                onSave={handleSave}
                currentRecord={currentRecord}
              />
              {errorMessage && (
                <p className="text-danger">{errorMessage}</p>
              )}
            </Grid>





          </Grid>
        </Paper>

      </Box>
    </Stack>
  );
};
export default CustomerForm;
