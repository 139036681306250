import React, { useEffect } from 'react';
import './Index.css';

import SideBar from '../../components/Sidebar';
import Header from '../../components/Header';
import { Box, Stack } from '@mui/material';





const Dashboard: React.FC = () => {

  useEffect(() => {
    document.getElementsByTagName('body')[0].classList.remove('loginbody');

  }, []);
  const handleSerchInput = async (event: any) => {
    //todo search action needs to be implemented
  }
  return (
    <Stack>
      <Header onSearch={handleSerchInput} links={[{title:"Home",link:""}]} />
      <SideBar />
      <Box  className="main dashboardmain">
       
        <section className="section dashboard">
          <div className="row">
            <div className="col-lg-8">
            </div>
          </div>
        </section>
      </Box>
    </Stack>
  );
};

export default Dashboard;
