import { Button } from "@mui/material";

interface ButtonComponentProps {
  onClick: () => void;
  label: string;
  errors: any;
  currentRecord?: any;
  keyName: string;
}
const ButtonComponent: React.FC<ButtonComponentProps> = ({
  onClick,
  label,
  keyName,
}) => {
  return (
    <Button
      sx={{ color: "#fff" }}
      type="button"
      id={keyName}
      key={keyName}
      variant="contained"
      color="primary"
      onClick={() => {
        onClick();
      }}
    >
      {label}
    </Button>
  );
};
export default ButtonComponent;
