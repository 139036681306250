"use client";
import React, { useEffect, useRef, useState } from "react";
import "./Index.css";
import SideBar from "../../../components/Sidebar";
import Header from "../../../components/Header";
import { Box, Paper, Stack, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import Constants from "../../../utils/contants";
import HttpService from "../../../services/HttpService";
import { useStore } from "../../../utils/store";
import { useNavigate, useParams } from "react-router-dom";
import TextFieldComponent from "../../../components/form/TextFieldComponent";
import DeleteIcon from '@mui/icons-material/Delete';
import ResetButtonComponent from "../../../components/form/ResetButtonComponent";
import TextFieldAreaComponent from "../../../components/form/TextFieldAreaComponent";
import ButtonComponent from "../../../components/form/ButtonComponent";
import SimpleAutoCompleteTextFieldComponent from "../../../components/form/SimpleAutoCompleteTextFieldComponent";
import SimpleDialogComponent from "../../../components/SimpleDialogComponent";
import IdLabelData from "../../../Models/IdLabelData";
import SelectFieldComponent from "../../../components/form/SelectFieldComponent";
import PrimaryButtonComponent from "../../../components/form/PrimaryButtonComponent";
import AddButtonComponent from "../../../components/form/AddButtonComponent";


const BillableForm: React.FC = () => {

  const { getAdmin } = useStore();
  const { currentId } = useParams<{ currentId: any }>();
  const navigate = useNavigate();
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [currentResource, setCurrentResource] = useState<any>(null);
  const [errorMesssage, setErrorMessage] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [unitType, setUnitType] = useState<IdLabelData[]>([]);
  const [resourceList, setResourceList] = useState<any[]>([]);
  const [billableFormItems, setBillableFormItems] = useState<any[]>([]);
  const [billableRemovedItems, setBillableRemovedItems] = useState<any[]>([]);

  const [currentRecord, setCurrentRecord] = useState<any>(null);
  const [errors, setErrors] = useState({
    name: "",
    price: "",
    unitTypeId: "",
  });

  const validateField = async (element: any) => {
    const elementName = element.name;
    const newErrors: any = {};
    switch (elementName) {
      case 'name':
        if (!element.value.trim()) {
          newErrors.name = "Name is required.";
        }
        break;
      case 'price':
        if (!element.value.trim()) {
          newErrors.price = "Price is required.";
        }
        break;
      case 'unitTypeId':
        if (!element.value) {
          newErrors.unitTypeId = "Unit of measure is required field.";
        }
        break;

    }
    setErrors(newErrors);
    // Return true if no errors
    return Object.keys(newErrors).length === 0;
  }
  const validateForm = () => {
    const newErrors: any = {};

    // Name Validation
    if (!billableForm.name.trim()) {
      newErrors.name = "Name is required.";
    }
    if (!billableForm.price) {
      newErrors.price = "Price is required.";
    }
    if (!billableForm.unitTypeId) {
      newErrors.unitTypeId = "Unit of measure is required field.";
    }
    setErrors(newErrors);
    // Return true if no errors
    return Object.keys(newErrors).length === 0;
  };
  interface billableFormFields {
    name: string,

    price: string,
    id?: string | null,

    exclusion: string,
    notes: string;
    items?: any[]
    unitTypeId: any,
  }
  const [billableForm, setBillableForm] = useState<billableFormFields>({
    name: "",
    price: "",
    id: null,
    exclusion: "",
    notes: '',
    unitTypeId: ""


  });

  useEffect(() => {
    if (currentId) {
      if (currentId !== 'Add') {
        fetchSingleRecord(currentId);
      }
    } else {
      setCurrentRecord(null);
    }
    if (resourceList.length === 0) {
      getResourceList();
    }
    if (unitType.length === 0)
      fetchUnitTypes();
  }, [currentId]);


  const Create = async (dataObj: any) => {
    try {
      const response = await HttpService.post(
        Constants.apiURL + Constants.billableURL,
        dataObj,
        { headers: { Authorization: "Bearer " + getAdmin() } }
      );
      if (response.success) {
        clearForm(false);
      } else {
        setErrorMessage("Failed to add record. Please try again.");
      }

      console.log("GET Response:", response);
    } catch (error) {
      setErrorMessage("Failed to add record. Please try again.");
      console.error("Error fetching data:", error);
    }
  };
  const Update = async (dataObj: any, id: number) => {
    try {
      dataObj['id'] = id;
      const response = await HttpService.put(
        Constants.apiURL + Constants.billableURL,
        dataObj,
        { headers: { Authorization: "Bearer " + getAdmin() } }
      );
      if (response.success) {
        clearForm(false);
      } else {
        setErrorMessage("Transaction failed. Please try again.");
      }
      console.log("GET Response:", response);
    } catch (error) {
      setErrorMessage("Transaction failed. Please try again.");
      console.error("Error fetching data:", error);
    }
  };



  const fetchSingleRecord = async (id: any) => {
    try {
      const response = await HttpService.get(
        Constants.apiURL + Constants.billableURL + '/' + id,
        { headers: { Authorization: "Bearer " + getAdmin() } },
        null
      );
      if (response.success) {

        handleRowClick(response.data);
      } else {
        setErrorMessage("Failed to find record. Please try again.");
      }

      console.log("GET Response:", response);
    } catch (error) {
      setErrorMessage("Failed to find record. Please try again.");
      console.error("Error fetching data:", error);
    }
  };
  const dialogContent = () => {
    return <div className="row mb-3 mt-2">
      <div style={{ width: '400px' }}>
        <SimpleAutoCompleteTextFieldComponent
          name="resourceLstselect"
          value={currentResource}
          isRequired={false}
          errorStr={''}
          keyName="resourceLstselect"
          label="Billable Items"
          onChange={handleBillableChange}
          items={resourceList}
        />

      </div>
      <div className="col">
        <ButtonComponent
          keyName={"addBtn"}
          errors={errors}
          onClick={AddResourceItem}
          label={'Add'}
        />
      </div>
    </div>;
  }
  const clearForm = (isForm: boolean) => {

    setBillableForm({
      name: "",
      price: "",
      id: null,
      exclusion: '',
      notes: '',
      unitTypeId: ''

    });
    setCurrentRecord(null);
    setErrorMessage('');
    setBillableRemovedItems([]);
    navigate('/management/billables')
  };
  const handleRowClick = async (data: any) => {
    const record = data.billable;
    setCurrentRecord(record);

    setBillableForm(
      {
        name: record.name,
        price: record.price,
        id: record.id,
        exclusion: record.exclusion,
        notes: record.notes,
        unitTypeId: record.unitTypeId

      }
    );
    setBillableFormItems(data.details);

  };

  const getResourceList = async () => {
    try {
      const response = await HttpService.get(
        Constants.apiURL + Constants.resourcesURL,
        { headers: { Authorization: "Bearer " + getAdmin() } },
        {},
      );
      if (response.success) {
        const resourceRecords = response.data.paginateRecords;
        if (resourceRecords.length > 0) {
          setResourceList(resourceRecords);
          setCurrentResource(resourceRecords[0])
        }


      } else {
        setErrorMessage("No records found");
      }
      console.log("GET Response:", response);
    } catch (error) {
      setErrorMessage("Error fetching data");
      console.error("Error fetching data:", error);
    }
  };
  const handleSerchInput = async (event: any) => {
    const value: any = event.target.value;

  };

  const handleSave = async () => {

    if (validateForm()) {

      setLoading(true);
      const req: any = {
        name: billableForm.name,
        price: billableForm.price,
        notes: billableForm.notes,
        unitTypeId: billableForm.unitTypeId,
        exclusion: billableForm.exclusion,
        items: billableFormItems
      };

      if (currentRecord) {
        req['billableRemovedItems'] = billableRemovedItems;
        await Update(req, currentRecord.id);
      } else {
        await Create(req);
      }

      setLoading(false);
    }

  };
  const calculateTotalCost = (
    newItems: any) => {
    let totalCost = newItems.reduce((acc: number, bItems: any) => {
      return acc + parseInt(bItems.quantity) * parseFloat(bItems.cost);
    }, 0);
    setBillableForm((prev: any) => {
      return { ...prev, ['price']: totalCost };
    });
  }
  const AddResourceItem = (
  ) => {
    const index: any = resourceList.findIndex(item => item === currentResource);
    let newItems: any;
    if (index !== -1) {
      setBillableFormItems((prevItems) => {
        newItems = [...prevItems];
        if (!newItems.includes(resourceList[index])) {
          newItems.push(resourceList[index]);
        }

        return newItems;
      });
      calculateTotalCost(newItems);
    }
    setDialogOpen(false);
  }
  const RemoveResourceItem = (index: number
  ) => {

    setBillableFormItems((prevItems) => {
      const newItems = [...prevItems];
      newItems.splice(index, 1);
      calculateTotalCost(newItems);
      return newItems;
    });
    setBillableRemovedItems((prevItems) => {
      const newItems = [...prevItems];
      if (!newItems.includes(billableFormItems[index])) {
        newItems.push(billableFormItems[index]);
      }

      return newItems;
    });


  }
  const handleItemChange = (value: any, index: number, type: string) => {
    setBillableFormItems((prevItems) => {
      // Create a copy of the current state
      const updatedItems = [...prevItems];
      // Update the specific item's property
      updatedItems[index] = { ...updatedItems[index], [type]: value };
      calculateTotalCost(updatedItems);
      return updatedItems;
    });
  };

  const handleDialog = (open: any) => {
    setDialogOpen(open);
  }
  const handleBillableChange = (
    e: any, dataValue: any
  ) => {
    setCurrentResource(dataValue);

  };
  const hanldebillableFormChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLFormElement>
  ) => {
    const { name, value, type } = e.target;
    setBillableForm((prev: any) => {
      const target: any = e.target;
      validateField(target);
      return { ...prev, [name]: value };
    });
  };

  const fetchUnitTypes = async () => {
    try {
      const response = await HttpService.get(
        Constants.apiURL + Constants.refBundleURL + '/fetchUnitTypes',
        { headers: { Authorization: "Bearer " + getAdmin() } },
        { search: '', limit: -1, offset: 0 },
      );
      if (response.success) {
        setUnitType([
          { id: "", label: "Select Unit Type" },
          ...response.data,
        ]);

      }
      console.log("GET Response:", response);
    } catch (error) {
      setErrorMessage(Constants.COMMON_SERVER_ERROR);
      console.error("Error fetching data:", error);
    }
  }



  return (
    <Stack>
      <Header onSearch={handleSerchInput} links={[{ title: "Home", link: "/dashboard" }, { title: "Data Management", link: "/management" }, { title: "Billable Item Management", link: "" }]} />
      <SideBar />
      <Box className="mainForm dashboardmain">


        <Paper sx={{ padding: '50px' }}>
          <h1>{currentId !== 'Add' ? 'Update Billable' : 'Add New Billable'}</h1>


          <Grid container spacing={2} style={{ width: '100%', textAlign: "left" }}>
            <Grid size={6}>
              <TextFieldComponent
                label="Billable Name"
                keyName="billableNameForm"
                name="name"
                value={billableForm.name}
                onChange={hanldebillableFormChange}
                errorStr={errors.name}
                isRequired={true}
              />
            </Grid>
            <Grid size={6}>
              <SelectFieldComponent
                errorStr={errors.unitTypeId}
                isRequired={true}
                items={unitType}
                keyName="unitTypeId"
                label="Unit of Measure"
                name="unitTypeId"
                value={billableForm.unitTypeId}
                onChange={(e: any) => {
                  hanldebillableFormChange(e);
                }}
              />
            </Grid>
            <Grid size={12} sx={{textAlign: 'right'}}>
                 <Typography variant="h6"> Total Price: ${parseFloat(billableForm.price ? billableForm.price : '0').toFixed(2)}</Typography>

            </Grid>




            <Grid size={12} className="mb-3 mt-3">
             
              <label key={'lbl_rsrcList'} className="form-label">{'Resource Items'}</label>
              <TableContainer sx={{mt:2}} component={Paper}>
                <Table >
                  <TableHead style={{background:' rgba(0, 244, 255, 0.06)'}}>
                    <TableRow >
                      <TableCell className="boldfont">Resource Items</TableCell>
                      <TableCell className="boldfont" style={{ width: '70px' }}>Quanity</TableCell>
                      <TableCell className="boldfont" style={{ width: '150px' }}>Cost</TableCell>
                      <TableCell className="boldfont" style={{ width: '100px' }}>UAM</TableCell>
                      <TableCell className="boldfont" style={{ width: '120px' }} colSpan={2}>Total</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {billableFormItems.map((item: any, index: any) => {
                      return <TableRow >
                        <TableCell>{item.name}</TableCell>
                        <TableCell >
                          <TextFieldComponent
                            label=""
                            keyName={'qtyFormBillable_' + index}
                            name={'qtyFormBillable_' + index}
                            value={item.quantity}
                            onChange={(event) => {
                              handleItemChange(event.target.value, index, 'quantity')
                            }}
                            errorStr={''}

                            isRequired={false}
                            style={{ width: '60px' }}
                          />
                        </TableCell>
                        <TableCell>
                          <TextFieldComponent
                            label=""
                            keyName={'costFormBillable_' + index}
                            name={'costFormBillable_' + index}
                            value={item.cost}
                            onChange={(event: any) => {
                              handleItemChange(event.target.value, index, 'cost')
                            }}
                            errorStr={''}

                            isRequired={false}
                            style={{ width: '70px' }}
                          />
                        </TableCell>
                        <TableCell>{item.unit}</TableCell>
                        <TableCell>{parseFloat(item.cost) * parseInt(item.quantity)}</TableCell>
                        <TableCell style={{ width: '50px' }}>

                          <IconButton aria-label="delete" onClick={() => {
                            RemoveResourceItem(index);
                          }}>
                            <DeleteIcon />
                          </IconButton>


                        </TableCell>

                      </TableRow>
                    })}
                    <TableRow onClick={() => {
                     
                    }} sx={{ '& > *': { borderRight: '1px solid #ddd', height: '45px', borderLeft: '1px solid #ddd', cursor: 'pointer' } }}>
                      <TableCell colSpan={6}> 
                        <AddButtonComponent  keyName="billableresourcebtn" label="Add Resource Item" onClick={()=>{
                          handleDialog(true);
                        }} />
                      </TableCell>

                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>

            </Grid>
            <Grid size={12}>
              <TextFieldAreaComponent
                label="Internal Notes"
                isRequired={false}
                name="notes"
                errorStr={''}
                minRows={7}
                onChange={hanldebillableFormChange}
                placeholder="Internal notes for Billable descriptions  goes here"
                keyName="notesForm"
                value={billableForm.notes}
              />
            </Grid>
            <Grid size={12}>
              <TextFieldAreaComponent
                label="Billable Descriptions Exclusions"
                isRequired={false}
                name="exclusion"
                errorStr={''}
                minRows={7}
                onChange={hanldebillableFormChange}
                placeholder="Notes for Billable description exclusions goes here"
                keyName="notesFormExcl"
                value={billableForm.exclusion}
              />
            </Grid>



            <Grid
              size={12}
              style={{ textAlign: "right" }}
            >
              <ResetButtonComponent
                keyName="rstBtn"
                onClear={() => {
                  clearForm(false);
                }}
              />
              <PrimaryButtonComponent
                loading={loading}
                onSave={handleSave}
                errors={errors}
                keyName="termMainBtn"
                currentRecord={currentRecord}
              />
              

              {errorMesssage && (
                <p className="text-danger">{errorMesssage}</p>
              )}
            </Grid>


            {<SimpleDialogComponent title={'Resources'} dialogContent={dialogContent()} open={dialogOpen} handleClose={() => {
              handleDialog(false)
            }} />}
          </Grid>
        </Paper>
      </Box>
    </Stack>
  );
};


export default BillableForm;
