import * as React from 'react';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
interface SimpleDialogComponentProps {
  handleClose: (
  ) => void;
  open: boolean;
  dialogContent: React.ReactNode;
  title: string;
  fullScreen?: boolean
  children?: React.ReactNode;
  width?:string;
}
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
    minWidth: '350px',
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
    minWidth: '350px',
  },

}));

const SimpleDialogComponent: React.FC<SimpleDialogComponentProps> = ({ handleClose,open,dialogContent,title,fullScreen,width}) => {
  

  return (
    <React.Fragment>
      <BootstrapDialog  sx={{
        "& .MuiDialog-paper": {
           maxWidth: width ?  width :'1200px'
        },
      }}
    
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullScreen={fullScreen}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title" style={{fontWeight: 'bold'}}>
        {title}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={(theme) => ({
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
          })}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent >
           {dialogContent}
        </DialogContent>
      
      </BootstrapDialog>
    </React.Fragment>
  );
}
export default SimpleDialogComponent;