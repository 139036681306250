import { TextareaAutosize, Box, TextField } from "@mui/material";

interface TextFieldAreaComponentProps {
  onChange: (event: any) => void;
  label: string;
  keyName: string;
  name: string;
  isRequired: boolean;
  value: string;
  placeholder?: string;
  errorStr: string;
  minRows: number;
}
const TextFieldAreaComponent: React.FC<TextFieldAreaComponentProps> = ({
  onChange,
  label,
  isRequired,
  value,
  keyName,
  name,
  placeholder,
  errorStr,
  minRows,
}) => {
  return (
    <Box sx={{ mb: 3 }} key={"mainele_" + keyName}>
      <Box sx={{ mb: 1 }} key={"lbl_" + keyName} className="form-label">
        {label}
        {isRequired && <small style={{ color: "red" }}> *</small>}
      </Box>
      <TextField
        className="form-control form-control-loum"
        placeholder={placeholder}
        name={name}
        value={value}
        multiline
        onChange={onChange}
        rows={minRows}
        key={keyName}
        fullWidth
      />
      {errorStr && <span className="bad-field">{errorStr}</span>}
    </Box>
  );
};
export default TextFieldAreaComponent;
