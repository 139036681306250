import * as React from 'react';
import Grid from "@mui/material/Grid2";
import { List, ListItem, ListItemText } from '@mui/material';


interface StandardTermsStepProps {
  terms:any[];
  currentStandardTermsList: any[]
  
}
const StandardTermsStep: React.FC<StandardTermsStepProps> = ({ terms,currentStandardTermsList }) => {
  const termsData = currentStandardTermsList.length > 0  ? currentStandardTermsList : terms;
  return (
 
  <Grid container spacing={2} style={{ marginTop: "30px" }}>
    {/* Section Header */}
   

    {/* Browse File  Field */}
    <Grid size={12}>
      <List>
      {termsData.map((item,index)=>{
        return <ListItem sx={{
          display: "list-item",
         listStyle:'disc',
        }} key={'terms_list_'+index}><ListItemText>{item.termText}</ListItemText></ListItem>
      })}
      </List>
    </Grid>





  </Grid>
  
  );
}
export default StandardTermsStep;