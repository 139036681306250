import React, { useEffect } from 'react';
import './Index.css';

import SideBar from '../../components/Sidebar';
import Header from '../../components/Header';
import { Link } from 'react-router-dom';
import { Box, Grid2 } from '@mui/material';
import {  CheckBoxOutlined, DocumentScannerOutlined, GroupAddOutlined } from '@mui/icons-material';
import HomeRepairServiceIcon from '@mui/icons-material/HomeRepairService';
import ReceiptIcon from '@mui/icons-material/Receipt';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import PageTitle from '../../components/PageTitle';


const DataManagement: React.FC = () => {
  const gridSize:number  = 3;
  useEffect(() => {
    document.getElementsByTagName('body')[0].classList.remove('loginbody');

  }, []);
  const handleSerchInput = async (event: any) => {
    console.log(event);
  }
  return (
    <div>
      <Header onSearch={handleSerchInput} links={[ {title:"Data Management",link:""}]} />
      <SideBar />
      <main id="main" className="main dashboardmain">
      

       

    <Box sx={{ flexGrow: 1 }}>
          <Grid2 container spacing={1}>
            <Grid2 size={gridSize} className="iconbox">
            <Link to='/management/users'><GroupAddOutlined /> User Management </Link>
            </Grid2>
            <Grid2 size={gridSize} className="iconbox">
            <Link to='/management/contractors'><GroupAddOutlined /> Contractor Management </Link>
            </Grid2>
            <Grid2 size={gridSize} className="iconbox">
            <Link to='/management/customers'><GroupAddOutlined /> Customer Management </Link>
            </Grid2>
            <Grid2 size={gridSize} className="iconbox">
            <Link to='/management/terms'><CheckBoxOutlined /> Standard Terms Management </Link>
            </Grid2>
            <Grid2 size={gridSize} className="iconbox">
            <Link to='/management/adhoc-terms'><CheckBoxOutlined /> Adhoc Terms Management </Link>
            </Grid2>
            <Grid2 size={gridSize} className="iconbox">
            <Link to='/management/tenders'><DocumentScannerOutlined /> Tenders Management </Link>
            </Grid2>
            <Grid2 size={gridSize} className="iconbox">
            <Link to='/management/resources'><HomeRepairServiceIcon /> Resources Management </Link>
            </Grid2>
            <Grid2 size={gridSize} className="iconbox">
            <Link to='/management/billables'><ReceiptIcon /> Billables Management </Link>
            </Grid2>
            <Grid2 size={gridSize} className="iconbox">
            <Link to='/management/projects'><AccountTreeIcon /> Project Management </Link>
            </Grid2>
          </Grid2>
        </Box>

      </main>
    </div>
  );
};



export default DataManagement;
