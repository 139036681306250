"use client";
import React, { useEffect, useRef, useState } from "react";
import "./Index.css";
import SideBar from "../../../components/Sidebar";
import Header from "../../../components/Header";
import {
  Box,
  IconButton,
  Paper,
  Stack,
  Typography
} from "@mui/material";
import AddButtonComponent from "../../../components/form/AddButtonComponent";
import Constants from "../../../utils/contants";
import HttpService from "../../../services/HttpService";
import { useStore } from "../../../utils/store";

import CustomPaginationActionsTable from "../../../components/TablePagination";
import StatusRequestData from "../../../Models/StatusRequest";
import { useNavigate, useParams } from "react-router-dom";
import { Add, AttachFile } from "@mui/icons-material";

import SimpleDialogComponent from "../../../components/SimpleDialogComponent";
import AlertMessageComponent from "../../../components/AlertMessageComponent";
import SwitchComponent from "../../../components/SwitchComponent";
const Resources: React.FC = () => {
  const isInitialLoad = useRef(true);
  const { getAdmin } = useStore();
  const { currentId } = useParams<{ currentId: any }>();
  const navigate = useNavigate();
  const columns: any[] = [
    { field: "name", headerName: "Resource Name" },
    { field: "unit", headerName: "Unit of Measure" },
    {
      field: "price", headerName: "Price"
    },
    {
      field: "resourceType", headerName: "Resource Type"
    },
    { field: "contractorName", headerName: "Contractor" },
    { field: "items", headerName: "Linked Billable Items" },
    {
      field: "isQuote", headerName: "Request for Quote",
      valueGetter: (value: any, row: any) => {
        return value === '1' ? 'Yes' : 'No';
      }

    },
    {
      field: "fileURL", headerName: "Attachments", renderCell: (value: any, row: any) => (
        <IconButton aria-label="Attachments" color="primary">
          <AttachFile />
        </IconButton>

      )
    },
    { field: "notes", headerName: "Notes" },
    { field: "creator", headerName: "User" },
    {
      field: "updated",
      headerName: "Date Modified",
      valueGetter: (value: any, row: any) => {
        return new Date(value).toLocaleDateString("default", {
          month: "short",
          day: "numeric",
          year: "numeric",
        });
      },
    },
    {
      field: "status",
      headerName: "Active",
      renderCell: (value: any, row: any) => (
        <SwitchComponent
          checked={value === "1" ? true : false}
          size="small"
          color={"success"}
          name="rsrtable"
          value={row.status}
          handleInputChange={() => { }}
          inputProps={{ "aria-label": "controlled" }}
        />
      ),
    }
  ];

  const [searchQuery, setSearchQuery] = useState<any>("");
  const [primaryError, setPrimaryError] = useState<boolean>(false);
  const [alertOpen, setAlertOpen] = useState<boolean>(false);
  const [attachmentDialogOpen, setAttachmentDialogOpen] = useState<boolean>(false);
  const [dialogContent, setDialogContent] = useState<any>();


  const [paginate, setPaginate] = useState<any>({ limit: Constants.DEFAULT_PAGE_SIZE, offset: 0 });
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [errorMesssage, setErrorMessage] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState<any>([]);


  const handleAttachmentDialog = (state: boolean) => {
    setAttachmentDialogOpen(state);
  }
  const handleAlert = (state: boolean) => {
    setAlertOpen(false);
    setErrorMessage("");
  }






  useEffect(() => {
    if ((searchQuery || paginate) || isInitialLoad.current) {
      fetchData();
    }
    isInitialLoad.current = false;
  }, [searchQuery, paginate]);
  const StatusUpdate = async (dataObj: StatusRequestData) => {
    try {
      const response = await HttpService.patch(
        Constants.apiURL + Constants.resourcesURL,
        dataObj,
        { headers: { Authorization: "Bearer " + getAdmin() } }
      );
      if (response.success) {
        fetchData();
      } else {
        setErrorMessage("Failed to Update Status. Please try again.");
        setAlertOpen(true);
      }

      console.log("GET Response:", response);
    } catch (error) {
      setErrorMessage("Failed to add resource. Please try again.");
      setAlertOpen(true);
      console.error("Error fetching data:", error);
    }
  };
  const getResourceRecord = async (id: any) => {
    return new Promise(async (resolve, reject) => {
      const response = await HttpService.get(
        Constants.apiURL + Constants.resourcesURL + '/' + id,
        { headers: { Authorization: "Bearer " + getAdmin() } },
        null
      );
      if (response.success) {
        resolve(response.resource);
      } else {
        resolve(null);
      }
    })
  }
  const getMediaRecord = async (id: any) => {
    return new Promise(async (resolve, reject) => {
      const response = await HttpService.get(
        Constants.apiURL + Constants.mediaURL + '/' + id,
        { headers: { Authorization: "Bearer " + getAdmin() } },
        null
      );
      if (response.success) {
        resolve(response.media);
      } else {
        resolve(null);
      }
    })
  }
  const handleDownloadFile = async (ID: any) => {
    const media: any = await getMediaRecord(ID);
    if (media) {
      window.open(media, '_blank');
    }
  }


  const fetchData = async () => {
    try {
      const response = await HttpService.get(
        Constants.apiURL + Constants.resourcesURL,
        { headers: { Authorization: "Bearer " + getAdmin() } },
        { search: searchQuery, limit: paginate.limit, offset: paginate.offset },
      );
      if (response.success) {
        setRows(response.data.paginateRecords);

        setTotalRecords(response.data.total);
      } else {
        setErrorMessage("Failed to add resource. Please try again.");
      }

      console.log("GET Response:", response);
    } catch (error) {
      setErrorMessage("Failed to add resource. Please try again.");
      console.error("Error fetching data:", error);
    }
  };
  const handleCustomTableClick = async (row: any) => {
    const resource = await getResourceRecord(row.id);

    const fileElements = renderDialogContent(resource);
    // console.log(`fileElements`);
    // console.log(fileElements);
    // console.log(`fileElements`);
    if (fileElements) {
      setDialogContent(fileElements);
      setAttachmentDialogOpen(true);
    } else {
      setAlertOpen(true);
      setErrorMessage("No attachment Found");
    }
  };
  const renderDialogContent = (resource: any) => {
    var renderElements: any = [];
    let mediaData: any;
    if (resource) {
      if (resource.isQuote === '1') {
        const details = resource.details;
        for (var i = 0; i < details.length; i++) {
          mediaData = details[i].media;
          if (mediaData.length > 0) {
            //console.log(mediaData);
            for (var j = 0; j < mediaData.length; j++) {
              console.log(mediaData[j]);
              let recordID: any = mediaData[j].id;

              renderElements.push(
                <div className="dialog-img-element" onClick={() => {
                  //console.log(recordID);
                  handleDownloadFile(recordID);
                }}>
                  <span>{mediaData[j]['name']}</span>
                </div>
              );

            }

          }
        }
      }
      if (resource.isQuote === '0') {
        mediaData = resource.media;
        for (var mi = 0; mi < mediaData.length; mi++) {
          let recordID: any = mediaData[mi].id;
          renderElements.push(
            <div className="dialog-img-element" onClick={() => {
              //console.log(recordID);
              handleDownloadFile(recordID);
            }}>
              <span>{mediaData[mi]['name']}</span>
            </div>
          );
        }
      }
      if (renderElements.length > 0) {
        return (<div className="dialog-files">{renderElements}</div>);
      } else {
        return null;
      }

    } else {
      return null;
    }

  }
  const handleStatus = (row: any) => {
    let status = '1';
    if (row.status === '1') {
      status = '0';
    }
    if (row.status === '0') {
      status = '1';
    }
    StatusUpdate({ id: row.id, status: status });
  };

  const handlePagination = (paginationData: any) => {
    console.log(paginationData);
    setPaginate(paginationData);
  };
  const handleSerchInput = async (event: any) => {
    const value: any = event.target.value;
    setSearchQuery(value);
  };
  return (
    <Stack>

      <Header onSearch={handleSerchInput} links={[{ title: "Home", link: "/dashboard" }, { title: "Data Management", link: "/management" }, { title: "Resource Management", link: "" }]} />
      <SideBar />
      <Box className="main dashboardmain">

        <section className="section dashboard">
          <div className="containergrid">
            <div className="mt-2">
              <Paper sx={{ width: "100%" }} className="mt-4">
                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: '10px', mb: 2 }}>
                  <Typography variant="h6"> Resources</Typography>
                  <AddButtonComponent onClick={() => {
                    navigate("/management/resources/Add")
                    //setDialogOpen(true);

                  }} keyName="quoteaddicon" label="Add New" />
                </Box>
                <CustomPaginationActionsTable
                  rows={rows}
                  columns={columns}
                  totalRecords={totalRecords}
                  handlePagination={handlePagination}
                  handleRowClick={(row) => {
                    navigate('/management/resources/' + row.id)
                    //console.log(data);
                  }}
                  paginationModel={{ page: paginate.offset, pageSize: paginate.limit }}
                  handleStatus={handleStatus}
                  customClick={async (row) => {
                    handleCustomTableClick(row);
                  }}
                />
              </Paper>
            </div>

          </div>

        </section>
        {<SimpleDialogComponent title={'Attachments'} dialogContent={dialogContent} open={attachmentDialogOpen} handleClose={() => {
          handleAttachmentDialog(false)
        }} />}
        <AlertMessageComponent type={'info'} open={alertOpen} handleClose={handleAlert} message={errorMesssage} />
      </Box>
    </Stack>
  );
};
export default Resources;
