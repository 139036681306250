import React from 'react';
import { HashRouter as Router, Routes, Route } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { StoreProvider } from './utils/store';

import AppRoutes from './AppRoutes';
const theme = createTheme({
  palette: {
    primary: {
      main: "rgb(1, 1, 1)", 
    
    
    },
    secondary: {
      main: "rgba(0, 244, 255, 0.08)",
      
    },
    text: {
      primary: "rgb(33, 33, 33)", // Main text color
      secondary: "rgb(117, 117, 117)", // Secondary text color
      disabled: "rgba(0, 0, 0, 0.38)", // Disabled text color
    },
  },
  typography: {
    fontFamily: "'Poppins', 'Arial', sans-serif", // Custom font family
    h1: {
      color: "#000B29", // Specific color for H1 headers
     
    },
    body1: {
      color: "#000B29", // Default color for body text
    },
    button: {
      color: "#000B29", // Button text color
      textTransform: "none", 
      "&:hover": {
        
        color: "#1A73E8",          // Hover text color
      },
      "&.Mui-selected": {
       // Selected background color
        color: "#000B29",          // Selected text color
      },// Disable uppercase text transformation
    },
    
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          color: "#000B29", // Default color
          textTransform: "none", // Disable uppercase transformation
          "&:hover": {
           // Hover background color
            color: "#1A73E8",          // Hover text color
          },
          "&.Mui-selected": {
          
            color: "#000B29",          // Selected text color
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
        
          textTransform: "none", // Disable uppercase transformation
          "&:hover": {
            // Hover background color
            color: "#1A73E8",          // Hover text color
          },
          "&.Mui-selected": {
           
            color: "#000B29",          // Selected text color
          },
        },
      },
    },
    MuiListItemText:{
      styleOverrides: {
        primary: {
          fontSize: "14px",
          color: "#000B29",
          fontWeight: "600",
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          color: "#000B29", // Change all icons color to red
        
        },
      },
    },
  },
});

const App: React.FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <StoreProvider>
          <AppRoutes />
        </StoreProvider>
      </Router>
    </ThemeProvider>

  );
};

export default App;
