import React, { useEffect, useRef } from "react";
import UTurnLeftIcon from "@mui/icons-material/UTurnLeft";
import { useStore } from "../../utils/store";
import {
  AlertColor,
  Box,
  Button,
  LinearProgress,
  Paper,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import Constants, { getCurrentDate } from "../../utils/contants";
import HttpService from "../../services/HttpService";
import SimpleAutoCompleteTextFieldComponent from "../../components/form/SimpleAutoCompleteTextFieldComponent";
import ButtonComponent from "../../components/form/ButtonComponent";
import SimpleDialogComponent from "../../components/SimpleDialogComponent";
import DetailStep from "./parts/detail-step-1";
import UploadStep from "./parts/upload-step-2";
import PricingStepSummary from "./parts/pricing-step-3";
import TermsStepTab from "./parts/terms-step-4";
import AlertMessageComponent from "../../components/AlertMessageComponent";
import ReviewFinalStep from "./parts/review-final-step-5";
import ConfirmDialog from "../../components/ConfirmDialog";
import IdLabelData from "../../Models/IdLabelData";
import WarningDialog from "../../components/WarningDialog";
import { useNavigate, useParams } from "react-router-dom";
import InfoDialog from "../../components/InfoDialog";

interface QuoteDialogComponentComponentProps {
  handleClose?: () => void;
  open?: boolean;
  quoteId?: any;
}

const QuoteDialogComponent: React.FC<
  QuoteDialogComponentComponentProps
> = () => {
  const steps = ["Details", "Documents", "Pricing", "T & C", "Review"];
  const isInitialLoad = useRef(true);
  const navigate = useNavigate();
  const { quoteId } = useParams<{ quoteId: any }>();
  const { getAdmin } = useStore();
  const [activeStep, setActiveStep] = React.useState(0);
  const [isLoading, setIsLoading] = React.useState(true);
  const [expandRowState, setExpandRowState] = React.useState<any[]>([]);
  const [quoteStatusList, setQuoteStatusList] = React.useState<IdLabelData[]>(
    []
  );
  const [currentRecord, setCurrentRecord] = React.useState<any>(null);
  const [inductionList, setInductionList] = React.useState<any[]>([]);

  const [customerList, setCustomerList] = React.useState<any[]>([]);
  const [customerListOptions, setCustomerListOptions] = React.useState<any[]>(
    []
  );
  const [currentCustomer, setCurrentCustomer] = React.useState<any>(null);
  const [currentProject, setCurrentProject] = React.useState<any>(null);
  const [projectTemplateList, setProjectTemplateList] = React.useState<any[]>(
    []
  );
  const [errorMessage, setErrorMessage] = React.useState<any>(null);
  const [billableList, setBillableList] = React.useState<any[]>([]);
  const [currentBillable, setCurrentBillable] = React.useState<any>(null);
  const [fileQuoteMedia, setFileQuoteMedia] = React.useState<any>([]);
  const [fileQuoteMediaCurrent, setFileQuoteMediaCurrent] = React.useState<any>(
    []
  );
  const [deletedQuoteFiles, setDeletedQuoteFiles] = React.useState<any>([]);

  //const [currentProjectRecord, setCurrentProjectRecord] = React.useState<any>(null);

  // const [billableRemovedItems, setBillableRemovedItems] = React.useState<any[]>([]);
  // const [billableDetailsRemovedItems, setBillableDetailsRemovedItems] = React.useState<any[]>([]);
  const [billableDialogOpen, setBillableDialogOpen] =
    React.useState<boolean>(false);
  const [companyDetails, setCompanyDetails] = React.useState<any>(null);
  const [alertOpen, setAlertOpen] = React.useState<boolean>(false);
  const [adhocTermsList, setAdhocTermsList] = React.useState<any[]>([]);
  const [standardTermsList, setStandardTermsList] = React.useState<any[]>([]);
  const [currentStandardTermsList, setCurrentStandardTermsList] = React.useState<any[]>([]);
  const [billableFormItems, setBillableFormItems] = React.useState<any[]>([]);
  const [selectedAdhocTerms, setSelectedAdhocTerms] = React.useState<any[]>([]);
  const [selectedExclusions, setSelectedExclusions] = React.useState<any[]>([]);
  const [selectedRejectReasons, setSelectedRejectReasons] = React.useState<any[]>([]);
  const [selectedQuoteInclusion, setSelectedQuoteInclusion] =
    React.useState<any>("");
  const [selectedQuoteExclusion, setSelectedQuoteExclusion] =
    React.useState<any>("");
  const [overheads, setOverheads] = React.useState<number>(0);
  const [forcasts, setForcasts] = React.useState<number>(0);
  const [projectPercentage, setProjectPercentage] = React.useState<number>(10);
  const GST: number = 10;
  const [errorType, setErrorType] = React.useState<AlertColor>("info");
  const [confirmDialogOpen, setConfirmDialogOpen] =
    React.useState<boolean>(false);
  const [warningDialogOpen, setWarningDialogOpen] =
    React.useState<boolean>(false);
  const [infoDialogOpen, setInfoDialogOpen] = React.useState<boolean>(false);
  const [inProgress, setInProgress] = React.useState<boolean>(false);
  const [warningButtonTitle, setWarningButtonTitle] =
    React.useState<string>("Save");
  const [warningMessage, setWarningMessage] = React.useState<string>(
    "Are you sure you want to Save as a Draft?"
  );
  const infoSuccessMessages = ['Success',
    'Your changes have been saved successfully.',
    'Your request has been sent for approval.',
    'Your request has been approved.',
    'Your request has been sent to the client.',
    'The quote has been rejected and sent back to the estimator for updates, along with the selected reason.',
    'The quote has been accepted and is now converted into a project.',
    'The quote has been rejected successfully with the provided reason.',
    "Quote revision created"

];
  const [warningMessageTitle, setWarningMessageTitle] =
    React.useState<string>("Save Draft");
  const [infoMessageTitle, setInfoMessageTitle] =
    React.useState<string>("Success");
  const [projectForm, setProjectForm] = React.useState({
    name: "",
    notes: "",
    cost: "",
    id: null,
  });
  const maxSteps = steps.length;

  const [detailForm, setDetailForm] = React.useState<any>({
    quoteStatusId: "1",

    customerId: "",
    customerEmail: "",
    mainContact: "",
    contactNumber: "",
    id: null,
    projectName: "",
    projectWorkCategory: "",
    projectSiteAddress: "",
    estimateStartDate: getCurrentDate(),
    submissionDueDate: getCurrentDate(),
    validToDate: getCurrentDate(),
    quoteInductionIds: "",
    projectTemplateId: "",
    internalNotes: "",
    updateVar: null,
  });

  const fetchStatusList = async () => {
    try {
      const statusList: any = await fetchBundle("fetchQuoteStatuses");
      if (statusList) {
        setQuoteStatusList([{ id: "", name: "Select Status" }, ...statusList]);
        if (quoteId === "Add") {
          setDetailForm((prev: any) => {
            return { ...prev, ["quoteStatusId"]: statusList[0].id.toString() };
          });
        }
      } else {
        setErrorMessage(statusList);
      }
    } catch (error) {
      setErrorMessage(error);
      console.error(error);
    }
  };
  const fetchInductionList = async () => {
    try {
      const inductionListData: any = await fetchBundle("fetchQuoteInductions");
      if (inductionListData) {
        setInductionList(inductionListData);
      } else {
        setErrorMessage(inductionListData);
      }
    } catch (error) {
      setErrorMessage(error);
      console.error(error);
    }
  };
  const fetchListData = async (type: string) => {
    try {
      let URL: string = "";
      if (type === "customer") {
        URL = `${Constants.apiURL}${Constants.customersURL}`;
      }
      if (type === "project") {
        URL = `${Constants.apiURL}${Constants.projectsURL}`;
      }
      const response = await HttpService.get(
        URL,
        { headers: { Authorization: "Bearer " + getAdmin() } },
        { search: "", limit: -1, offset: 0 }
      );
      if (response.success) {
        if (type === "customer") {
          let customerData: any = response.data.paginateRecords;
          setCustomerList(customerData);
          setCustomerListOptions(
            customerData.map((data: any) => ({ id: data.id, name: data.name }))
          );
          if (quoteId !== "Add") {
            const customerCurrentRecord: any = customerData.find(
              (item: any) =>
                item.id.toString() === currentRecord.customerId.toString()
            );

            if (customerCurrentRecord) {
              setCurrentCustomer({
                id: customerCurrentRecord.id,
                name: customerCurrentRecord.name,
              });
            }
          }
        }
        if (type === "project") {
          const projectData: any = response.data.paginateRecords;
          setProjectTemplateList(projectData);
          if (quoteId !== "Add") {
            const projectDataRecord: any = projectData.find(
              (item: any) =>
                item.id.toString() ===
                currentRecord.projectTemplateId.toString()
            );
            if (projectDataRecord) {
              setCurrentProject(null);
              setCurrentProject({
                id: projectDataRecord.id,
                name: projectDataRecord.name,
              });
            }
          }
        }
      } else {
        setErrorMessage("Failed to Fetch List. Please try again.");
      }
      console.log("GET Response:", response);
    } catch (error) {
      setErrorMessage("Failed to Find List. Please try again.");
      console.error("Error fetching data:", error);
    }
  };
  const fetchBundle = async (type: string) => {
    return new Promise(async (resolve, reject) => {
      try {
        const URL = `${Constants.apiURL}${Constants.refBundleURL}/${type}`;
        const response = await HttpService.get(
          URL,
          { headers: { Authorization: "Bearer " + getAdmin() } },
          { search: "", limit: -1, offset: 0 }
        );
        if (response.success) {
          resolve(response.data);
        } else {
          reject("Failed to Fetch List. Please try again.");
        }
        console.log("GET Response:", response);
      } catch (error) {
        reject("Failed to Find List. Please try again.");
        console.error("Error fetching data:", error);
      }
    });
  };
  const handleInductions = (inductionListData: any) => {
    if (inductionListData) {
      if (inductionList.length > 0) {
        const inductionListDataArr = inductionListData.split(",").map(Number);
        return inductionList.filter((item) =>
          inductionListDataArr.includes(item.id)
        );
      } else {
        return [];
      }
    } else {
      return [];
    }
  };
  const fetchStandardTerms = async () => {
    try {
      const response = await HttpService.get(
        Constants.apiURL + Constants.termsURL,
        { headers: { Authorization: `Bearer ${getAdmin()}` } },
        { search: "", limit: -1, offset: 0 }
      );
      if (response.success) {
        setStandardTermsList(response.data.paginateRecords);
      }
    } catch (error) {}
  };
  const fetchAdhocTerms = async () => {
    try {
      const response = await HttpService.get(
        Constants.apiURL + Constants.adhocTermsURL,
        { headers: { Authorization: `Bearer ${getAdmin()}` } },
        { search: "", limit: -1, offset: 0 }
      );
      if (response.success) {
        setAdhocTermsList(response.data.paginateRecords);
      }
    } catch (error) {}
  };

  // useEffect(() => {
  //     if (currentProject) {
  //         if (currentProject.id) {
  //             fetchProject(currentProject.id.toString());
  //         }
  //     }
  // }, [currentProject]);
  useEffect(() => {
    if (isInitialLoad.current) {
      isInitialLoad.current = false;
      loadDataList();
    }
  }, [quoteId]);
  useEffect(() => {
    if (currentRecord) {
      if (quoteId !== "Add") {
        handleQuote(currentRecord);
        if (customerList.length === 0) fetchListData("customer");
        if (projectTemplateList.length === 0) fetchListData("project");
      }
    }
  }, [currentRecord]);
  const loadDataList = async () => {
    setIsLoading(true);
    if (inductionList.length === 0) await fetchInductionList();
    if (quoteId !== "Add") {
      await fetchData(quoteId);
    }
    if (quoteStatusList.length === 0) await fetchStatusList();

    if (quoteId === "Add") {
      if (customerList.length === 0) fetchListData("customer");
      if (projectTemplateList.length === 0) fetchListData("project");
    }
    if (adhocTermsList.length === 0) await fetchAdhocTerms();
    if (standardTermsList.length === 0) await fetchStandardTerms();
    if (billableList.length === 0) await getBillableList();
    if (companyDetails === null) {
      await fetchCompany();
      setIsLoading(false);
    }
  };
  const handleQuote = async (quoteData: any) => {
    setProjectForm({
      name: quoteData.projectName,
      notes: "",
      cost: quoteData.quoteSubmissionPrice,
      id: null,
    });
    setDetailForm({
      quoteStatusId: quoteData.quoteStatusId.toString(),

      customerId: quoteData.customerId.toString(),
      customerEmail: quoteData.customerEmail,
      mainContact: quoteData.customerMainContact,
      contactNumber: quoteData.customerContactNumber,
      id: quoteData.id,
      projectName: quoteData.projectName,
      projectWorkCategory: quoteData.projectWorkCategory,
      projectSiteAddress: quoteData.projectSiteAddress,
      estimateStartDate: quoteData.estimateStartDate,
      submissionDueDate: quoteData.submissionDueDate,
      validToDate: quoteData.quoteValidToDate,
      quoteInductionIds: handleInductions(quoteData.quoteInductionIds),
      projectTemplateId: quoteData.projectTemplateId.toString(),
      internalNotes: quoteData.internalNotes,
      updateVar: quoteData.quoteInductionIds,
    });
    if (quoteData.customerId) {
      console.log(customerList);
      const customerIndex: any = customerList.findIndex(
        (item: any) => item.id.toString() === quoteData.customerId.toString()
      );
      console.log(customerIndex);
      if (customerIndex !== -1) {
        //console.log(customerList[customerIndex]);
        setCurrentCustomer({
          id: customerList[customerIndex].id,
          name: customerList[customerIndex].name,
        });
      }
    }
    if (quoteData.projectTemplateId) {
      const projectIndex: any = projectTemplateList.findIndex(
        (item: any) =>
          item.id.toString() === quoteData.projectTemplateId.toString()
      );
      if (projectIndex !== -1) {
        setCurrentProject(projectTemplateList[projectIndex]);
      }
    }
    if(quoteData.standardTerms){
        if(quoteData.standardTerms.length > 0) {
            setCurrentStandardTermsList([]); //clear first then update to reflect changes
            setCurrentStandardTermsList(quoteData.standardTerms);
        }
    }
    setSelectedAdhocTerms(quoteData.adhocTerms);
    setSelectedQuoteExclusion(quoteData.quoteExclusions);
    setSelectedQuoteInclusion(quoteData.quoteInclusions);
    setProjectPercentage(quoteData.projectMarginPercentage);
    setFileQuoteMediaCurrent(quoteData.medias);
    setBillableFormItems(quoteData.billables);
    calculateTotalCost(quoteData.billables);

    if (quoteData.billables.length > 0) {
      if (quoteId !== "Add") {
        const filteredArray = quoteData.billables.filter(
          (item: any) => item.isExclusionSelected
        );
        console.log(JSON.stringify(filteredArray), "->>>>>>>>>");
        setSelectedExclusions(filteredArray);
      }
    }

    setOverheads(quoteData.overheads ? parseFloat(quoteData.overheads) : 0);
    setForcasts(
      quoteData.contigencies ? parseFloat(quoteData.contigencies) : 0
    );
    if (quoteData.quoteStatusId === 1) {
      setActiveStep(0);
    } else {
      setActiveStep(maxSteps - 1);
    }
  };
  const fetchData = async (id: any) => {
    try {
      try {
        const response = await HttpService.get(
          Constants.apiURL + Constants.quoteURL + "/" + id,
          { headers: { Authorization: "Bearer " + getAdmin() } }
        );
        if (response.success) {
          if (response.quote) {
            setCurrentRecord(response.quote);
          }
        } else {
          setErrorMessage("Failed to fetch quote Please try again.");
        }

        console.log("GET Response:", response);
      } catch (error) {
        setErrorMessage("Failed to add tender. Please try again.");
        console.error("Error fetching data:", error);
      }
    } catch (error) {
      setErrorMessage(Constants.COMMON_SERVER_ERROR);
    }
  };
  const validateFields = () => {
    if (!detailForm.customerId) {
      setErrorMessage("Customer  is required field");
      setErrorType("error");
      setAlertOpen(true);
      return false;
    }
    if (!detailForm.projectName) {
      setErrorMessage("Project Name  is required field");
      setErrorType("error");
      setAlertOpen(true);
      return false;
    }
    if (!detailForm.mainContact) {
      setErrorMessage("Customer Conatct Name  is required field");
      setErrorType("error");
      setAlertOpen(true);
      return false;
    }
    if (!detailForm.customerEmail) {
      setErrorMessage("Email ID  is required field");
      setErrorType("error");
      setAlertOpen(true);
      return false;
    }
    if (!detailForm.contactNumber) {
      setErrorMessage("Customer Contact Number  is required field");
      setErrorType("error");
      setAlertOpen(true);
      return false;
    }
    return true;
  };
  const handleNext = () => {
    if (activeStep === 0) {
      if (!validateFields()) {
        return false;
      }
    }
    if (activeStep === 2) {
      // if (!detailForm.projectTemplateId) {
      //     setErrorMessage("Project Template  is required field");
      //     setErrorType('error');
      //     setAlertOpen(true);
      //     return false;
      // }
      if (billableFormItems.length === 0) {
        setErrorMessage("Please atleast one billable");
        setErrorType("error");
        setAlertOpen(true);
        return false;
      }
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const handleConfirmDialog = async (type: string) => {
    if (type === "Yes") {
      setConfirmDialogOpen(false);
      if (quoteId === "Add") {
        if (validateFields()) await handleFinalSave(1);
        navigate(`/quote`);
      } else {
        setConfirmDialogOpen(false);
        navigate(`/quote`);
      }

      //handleClose();
      //setConfirmDialogOpen(false);
      clearForm();
    } else {
      setConfirmDialogOpen(false);
      return false;
    }
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const handleAttached = async (
    index: number,
    detailIndex: number,
    mediaIndex: number
  ) => {
    billableFormItems[index]["resources"][detailIndex]["items"][0][
      "isAttached"
    ] = 1;
    billableFormItems[index]["resources"][detailIndex]["items"][0]["media"] =
      billableFormItems[index]["resources"][detailIndex]["items"][0]["medias"][
        mediaIndex
      ];
  };
  const handleArrow = async (index: number) => {
    setExpandRowState((prevItems: any) =>
      prevItems.includes(index)
        ? prevItems.filter((item: any) => item !== index)
        : [...prevItems, index]
    );
  };
  const handleBillableDetailChange = (
    value: any,
    index: number,
    type: string,
    detailIndex: number
  ) => {
    setBillableFormItems((prevItems) => {
      // Create a copy of the current state
      const updatedItems = [...prevItems];
      // Update the specific item's property
      updatedItems[index]["resources"][detailIndex]["items"][0] = {
        ...updatedItems[index]["resources"][detailIndex]["items"][0],
        [type]: value,
      };
      calculateTotalCost(updatedItems);
      return updatedItems;
    });
  };

  const removeDetailsItem = (index: any, detailIndex: any) => {
    // console.log(detailIndex,'>>>>>>>>>>>>>>>>>>>>>>>');
    // setBillableDetailsRemovedItems((prevItems) => {
    //     const newItems = [...prevItems];
    //     if (!newItems.includes(billableFormItems[index]['resources'][detailIndex])) {
    //         newItems.push(billableFormItems[index]['resources'][detailIndex]);
    //     }
    //     return newItems;
    // });

    setBillableFormItems((prevItems) => {
      // Create a deep copy of the state array
      const newItems = prevItems.map((item, i) => {
        if (i === index) {
          return {
            ...item,
            resources: item.resources.filter(
              (_: any, dIndex: any) => dIndex !== detailIndex
            ),
          };
        }
        return item;
      });
      if (newItems[index].resources.length === 0) {
        removeItem(index);
      }
      calculateTotalCost(newItems); // Call your function to calculate total cost
      return newItems; // Return the updated array
    });
  };
  const calculateTotalCost = (newItems: any) => {
    // console.log(JSON.stringify(newItems),'<<<<<<<>>>>>>>>>>>>>><<<>>>>>>>>>>>>>');

    let totalCost = newItems.reduce((acc: number, item: any) => {
      let itemCost = item.resources.reduce((detailAcc: number, detail: any) => {
        return (
          detailAcc +
          parseInt(detail["items"][0].quantity) *
            parseFloat(detail["items"][0].price)
        );
      }, 0);
      return acc + itemCost;
    }, 0);
    console.log(totalCost,'---');
    setProjectForm((prev: any) => {
      return { ...prev, ["cost"]: isNaN(totalCost) ? 0.0 : totalCost };
    });
  };
  const removeItem = (index: any) => {
    setBillableFormItems((prevItems) => {
      const newItems = [...prevItems];
      newItems.splice(index, 1);
      calculateTotalCost(newItems);
      return newItems;
    });
    /* setBillableRemovedItems((prevItems) => {
             const newItems = [...prevItems];
             if (!newItems.includes(billableFormItems[index])) {
                 newItems.push(billableFormItems[index]);
             }
             return newItems;
         });*/
  };
  const fetchProject = async (id: string) => {
    try {
      const response = await HttpService.get(
        `${
          Constants.apiURL + Constants.billableURL
        }/getProjectBillableWithResources/${id}`,
        { headers: { Authorization: `Bearer ${getAdmin()}` } },
        null
      );
      if (response.success) {
        handleProjectRecord(response.data);
      } else {
        setErrorMessage("Failed to fetch record. Please try again.");
      }
    } catch (error) {
      setErrorMessage("Failed to fetch record. Please try again.");
    }
  };
  // Fetch single term record
  const fetchCompany = async () => {
    try {
      const response = await HttpService.get(
        `${Constants.apiURL + Constants.companyProfileURL}`,
        { headers: { Authorization: `Bearer ${getAdmin()}` } },
        null
      );
      if (response.data) {
        setCompanyDetails(response.data);
        if (response.data === null) {
          setErrorMessage("Please complete complete profile");
          setAlertOpen(true);
          setErrorType("error");
        }
      } else {
        //setErrorMessage("Failed to fetch record. Please try again.");
      }
    } catch (error) {
      //setErrorMessage("Failed to fetch record. Please try again.");
    }
  };
  const handleProjectRecord = (record: any) => {
    // console.log(record);
    // console.log(quoteId, "--------");
    // console.log(currentProject);
    //setCurrentProjectRecord(record.project);
    // if (record && currentProject && quoteId!=="Add") {
    //     if (currentProject) {
    //         if (currentProject.id !== record.projectTemplateId) {
    //             handleBillables(record.billables);
    //         }
    //     } else {
    //         handleBillables(record.billables);
    //     }

    // }
    if (record.projectTemplateId) {
      handleBillables(record.billables);
    }
    //setProjectForm({ name: record.name, id: record.projectTemplateId, cost: record.cost, notes: '' });
  };
  const handleBillables = (billables: any) => {
    let billableItemsData: any[] = [];
    if (billableFormItems.length === 0) {
      setBillableFormItems(billables);
      calculateTotalCost(billables);
      return;
    } else {
      billableItemsData = billableFormItems;
      for (var i = 0; i < billables.length; i++) {
        const indexData = billableItemsData.findIndex(
          (item: any) => item.id.toString() === billables[i].id.toString()
        );
        if (indexData === -1) {
          billableItemsData.push(billables[i]);
          continue;
        }
      }
      setBillableFormItems(billableItemsData);
      calculateTotalCost(billableItemsData);
      return;
    } //this needs to confirm
  };

  const handleFileChange = (files: any) => {
    if (files) {
      setFileQuoteMedia((prevFiles: any) => [
        ...prevFiles,
        ...Array.from(files),
      ]);
    }
  };

  const handleChangeDate = (value: any, name: string) => {
    const DateValue =
      value.year() + "-" + (value.month() + 1) + "-" + value.date();
    setDetailForm((prev: any) => {
      return { ...prev, [name]: DateValue };
    });
  };
  const handleRemoveFile = (file: any) => {};
  const handleCurrentFilesRemove = (fileData: any) => {
    const removedFiles = deletedQuoteFiles;
    const updatedFiles = fileQuoteMediaCurrent.filter(
      (file: any) => file !== fileData
    ); // Create a new array
    setFileQuoteMediaCurrent(updatedFiles);
    removedFiles.push(fileData);
    setDeletedQuoteFiles(removedFiles);
  };

  const getBillableList = async () => {
    try {
      const response = await HttpService.get(
        Constants.apiURL +
          Constants.billableURL +
          "/getAllBillableWithResourcesAndMedias",
        { headers: { Authorization: "Bearer " + getAdmin() } },
        {}
      );
      if (response.success) {
        const billableRecords = response.data.billables;
        if (billableRecords.length > 0) {
          setBillableList(billableRecords);
          setCurrentBillable(billableRecords[0]);
        }
      } else {
        setErrorMessage("No records found");
      }
      console.log("GET Response:", response);
    } catch (error) {
      setErrorMessage("Error fetching data");
      console.error("Error fetching data:", error);
    }
  };
  const handleAutoCompleteChange = (
    e: any,
    dataValue: any,
    typeData: string
  ) => {
    if (typeData === "customerId") {
      setCurrentCustomer({ id: dataValue.id, name: dataValue.name });
      const currentData = customerList.find(
        (item: any) => item.id.toString() === dataValue.id.toString()
      );

      setDetailForm((prev: any) => ({
        ...prev,
        mainContact: currentData.tradingName.toString(),
        customerEmail: currentData.email,
        contactNumber: currentData.phone,
        customerId: dataValue.id.toString(),
      }));
    }
    if (typeData === "projectTemplateId") {
      setCurrentProject(dataValue);
      fetchProject(dataValue.id.toString());
      setDetailForm((prev: any) => {
        return { ...prev, [typeData]: dataValue.id.toString() };
      });
    }
  };
  const handleInductionChange = (e: any, dataValue: any) => {
    setDetailForm((prev: any) => {
      return { ...prev, ["quoteInductionIds"]: dataValue };
    });
  };
  const handleDetailInputChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLFormElement
    >
  ) => {
    const { name, value } = e.target;

    setDetailForm((prev: any) => {
      return { ...prev, [name]: value };
    });
  };

  const handleBillableAdd = (e: any, dataValue: any) => {
    setCurrentBillable(dataValue);
  };

  const handleAlert = (state: boolean) => {
    setAlertOpen(false);
    setErrorMessage("");
  };
  const AddBillableItem = () => {
    const index: any = billableList.findIndex(
      (item: any) => item.id.toString() === currentBillable.id.toString()
    );
    console.log(billableList[index]);
    if (index !== -1) {
      const indexData = billableFormItems.findIndex(
        (item: any) => item.id.toString() === currentBillable.id.toString()
      );
      if (indexData === -1) {
        setBillableFormItems((prevItems) => {
          const newItems = [...prevItems];
          if (!newItems.includes(billableList[index])) {
            newItems.push(billableList[index]);
          }
          calculateTotalCost(newItems);
          return newItems;
        });
      }
    }
    setBillableDialogOpen(false);
  };
  const handleSave = async (reqData: any) => {
    const endpoint = `${Constants.apiURL + Constants.adhocTermsURL}`;
    let response: any;
    try {
      response = await HttpService.post(endpoint, reqData, {
        headers: { Authorization: `Bearer ${getAdmin()}` },
      });
      if (response.success) {
        if (reqData.isActive) {
          handleTermSelect([response.terms], "old");
        }
      } else {
        setErrorMessage(Constants.COMMON_SERVER_ERROR);
      }
    } catch (error) {
      setErrorMessage(Constants.COMMON_SERVER_ERROR);
    } finally {
    }
  };
  const handleQuoteIncluExclusionSelect = (rowData: any, type: string) => {
    if (type === "inclusion") {
      setSelectedQuoteInclusion(rowData);
    }
    if (type === "exclusion") {
      setSelectedQuoteExclusion(rowData);
    }
  };
  const handleBillableExclusionSelect = (rowData: any) => {
    const currentIndex = selectedExclusions.indexOf(rowData);
    const newChecked = [...selectedExclusions];

    if (currentIndex === -1) {
      newChecked.push(rowData);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setSelectedExclusions(newChecked);
  };
  const handleTermSelect = (rowData: any, type: string) => {
    if (type === "new") {
      handleSave(rowData);
    } else {
      setSelectedAdhocTerms((prev) => {
        const existingIds = new Set(prev.map((prevRow) => prevRow.id));
        const filteredRows = rowData.filter(
          (row: any) => !existingIds.has(row.id)
        );
        return [...prev, ...filteredRows];
      });
    }
  };
  const getSubmissionPrice = () => {
    console.log(projectForm.cost);
    console.log(forcasts);
    console.log(overheads);
    const subTotal =
      (overheads ?? 0) +
      (forcasts ?? 0) +
      (projectForm.cost ? parseFloat(projectForm.cost) : 0);
    // console.log(subTotal);
    const percentage = (projectPercentage * subTotal) / 100;
    return subTotal + percentage;
  };
  const getTotalQuotePrice = () => {
    const finalTotal = getSubmissionPrice() + getGST();
    return finalTotal ?? "0";
  };
  const getStatusText = (quoteStatusId: any) => {
    const statusData: any = quoteStatusList.find(
      (item: any) => item.id.toString() === quoteStatusId.toString()
    );
    if (statusData) {
      return statusData.name;
    } else {
      return quoteStatusId;
    }
  };

  const getInductionIDS = (inductionId: any[]) => {
    if (inductionId) return inductionId.map((item: any) => item.id).join(",");
    else return "";
  };
  const getGST = () => {
    // const subTotal = overheads + forcasts + parseFloat(projectForm.cost);
    const GSTTotal = (getSubmissionPrice() * GST) / 100;
    return GSTTotal ?? 0;
  };
  const clearForm = () => {
    setActiveStep(0);
    setCurrentCustomer(null);
    setCurrentProject(null);
    setDetailForm({
      quoteStatusId: "1",

      customerId: "",
      customerEmail: "",
      mainContact: "",
      contactNumber: "",
      id: null,
      projectName: "",
      projectWorkCategory: "",
      projectSiteAddress: "",
      estimateStartDate: getCurrentDate(),
      submissionDueDate: getCurrentDate(),
      validToDate: getCurrentDate(),
      quoteInductionIds: "",
      projectTemplateId: "",
      internalNotes: "",
      updateVar: null,
    });
    setProjectForm({
      name: "",
      notes: "",
      cost: "",
      id: null,
    });
    setFileQuoteMedia([]);
    setFileQuoteMediaCurrent([]);

    setSelectedAdhocTerms([]);
    setSelectedExclusions([]);
    setSelectedQuoteInclusion("");
    setBillableFormItems([]);
    setSelectedQuoteExclusion("");
    setInProgress(false);
    setErrorMessage("Success!");
    setErrorType("success");
    setAlertOpen(true);
    navigate("/quote");
    // handleClose();
  };
  const handleDownloadFile = async (ID: any) => {
    const media: any = await getMediaRecord(ID);
    if (media) {
      window.open(media, "_blank");
    }
  };
  const getMediaRecord = async (id: any) => {
    return new Promise(async (resolve, reject) => {
      const response = await HttpService.get(
        Constants.apiURL + Constants.mediaURL + "/" + id,
        { headers: { Authorization: "Bearer " + getAdmin() } },
        null
      );
      if (response.success) {
        resolve(response.media);
      } else {
        resolve(null);
      }
    });
  };

  const handleFinalSummary = async (type: any) => {
    if (type === -1) {
      setWarningButtonTitle("Delete");
      setWarningMessage(
        "Are you sure you want to delete this quote? This action cannot be undone."
      );
      setWarningMessageTitle("Delete Quote");
    }
    if (type === 0) {
      setWarningButtonTitle("Yes");
      setWarningMessage(
        "If you cancel, your changes will not be saved. Do you want to continue?"
      );
      setWarningMessageTitle("Cancel");
    }
    if (type === 1) {
      setWarningButtonTitle("Save");
      setWarningMessage("Are you sure you want to Save as a Draft?");
      setWarningMessageTitle("Save Draft");
    }
    if (type === 2) {
      setWarningButtonTitle("Send");
      setWarningMessage("Are you sure you want to send this for approval?");
      setWarningMessageTitle("Send for Internal Approval");
    }
    if (type === 3) {
      setWarningButtonTitle("Approve");
      setWarningMessage("Are you sure you want to approve this quote?");
      setWarningMessageTitle("Approve");
    }
    if (type === 4) {
      setWarningButtonTitle("Send to Client");
      setWarningMessage("Are you sure you want to send this quote to the client?");
      setWarningMessageTitle("Send to Client");
    }
    if (type === 5) {
      setWarningButtonTitle("Reject");
      setWarningMessage(
        "Are you sure you want to reject this quote and send it back to the estimator for updates? Please select a reason for rejection."
      );
      setWarningMessageTitle("Reject");
    }
    if (type === 6) {
      setWarningButtonTitle("Quote Accepted");
      setWarningMessage("Are you sure this quote has been accepted by the client? The system will now convert it into a project.");
      setWarningMessageTitle("Client Accepted Quote");
    }
    if (type === 7) {
      setWarningButtonTitle("Quote Rejected");
      setWarningMessage("Are you sure you want to mark this quote as rejected? Please provide a reason for the rejection.");
      setWarningMessageTitle("Client Rejected Quote");
    }
    if (type === 8) {
        setWarningButtonTitle("Revision");
        setWarningMessage("Are you sure you want to create a revision for this quote?.");
        setWarningMessageTitle("Revision");
      }

    setWarningDialogOpen(true);
    return;
  };
  const handleWarningClose = async (type: any,reasons?: any[]) => {
    if (type === "Yes") {
      //Cancel the quote without saving it
      setWarningDialogOpen(false);
      setInfoDialogOpen(true);
      setInfoMessageTitle("Changes have been discarded.");

      return;
    }
    if (type === "Delete") {
      //Delete the quote without saving it
      setWarningDialogOpen(false);
      await deleteQuote(quoteId);

      return;
    }
    if (type === "Cancel") {
      setWarningDialogOpen(false);
    }
    //save as draft
    if (type === "Save") {
      handleFinalSave(1);
      setWarningDialogOpen(false);
    }

    //send for internal approve
    if (type === "Send") {
      handleFinalSave(2);
      setWarningDialogOpen(false);
    }
    //internal approved
    if (type === "Approve") {
      handleFinalSave(3);
      setWarningDialogOpen(false);
    }
    //internal rejected
    if (type === "Reject") {
      handleFinalSave(5);
      setWarningDialogOpen(false);
      if(reasons)
         setSelectedRejectReasons(reasons);
    }
    //Send to client only if internal approved
    if (type === "Send to Client") {
      handleFinalSave(4);
      setWarningDialogOpen(false);
    }
    //Customer accepted quote
    if (type === "Quote Accepted") {
      handleFinalSave(6);
      setWarningDialogOpen(false);
    }
    //Customer rejected quote
    if (type === "Quote Rejected") {
      handleFinalSave(7);
      setWarningDialogOpen(false);
      if(reasons)
        setSelectedRejectReasons(reasons);
      
    }
    if (type === "Revision") {
        handleFinalSave(8);
        setWarningDialogOpen(false);
        
        
      }
  };
  const handleFinalSave = async (type: any) => {
    if(type === 8){
        setInfoDialogOpen(true);
        setInfoMessageTitle(infoSuccessMessages[type]);
        return false;
    }
    const quoteDataForm: any = {
      quoteStatusId: type,

      customerId: currentCustomer.id,
      id: detailForm.id,
      customerName: currentCustomer.name,
      customerEmail: detailForm.customerEmail,
      customerMainContact: detailForm.mainContact,
      customerContactNumber: detailForm.contactNumber,
      projectName: detailForm.projectName,
      projectWorkCategory: detailForm.projectWorkCategory,
      projectSiteAddress: detailForm.projectSiteAddress,
      estimateStartDate: detailForm.estimateStartDate,
      submissionDueDate: detailForm.submissionDueDate,
      quoteInductionIds: getInductionIDS(detailForm.quoteInductionIds),
      projectTemplateId: currentProject ? currentProject.id : 0,
      quoteValidToDate: detailForm.validToDate,
      projectMarginPercentage: projectPercentage,
      quoteSubmissionPrice: getSubmissionPrice() ?? "0",
      quoteGSTPrice: getGST(),
      quoteTotalPrice: getTotalQuotePrice(),
      internalNotes: detailForm.internalNotes,
      quoteInclusions: selectedQuoteInclusion,
      quoteExclusions: selectedQuoteExclusion,
      adhocTerms: [],
      billables: {},
      overheads: overheads ? overheads.toString() : "0",
      contigencies: forcasts ? forcasts.toString() : "0",
    };
    if (quoteId !== "Add") {
      quoteDataForm["medias"] = fileQuoteMediaCurrent;
      if(type === 5 || type === 7){
        quoteDataForm["selectedReasons"] = selectedRejectReasons;
      }
    }
    
    // console.log(quoteDataForm);
    // return false;
    let billableData: any = [];
    for (var i = 0; i < billableFormItems.length; i++) {
      var resourceDetails = billableFormItems[i]["resources"];

      //  billableDataForm[i]['resources'] = {};
      var lineTotal = 0;
      const detailsData = [];
      for (var j = 0; j < resourceDetails.length; j++) {
        lineTotal +=
          parseFloat(resourceDetails[j]["items"][0]["price"]) *
          parseInt(resourceDetails[j]["items"][0]["quantity"]);
        // console.log(resourceDetails[j]['resourceTypeId'], '------->>>>>>');
        detailsData.push({
          resourcesId:
            resourceDetails[j]["resourcesId"] ?? resourceDetails[j]["id"],
          name: resourceDetails[j]["name"],
          expiryDate: resourceDetails[j]["expiryDate"],
          isQuote: resourceDetails[j]["isQuote"],
          resourceTypeId: resourceDetails[j]["resourceTypeId"],
          unitTypeId: resourceDetails[j]["unitTypeId"],
          resourceTotalPrice: (
            parseFloat(resourceDetails[j]["items"][0]["price"]) *
            parseInt(resourceDetails[j]["items"][0]["quantity"])
          ).toString(),
          items: [
            {
              resourceDetailId:
                resourceDetails[j]["items"][0]["resourceDetailId"] ??
                resourceDetails[j]["items"][0]["id"],
              contractorId: resourceDetails[j]["items"][0]["contractorId"],
              notes: resourceDetails[j]["items"][0]["Note2"],
              isAttached: resourceDetails[j]["items"][0]["isAttached"]
                ? resourceDetails[j]["items"][0]["isAttached"]
                : 0, //todo
              resourceItemsTotalPrice: (
                parseFloat(resourceDetails[j]["items"][0]["price"]) *
                parseInt(resourceDetails[j]["items"][0]["quantity"])
              ).toString(),
              price: resourceDetails[j]["items"][0]["price"],
              quantity: parseInt(resourceDetails[j]["items"][0]["quantity"]),
              media: resourceDetails[j]["items"][0]["isAttached"]
                ? resourceDetails[j]["items"][0]["medias"][0]
                : null,
            },
          ],
        });
      }
      var billableExclusion = selectedExclusions.find(
        (item) => item.id === billableFormItems[i]["id"]
      );
      billableData.push({
        billableItemId:
          billableFormItems[i]["billableItemId"] ?? billableFormItems[i]["id"], //This is refrence ID of master billbel table
        name: billableFormItems[i]["name"],
        quantity: parseInt(billableFormItems[i]["quantity"]),
        unitTypeId: billableFormItems[i]["unitTypeId"]
          ? billableFormItems[i]["unitTypeId"]
          : 1,
        exclusion: billableFormItems[i]["exclusion"],
        isExclusionSelected: billableExclusion ? 1 : 0,
        billableTotalPrice: lineTotal.toString(),
        resources: detailsData,
      });
    }
    if (selectedAdhocTerms.length > 0) {
      quoteDataForm["adhocTerms"] = selectedAdhocTerms.map(
        ({ id, adhocTermId, termText }) => ({
          adhocTermId: adhocTermId ?? id,
          termText: termText,
        })
      );
    }

    quoteDataForm["billables"] = billableData;
    console.log(quoteDataForm);
    const endpoint = `${Constants.apiURL + Constants.quoteURL}`;
    let response: any;
    try {
      setInProgress(true);
      response = await HttpService.post(endpoint, quoteDataForm, {
        headers: { Authorization: `Bearer ${getAdmin()}` },
      });
      if (response.success) {
        for (var k = 0; k < fileQuoteMedia.length; k++) {
          await handleFileUpload(fileQuoteMedia[k], "quote", response.quote.id);
        }
        setInfoDialogOpen(true);
        setInfoMessageTitle(infoSuccessMessages[type]);
        //clearForm(); ̰
      } else {
        setErrorType("error");
        setErrorMessage(Constants.COMMON_SERVER_ERROR);
        setAlertOpen(true);

        setInProgress(false);
      }
    } catch (error: any) {
      console.log(error.response.data.error);
      setErrorMessage(
        typeof error.response.data.error !== undefined
          ? error.response.data.error
          : Constants.COMMON_SERVER_ERROR
      );
      setErrorType("error");
      setAlertOpen(true);
      setInProgress(false);
    } finally {
    }
  };
  
  const handleFileUpload = async (
    file: any,
    relationType: any,
    relationId: any
  ) => {
    return new Promise(async (resolve, reject) => {
      try {
        if (file) {
          setInProgress(true);
          const formData = new FormData();
          formData.append("file", file);
          formData.append("relationType", relationType);
          formData.append("relationId", relationId);

          const response = await HttpService.postWithFileUpload(
            Constants.apiURL + Constants.mediaURL,
            formData,
            {
              headers: {
                Authorization: "Bearer " + getAdmin(),
                "Content-Type": "multipart/form-data",
              },
            }
          );
          if (response.success) {
            resolve(response.media);
          } else {
            setErrorMessage("Failed to add Media. Please try again.");
            setAlertOpen(true);
            resolve(false);
          }
          console.log("GET Response:", response);
        }
      } catch (error) {
        resolve(false);
        setErrorMessage("Failed to add Media. Please try again.");
        setAlertOpen(true);
        console.error("Error fetching data:", error);
      }
      setInProgress(false);
    });
  };
  const deleteQuote = async (quoteId: any) => {
    try {
      const response = await HttpService.delete(
        Constants.apiURL + Constants.quoteURL + "/" + quoteId,
        { headers: { Authorization: "Bearer " + getAdmin() } },
        {}
      );
      if (response.success) {
        setInfoDialogOpen(true);
        setInfoMessageTitle("The quote has been deleted.");
      } else {
      }
      console.log("GET Response:", response);
    } catch (error) {
      setErrorMessage("Error fetching data");
      console.error("Error fetching data:", error);
    }
  };
  // dialog Content for Billable List
  const dialogBillableContent = () => {
    return (
      <div className="row mb-3 mt-2">
        <div style={{ width: "400px" }}>
          <SimpleAutoCompleteTextFieldComponent
            name="billableAutoSelect"
            value={currentBillable}
            isRequired={false}
            errorStr={""}
            keyName="billableAutoSelect"
            label="Billable Items"
            onChange={handleBillableAdd}
            items={billableList}
          />
        </div>
        <div className="col">
          <ButtonComponent
            keyName={"addBtn"}
            errors={[]}
            onClick={AddBillableItem}
            label={"Add"}
          />
        </div>
      </div>
    );
  };
  return (
    <div>
      <div style={{ margin: "20px" }}>
        <div
          onClick={() => setConfirmDialogOpen(true)}
          style={{ cursor: "pointer", display: "flex", alignItems: "center" }}
        >
          <Typography variant="h6">
            <UTurnLeftIcon
              style={{
                transform: "rotate(90deg)",
                cursor: "pointer",
                marginTop: "4px",
              }}
              onClick={() => {
                setConfirmDialogOpen(true);
              }}
            />
          </Typography>
          <Typography variant="h6">Back</Typography>
        </div>

        <Paper
          elevation={3}
          sx={{
            backgroundColor: "#ffffff",
          }}
        >
          {isLoading && (
            <Box sx={{ width: "100%", mt: 3 }}>
              <LinearProgress />
            </Box>
          )}
          <Typography
            variant="h6"
            style={{ paddingLeft: "30px", paddingTop: "30px" }}
          >
            {quoteId !== "Add" ? "Update Quote" : "Add New Quote"}
          </Typography>

          <Box sx={{ padding: "10px", mb: 2, width: "60%", margin: "0 auto" }}>
            <Stepper activeStep={activeStep} alternativeLabel>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel
                    slotProps={{
                      stepIcon: {
                        sx: {
                          "&.Mui-completed": {
                            color: "#000B29", // Change the color of completed steps
                          },
                          "&.Mui-active": {
                            color: "#00F4FF14", // Active step background color
                            "& .MuiStepIcon-text": {
                              color: "#000000",
                              fill: "#000000", // Active step number color (Step circle)
                            },
                          },
                        },
                      },
                    }}
                  >
                    {label}
                  </StepLabel>
                </Step>
              ))}
            </Stepper>

            <Box>
              {isLoading === false && activeStep === 0 && (
                <DetailStep
                  currentCustomer={currentCustomer}
                  customerList={customerListOptions}
                  detailForm={detailForm}
                  handleAutoCompleteChange={handleAutoCompleteChange}
                  handleChangeDate={handleChangeDate}
                  handleDetailInputChange={handleDetailInputChange}
                  inductionList={inductionList}
                  handleInductionChange={handleInductionChange}
                />
              )}
              {isLoading === false && activeStep === 1 && (
                <UploadStep
                  fileQuoteMedia={fileQuoteMedia}
                  handleFileChange={handleFileChange}
                  handleRemoveFile={handleRemoveFile}
                  fileQuoteMediaCurrent={fileQuoteMediaCurrent}
                  handleOpen={(id: any) => {
                    handleDownloadFile(id);
                  }}
                  handleCurrentFilesRemove={handleCurrentFilesRemove}
                />
              )}
              {isLoading === false && activeStep === 2 && (
                <PricingStepSummary
                  GST={GST}
                  billableFormItems={billableFormItems}
                  companyDetails={companyDetails}
                  currentProject={currentProject}
                  expandRowState={expandRowState}
                  handleArrow={handleArrow}
                  handleAutoCompleteChange={handleAutoCompleteChange}
                  handleBillableDetailChange={handleBillableDetailChange}
                  handleBillableDialog={(open: boolean) => {
                    setBillableDialogOpen(open);
                  }}
                  projectForm={projectForm}
                  projectTemplateList={projectTemplateList}
                  removeDetailsItem={removeDetailsItem}
                  removeItem={removeItem}
                  viewOnly={false}
                  forcasts={forcasts}
                  overheads={overheads}
                  handleForecasts={(val: number) => {
                    setForcasts(val);
                  }}
                  handleOverHeads={(val: number) => {
                    setOverheads(val);
                  }}
                  projectPercentage={projectPercentage}
                  handleProjectPercentage={(val: number) => {
                    setProjectPercentage(val);
                  }}
                  handleAttached={handleAttached}
                  handleOpen={handleDownloadFile}
                />
              )}
              {isLoading === false && activeStep === 3 && (
                <TermsStepTab
                  selectedExclusions={selectedExclusions}
                  adhocTermsList={adhocTermsList}
                  handleTermSelect={handleTermSelect}
                  termsList={standardTermsList}
                  currentStandardTermsList={currentStandardTermsList}
                  billableFormItems={billableFormItems}
                  handleBillableExclusionSelect={handleBillableExclusionSelect}
                  selectedAdhocTerms={selectedAdhocTerms}
                  selectedQuoteInclusion={selectedQuoteInclusion}
                  selectedQuoteExclusion={selectedQuoteExclusion}
                  handleQuoteIncluExclusionSelect={
                    handleQuoteIncluExclusionSelect
                  }
                />
              )}
              {isLoading === false && activeStep === 4 && (
                <ReviewFinalStep
                  detailForm={detailForm}
                  currentCustomer={currentCustomer}
                  currentProject={currentProject}
                  projectForm={projectForm}
                  fileQuoteMedia={fileQuoteMedia}
                  billableFormItems={billableFormItems}
                  companyDetails={companyDetails}
                  expandRowState={expandRowState}
                  projectTemplateList={projectTemplateList}
                  selectedAdhocTerms={selectedAdhocTerms}
                  selectedQuoteInclusion={selectedQuoteInclusion}
                  selectedQuoteExclusion={selectedQuoteExclusion}
                  termsList={standardTermsList}
                  currentStandardTermsList={currentStandardTermsList}
                  selectedExclusions={selectedExclusions}
                  overheads={overheads}
                  forcasts={forcasts}
                  projectPercentage={projectPercentage}
                  GST={GST}
                  status={getStatusText(detailForm.quoteStatusId)}
                  induction={detailForm.quoteInductionIds}
                ></ReviewFinalStep>
              )}
            </Box>
          </Box>
          {inProgress && (
            <Box sx={{ width: "100%", textAlign: "center" }}>
              <LinearProgress />
            </Box>
          )}
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "25px",
              background: "#F3F6F9",
            }}
          >
            <Button
              variant="contained"
              sx={{ color: "#fff !important" }}
              onClick={handleBack}
              disabled={
                activeStep === 0 ||
                (quoteId !== "Add" &&
                  detailForm.quoteStatusId !== "1" &&
                  detailForm.quoteStatusId !== "2")
                  ? true
                  : false
              }
            >
              Back
            </Button>
            

            
              <Box
                style={{ display: "flex", flexDirection: "row", gap: "24px" }}
              >
                <Box   >
              {(detailForm.quoteStatusId == "1") && (
                <Typography component={"span"}>
                  <Button
                    color="secondary"
                    sx={{ marginRight: "10px" }}
                    variant="contained"
                    onClick={() => {
                      handleFinalSummary(0);
                    }}
                    disabled={isLoading ? true : false}
                  >
                    {"Cancel"}
                  </Button>
                  {quoteId !== "Add" && (
                    <Button
                      color="secondary"
                      sx={{ marginRight: "10px" }}
                      variant="contained"
                      onClick={() => {
                        handleFinalSummary(-1);
                      }}
                      disabled={isLoading ? true : false}
                    >
                      {"Delete"}
                    </Button>
                  )}
                </Typography>
              )}
              {(quoteId === "Add" ||
                detailForm.quoteStatusId == "1" ||
                detailForm.quoteStatusId == "1") &&
                activeStep < 4 && (
                  <Button
                    color="secondary"
                    sx={{ marginRight: "10px" }}
                    variant="contained"
                    onClick={() => {
                      if (validateFields()) {
                        handleFinalSummary(1);
                      }
                    }}
                    disabled={inProgress ? true : false}
                  >
                    {inProgress ? "Saving..." : "Save Draft"}
                  </Button>
                )}
           
              {activeStep < 4 && (
                <Button
                  color="primary"
                  sx={{ color: "#fff !important" }}
                  variant="contained"
                  onClick={handleNext}
                  disabled={activeStep === maxSteps - 1 ? true : false}
                >
                  Next
                </Button>
              )}
            </Box>
                {activeStep === 4 && <Box>

                {/* status == draft or in inital step then display this button */}
                {detailForm.quoteStatusId === "1" && (
                  <Button
                    color="secondary"
                    variant="contained"
                    onClick={() => {
                      handleFinalSummary(1);
                    }}
                    disabled={inProgress ? true : false}
                  >
                    {inProgress ? "Saving..." : "Save Draft"}
                  </Button>
                )}
                {/* status == draft or in inital step then display this button */}
                {detailForm.quoteStatusId === "1" && (
                  <Button
                    disabled={inProgress ? true : false}
                    color="secondary"
                    variant="contained"
                    onClick={() => {
                      handleFinalSummary(2);
                    }}
                  >
                    {inProgress ? "Saving..." : "Send for Approval"}
                  </Button>
                )}

                {/* status  === review then display this button */}
                {detailForm.quoteStatusId === "2" && (
                  <Button
                    disabled={inProgress ? true : false}
                    color="secondary"
                    variant="contained"
                    onClick={() => {
                      handleFinalSummary(3);
                    }}
                  >
                    {inProgress ? "Saving..." : "Approve"}
                  </Button>
                )}
                {/* status  === review then display this button */}

                {/* status  === Approved then display this button */}
                {detailForm.quoteStatusId === "3" && (
                  <Button
                    disabled={inProgress ? true : false}
                    color="secondary"
                    variant="contained"
                    onClick={() => {
                      handleFinalSummary(4);
                    }}
                  >
                    {inProgress ? "Saving..." : "Send to Client"}
                  </Button>
                )}

                {/* status  === Sent to Client then display this button */}

                {detailForm.quoteStatusId === "4" && (
                  <Button
                    disabled={inProgress ? true : false}
                    color="secondary"
                    variant="contained"
                    onClick={() => {
                      handleFinalSummary(6);
                    }}
                  >
                    {inProgress ? "Saving..." : "Accept"}
                  </Button>
                )}

                {/* status  ===Rejeced then display this button */}
                {detailForm.quoteStatusId === "5" && (
                  <Button
                    disabled={inProgress ? true : false}
                    color="secondary"
                    variant="contained"
                    onClick={() => {
                      handleFinalSummary(8);
                    }}
                  >
                    {inProgress ? "Saving..." : "Create Revision"}
                  </Button>
                )}
                {/* status  === review then display this button */}
                {(detailForm.quoteStatusId === "3" ||
                  detailForm.quoteStatusId === "2") && (
                  <Button
                    disabled={inProgress ? true : false}
                    color="secondary"
                    variant="contained"
                    onClick={() => {
                      handleFinalSummary(5);
                    }}
                  >
                    {inProgress ? "Saving..." : "Reject"}
                  </Button>
                )}
                {/* status  === review then display this button */}
                {detailForm.quoteStatusId === "4" && (
                  <Button
                    disabled={inProgress ? true : false}
                    color="secondary"
                    variant="contained"
                    onClick={() => {
                      handleFinalSummary(7);
                    }}
                  >
                    {inProgress ? "Saving..." : "Reject"}
                  </Button>
                )}
                </Box>}
              </Box>
            
          </div>
        </Paper>
      </div>
      <AlertMessageComponent
        type={errorType}
        open={alertOpen}
        handleClose={handleAlert}
        message={errorMessage}
      />
      {
        <SimpleDialogComponent
          title={"Billables"}
          dialogContent={dialogBillableContent()}
          open={billableDialogOpen}
          handleClose={() => {
            setBillableDialogOpen(false);
          }}
        />
      }
      <ConfirmDialog
        handleClose={handleConfirmDialog}
        message="Are you sure, you want to exit?"
        open={confirmDialogOpen}
      ></ConfirmDialog>
      <WarningDialog
        handleClose={handleWarningClose}
        message={warningMessage}
        open={warningDialogOpen}
        buttonTitle={warningButtonTitle}
        title={warningMessageTitle}
      ></WarningDialog>
      <InfoDialog
        handleClose={() => {
          setInfoDialogOpen(false);
          navigate("/quote");
        }}
        open={infoDialogOpen}
        title={infoMessageTitle}
      ></InfoDialog>
    </div>
  );
};
export default QuoteDialogComponent;
