import * as React from 'react';
import Grid from "@mui/material/Grid2";
import { List, ListItem, ListItemText, ListItemIcon, Checkbox, IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import { Button } from "@mui/material";
import TextFieldAreaComponent from '../../../components/form/TextFieldAreaComponent';

interface QuoteIncluExclusionSubStepProps {
  selectedQuoteInclusion: any;
  selectedQuoteExclusion: any;
  handleQuoteIncluExclusionSelect: (selected: any, type: string) => void;
}

const QuoteIncluExclusionSubStep: React.FC<QuoteIncluExclusionSubStepProps> = ({ selectedQuoteInclusion, selectedQuoteExclusion, handleQuoteIncluExclusionSelect }) => {
  const [currentOption, setCurrentOption] = React.useState('list');
  const [currentText, setCurrentText] = React.useState('');

  const exclusionSave = () => {
    if (currentOption === 'Exclusion') {
      handleQuoteIncluExclusionSelect(currentText, 'exclusion');
    }
    if (currentOption === 'Inclusion') {
      handleQuoteIncluExclusionSelect(currentText, 'inclusion');
    }
    setCurrentOption('list');
  }
  const manageSection = (type: any) => {
    if (type === 'Exclusion') {
      setCurrentText(selectedQuoteExclusion);
    }
    if (type === 'Inclusion') {
      setCurrentText(selectedQuoteInclusion);
    }
    setCurrentOption(type);

  }
  const handleChange = (e: any) => {
    setCurrentText(e.target.value);
  }
  return (

    <Grid container spacing={2} style={{ marginTop: "30px" }}>
      {/* Section Header */}


      {/* Browse File  Field */}
      <Grid size={12}>
        {currentOption === 'list' && <List>
          <ListItem sx={{
            display: "list-item",
            width: '350px'

          }} key={'quote_inclusion_name_list_'}
            secondaryAction={
              <IconButton edge="end" onClick={() => {
                manageSection('Inclusion');
              }}>
                <EditIcon />
              </IconButton>
            }
          >
            <ListItemText sx={{ fontWeight: 'bold' }}>
              Quote Inclusions

            </ListItemText>
            {selectedQuoteInclusion && <ListItem sx={{
              display: "inline-flex",

            }} key={'quote_inclusion_list_'}>

              <ListItemText sx={{ fontWeight: 'bold' }}>
                {selectedQuoteInclusion}
              </ListItemText>
            </ListItem>}
          </ListItem>
          <ListItem sx={{
            display: "list-item",
             width: '350px'

          }} key={'quote_exclusion_name_list_'} secondaryAction={
            <IconButton edge="end" aria-label="comments" onClick={() => {
              manageSection('Exclusion');
            }}>
              <EditIcon />
            </IconButton>
          }>
            <ListItemText sx={{ fontWeight: 'bold' }} >
              Quote Exclusions

            </ListItemText>
            {selectedQuoteExclusion && <ListItem sx={{
              display: "inline-flex",

            }} key={'quote_exclusion_list_'}

            >

              <ListItemText sx={{ fontWeight: 'bold' }}>
                {selectedQuoteExclusion}
              </ListItemText>
            </ListItem>}
          </ListItem>


        </List>}
        {currentOption !== 'list' && <Grid size={12}>
          <TextFieldAreaComponent
            name={currentOption}
            errorStr=''
            isRequired={false}
            keyName='txtareaquotetext'
            label={`Quote ${currentOption}`}
            minRows={5}
            onChange={handleChange}
            value={currentText}

          />
          <Button variant="contained" sx={{ color: '#ffffff !important' }} startIcon={<AddIcon  sx={{ color: '#ffffff !important' }}  />} className="primarybtn" onClick={() => {
            exclusionSave();
          }}>
            Save
          </Button>
        </Grid>}
      </Grid>





    </Grid>

  );
}
export default QuoteIncluExclusionSubStep;