"use client";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useStore } from "../utils/store";
import FilterComponent from "./SearchComponent";
import PageTitle from "./PageTitle";
import Grid from "@mui/material/Grid2";
import { Box, Button, Popover, Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

interface HeaderProps {
  onSearch: (event: any) => void;

  links?: any[];
}
const Header: React.FC<HeaderProps> = ({ onSearch, links }) => {
  const navigate = useNavigate();
  const { setIsAdmin, getLoginUsername } = useStore();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  function logOut() {
    setIsAdmin("");
    navigate("/");
  }

  return (
    <Box id="header" className="header" sx={{ py: 2 }}>
      <Grid container spacing={2} alignItems="center">
        {" "}
        {/* Center elements vertically */}
        {/* Logo Section */}
        <Grid size={2}>
          <Link to="/dashboard" className="logo">
            <img src="../../assets/img/loumac-logo.png" alt="Logo" />
          </Link>
          <i className="bi bi-list toggle-sidebar-btn"></i>
        </Grid>
        {/* Page Title Section */}
        <Grid size={4}>
          <PageTitle key={links ? links[0].title + "_" : ""} links={links} />
        </Grid>
        {/* Filter Component Section */}
        <Grid size={2}>
          <FilterComponent onSearch={onSearch} />
        </Grid>
        <Grid size={4} display="flex" alignItems={"center"} justifyContent={"flex-end"} paddingRight={"30px"}>
          <IconButton type="button" sx={{ p: "10px", color: "#ffffff" }} aria-label="inbox">
            <MailOutlineIcon />
          </IconButton>
          <IconButton type="button" sx={{ p: "10px", color: "#ffffff" }} aria-label="inbox">
            <NotificationsOutlinedIcon fontVariant="" />
          </IconButton>
          <Button sx={{ marginTop: "7px" }} onClick={handleClick}>
            <Grid container spacing={2} display="flex" color="#fff" justifyContent="flex-end" alignItems="center">
              <Grid sx={{ flex: "0 1 auto", paddingRight: "30px" }}>
                <AccountCircleIcon sx={{ color: "#fff" }} />
              </Grid>
              <Grid sx={{ flex: "1 1 auto", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>{getLoginUsername() !== "" ? getLoginUsername() : "Admin"}</Grid>
              <Grid sx={{ flex: "0 1 auto" }}>
                <ExpandMoreIcon sx={{ color: "#fff" }} />
              </Grid>
            </Grid>
          </Button>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
          >
            <Button sx={{ p: 2, bgcolor: "#000b29", color: "#fff" }} onClick={logOut}>
              Logout
            </Button>
          </Popover>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Header;
