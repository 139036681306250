"use client";
import React, { useEffect, useState } from "react";

import SideBar from "../../../components/Sidebar";
import Header from "../../../components/Header";
import PageTitle from "../../../components/PageTitle";
import Constants from "../../../utils/contants";
import HttpService from "../../../services/HttpService";
import { useStore } from "../../../utils/store";
import { useNavigate, useParams } from "react-router-dom";
import TextFieldAreaComponent from "../../../components/form/TextFieldAreaComponent";
import PrimaryButtonComponent from "../../../components/form/PrimaryButtonComponent";
import ResetButtonComponent from "../../../components/form/ResetButtonComponent";
import TextFieldComponent from "../../../components/form/TextFieldComponent";
import { Box, Button, Divider, IconButton, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import ButtonComponent from "../../../components/form/ButtonComponent";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import AddIcon from '@mui/icons-material/Add';
import Grid from "@mui/material/Grid2";
import SimpleDialogComponent from "../../../components/SimpleDialogComponent";
import SimpleAutoCompleteTextFieldComponent from "../../../components/form/SimpleAutoCompleteTextFieldComponent";

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
const ProjectForm: React.FC = () => {
  const { getAdmin } = useStore();
  const { currentId } = useParams<{ currentId: any }>();
  const navigate = useNavigate();
  // State variables
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [currentBillable, setCurrentBillable] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [currentRecord, setCurrentRecord] = useState<any>(null);
  const [billableList, setBillableList] = useState<any[]>([]);
  const [billableFormItems, setBillableFormItems] = useState<any[]>([]);
  const [expandRowState, setExpandRowState] = useState<any[]>([]);
  const [billableRemovedItems, setBillableRemovedItems] = useState<any[]>([]);
  const [billableDetailsRemovedItems, setBillableDetailsRemovedItems] = useState<any[]>([]);
  const [projectForm, setProjectForm] = useState(
    {
      name: "",
      notes: "",
      cost: "",
      unit: 'Each',
      id: null
    }
  );
  const [errors, setErrors] = useState({
    name: "",
    cost: "",
  });
  const handleArrow = async (index: number) => {
    setExpandRowState((prevItems) =>
      prevItems.includes(index)
        ? prevItems.filter((item) => item !== index)
        : [...prevItems, index]
    );
  }
  // Fetch single term record
  const fetchSingleRecord = async (id: string) => {
    try {
      const response = await HttpService.get(
        `${Constants.apiURL + Constants.projectsURL}/${id}`,
        { headers: { Authorization: `Bearer ${getAdmin()}` } },
        null
      );
      if (response.success) {
        handleRowClick(response.data);
      } else {
        setErrorMessage("Failed to fetch record. Please try again.");
      }
    } catch (error) {
      setErrorMessage("Failed to fetch record. Please try again.");
    }
  };
  // Fetch data when component loads or dependencies change
  useEffect(() => {
    if (currentId) {
      if (currentId !== "Add") {
        fetchSingleRecord(currentId);
      }
    } else {

      setCurrentRecord(null);
    }

    if (billableList.length === 0) {
      getBillableList();
    }
  }, [currentId]);


  const getBillableList = async () => {
    try {
      const response = await HttpService.get(
        Constants.apiURL + Constants.billableURL + '/getBillableWithResources',
        { headers: { Authorization: "Bearer " + getAdmin() } },
        {},
      );
      if (response.success) {
        const billableRecords = response.data;
        if (billableRecords.length > 0) {
          setBillableList(billableRecords);
          setCurrentBillable(billableRecords[0])
        }
      } else {
        setErrorMessage("No records found");
      }
      console.log("GET Response:", response);
    } catch (error) {
      setErrorMessage("Error fetching data");
      console.error("Error fetching data:", error);
    }
  };

  // Handle form input change
  const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setProjectForm((prevData) => ({ ...prevData, [name]: value }));
    validateField(e.target);

  };


  const handleBillableDetailChange = (value: any, index: number, type: string, detailIndex: number) => {
    setBillableFormItems((prevItems) => {
      // Create a copy of the current state
      const updatedItems = [...prevItems];
      // Update the specific item's property
      updatedItems[index]['details'][detailIndex] = { ...updatedItems[index]['details'][detailIndex], [type]: value };
      calculateTotalCost(updatedItems);
      return updatedItems;
    });
  };


  const AddBillableItem = (
  ) => {
    const index: any = billableList.findIndex((item: any) => item.id.toString() === currentBillable.id.toString());
    console.log(billableList[index]);
    if (index !== -1) {
      setBillableFormItems((prevItems) => {
        const newItems = [...prevItems];
        if (!newItems.includes(billableList[index])) {
          newItems.push(billableList[index]);
        }
        calculateTotalCost(newItems);
        return newItems;
      });
    }
    setDialogOpen(false);
  }
  const removeDetailsItem = (
    index: any, detailIndex: any) => {
    // console.log(detailIndex,'>>>>>>>>>>>>>>>>>>>>>>>');
    setBillableDetailsRemovedItems((prevItems) => {
      const newItems = [...prevItems];
      if (!newItems.includes(billableFormItems[index]['details'][detailIndex])) {
        newItems.push(billableFormItems[index]['details'][detailIndex]);
      }
      return newItems;
    });

    setBillableFormItems((prevItems) => {
      // Create a deep copy of the state array
      const newItems = prevItems.map((item, i) => {
        if (i === index) {
          return {
            ...item,
            details: item.details.filter((_: any, dIndex: any) => dIndex !== detailIndex),
          };
        }
        return item;
      });
      if (newItems[index].details.length === 0) {
        removeItem(index);
      }
      calculateTotalCost(newItems); // Call your function to calculate total cost
      return newItems; // Return the updated array
    })
  }
  const removeItem = (
    index: any) => {
    setBillableFormItems((prevItems) => {
      const newItems = [...prevItems];
      newItems.splice(index, 1);
      calculateTotalCost(newItems);
      return newItems;
    });
    setBillableRemovedItems((prevItems) => {
      const newItems = [...prevItems];
      if (!newItems.includes(billableFormItems[index])) {
        newItems.push(billableFormItems[index]);
      }
      return newItems;
    });
  }

  const validateField = async (element: any) => {
    const elementName = element.name;
    const newErrors: any = {};
    switch (elementName) {
      case 'name':
        if (!element.value.trim()) {
          newErrors.name = "Name is required.";
        }
        break;
      case 'cost':
        if (!element.value.trim()) {
          newErrors.cost = "Price is required.";
        }
        break;
    }
    setErrors(newErrors);
    // Return true if no errors
    return Object.keys(newErrors).length === 0;
  }
  const validateForm = () => {
    const newErrors: any = {};

    // Name Validation
    if (!projectForm.name.trim()) {
      newErrors.name = "Name is required.";
    }
    if (!projectForm.cost) {
      newErrors.cost = "Price is required.";
    }
    setErrors(newErrors);
    // Return true if no errors
    return Object.keys(newErrors).length === 0;
  };
  const calculateTotalCost = (
    newItems: any) => {
    let totalCost = newItems.reduce((acc: number, item: any) => {
      let itemCost = item.details.reduce((detailAcc: number, detail: any) => {
        return detailAcc + parseInt(detail.quantity) * parseFloat(detail.cost);
      }, 0);
      return acc + itemCost;
    }, 0);
    setProjectForm((prev: any) => {
      return { ...prev, ['cost']: isNaN(totalCost) ? 0.00 : totalCost };
    });
  }
  const handleBillableAdd = (
    e: any, dataValue: any
  ) => {
    setCurrentBillable(dataValue);

  };
  // dialog Content for Billable List
  const dialogContent = () => {
    return <div className="row mb-3 mt-2">
      <div style={{ width: '400px' }}>
        <SimpleAutoCompleteTextFieldComponent
          name="billableAutoSelect"
          value={currentBillable}
          isRequired={false}
          errorStr={''}
          keyName="billableAutoSelect"
          label="Billable Items"
          onChange={handleBillableAdd}
          items={billableList}
        />

      </div>
      <div className="col">
        <ButtonComponent
          keyName={"addBtn"}
          errors={errors}
          onClick={AddBillableItem}
          label={'Add'}
        />
      </div>
    </div>;
  }
  // Handle record creation or update
  const handleSave = async () => {

    if (validateForm()) {
      setLoading(true);
      const req: any = {
        name: projectForm.name,
        price: projectForm.cost,
        notes: projectForm.notes,
        unit: projectForm.unit,
        billableFormItems: billableFormItems
      };
      const endpoint =
        `${Constants.apiURL + Constants.projectsURL}`
      let response: any;
      try {

        if (currentRecord) {
          // console.log(billableDetailsRemovedItems);
          // setLoading(false);
          // return false;

          req['id'] = currentRecord.id;
          req['billableRemovedItems'] = billableRemovedItems;
          req['billableDetailsRemovedItems'] = billableDetailsRemovedItems;
          response = await HttpService.put(endpoint, req, {
            headers: { Authorization: `Bearer ${getAdmin()}` },
          });
        } else {
          response = await HttpService.post(endpoint, req, {
            headers: { Authorization: `Bearer ${getAdmin()}` },
          });
        }
        if (response.success) {
          clearForm(false);
        } else {
          setErrorMessage(Constants.COMMON_SERVER_ERROR);
        }
      } catch (error) {
        setErrorMessage(Constants.COMMON_SERVER_ERROR);
      } finally {
        setLoading(false);
      }
    }

  };

  const getLineTotal = (itemData: any) => {
    var lineTotal: number = 0;
    if (itemData.details.length > 0) {
      lineTotal = 0;
      for (var i = 0; i < itemData.details.length; i++) {
        lineTotal = lineTotal + (parseFloat(itemData.details[i].cost) * parseInt(itemData.details[i].quantity))
      }
      return lineTotal.toFixed(2);
    } else {
      return 0.00;
    }
  }
  // Clear form and reset state
  const clearForm = (isForm: boolean) => {
    setProjectForm({ name: "", id: null, cost: '', notes: '', unit: 'Each' });
    setErrorMessage("");
    setCurrentRecord(null);
    setBillableFormItems([]);
    setBillableRemovedItems([]);
    setBillableDetailsRemovedItems([]);
    navigate("/management/projects");
  };

  const handleRowClick = (record: any) => {

    setCurrentRecord(record.project);
    setProjectForm({ name: record.project.name, id: record.project.id, cost: record.project.cost, notes: record.project.notes, unit: record.project.unit });
    setBillableFormItems(record.billable);

    setErrors({
      name: "",
      cost: "",
    });

  };

  return (
    <Stack>
      <Header onSearch={() => { }}  links={[{title:"Home",link:"/dashboard"}, {title:"Data Management",link:"/management"}, {title:"Project Template",link:""}]}/>
      <SideBar />
      <Box className="mainForm dashboardmain">
        
      <Paper sx={{ padding: '50px' }}>
      <h1>{currentId !== 'Add' ? 'Update Project Template' : 'Add New Project Template'}</h1>
          
             
      <Grid container spacing={2} style={{ width: '100%', textAlign: "left" }}>
        <Grid size={12}>

     
                <TextFieldComponent
                  label="Project Template  Name"
                  keyName="projectTemplateName"
                  name="name"
                  value={projectForm.name}
                  onChange={handleInputChange}
                  errorStr={errors.name}
                  isRequired={true}
                />
                   </Grid>
                
             


                {/* Billable Items Starts from here  */}
                <Grid size={12} sx={{textAlign: 'right'}}>
                <Typography variant="h6">Total Price:$ {parseFloat(projectForm.cost ? projectForm.cost : '0').toFixed(2)}</Typography>
                </Grid>
                <Grid size={12}>
                  <Button variant="outlined" startIcon={<AddIcon />} className="primarybtn" onClick={() => {
                    setDialogOpen(true);
                  }}>
                    Add Billable Description
                  </Button>
                  {billableFormItems.length > 0 && <TableContainer sx={{mt:2}}>
                    <Table sx={{ '& > *': { borderRight: '1px solid #ddd', borderTop: '1px solid #ddd' } }}>
                     
                      {billableFormItems.map((item: any, index: any) => {
                       
                        return <TableBody key={'tblbody_' + index}>
                          {index === 0 && <TableRow key={'headrow_' + index} sx={{ '& > *': { borderRight: '1px solid #ddd', borderLeft: '1px solid #ddd' } }}>
                            <TableCell key={'tblheadclldesc_' + index} className="boldfont">Billable Description</TableCell>
                            <TableCell key={'tblheadcllitemc_' + index} className="boldfont">Resource Items</TableCell>
                            <TableCell key={'tblbotblheadclliqty_' + index} className="boldfont" style={{ width: '70px' }}>Quanity</TableCell>
                            <TableCell key={'tblheadcllcost_' + index} className="boldfont" style={{ width: '150px' }}>Cost ($)</TableCell>
                            <TableCell key={'tblheadcluom_' + index} className="boldfont" style={{ width: '100px' }}>UOM</TableCell>
                            <TableCell key={'tblheadclttl_' + index} className="boldfont" style={{ width: '120px' }} colSpan={2}>Total ($)</TableCell>
                          </TableRow>}
                          <TableRow key={'bodyrow_' + index} sx={{ '& > *': { borderRight: '1px solid #ddd', borderLeft: '1px solid #ddd' } }}>
                            <TableCell key={'bodyrow_cell_name' + index}>
                              {expandRowState.indexOf(index) !== -1 && <KeyboardArrowDownIcon style={{cursor:'pointer'}} onClick={() => {
                                handleArrow(index);
                              }} />}
                              {expandRowState.indexOf(index) === -1 && <ExpandLessIcon style={{cursor:'pointer'}} onClick={() => {
                                handleArrow(index);    
                              }} />}
                              {item.name}
                             
                            </TableCell>
                            <TableCell key={'bodyrow_cell_blnk' + index}></TableCell>
                            <TableCell key={'bodyrow_cell_qty' + index}>1</TableCell>
                            <TableCell key={'bodyrow_cell_lst_blnk' + index}>

                            </TableCell>
                            <TableCell key={'bodyrow_cell_lst_each' + index}>Each</TableCell>
                            <TableCell key={'bodyrow_cell_lst_linettl' + index}>{getLineTotal(item)}</TableCell>
                            <TableCell key={'bodyrow_cell_lst_rmitem_main' + index} style={{ width: '50px' }}>

                              <IconButton key={'bodyrow_cell_lst_rmitem_main_icn_btn' + index} aria-label="delete" onClick={() => {
                                removeItem(index);
                              }}>
                                <DeleteForeverIcon key={'bodyrow_cell_lst_rmitem_main_icn_btn_icon' + index} />
                              </IconButton>


                            </TableCell>

                          </TableRow>
                          {item['details'].map((detailItem: any, detailIndex: any) => {
                            return <TableRow className={expandRowState.indexOf(index) !== -1 ? 'hidden' : ''} key={'bodyrow_dtl_' + index + '_' + detailIndex} sx={{ '& > *': { borderRight: '1px solid #ddd', borderLeft: '1px solid #ddd' } }}>
                              <TableCell key={'bodyrow_dtl_cell_blnk-' + index + '_' + detailIndex}></TableCell>
                              <TableCell key={'bodyrow_dtl_cell_name-' + index + '_' + detailIndex}>{detailItem.name}</TableCell>
                              <TableCell key={'bodyrow_dtl_cell_qty-' + index + '_' + detailIndex} > <TextFieldComponent
                                label=""
                                keyName={'qtyFormBillable_' + index}
                                name={'qtyFormBillable_' + index}
                                value={detailItem.quantity}
                                onChange={(event) => {
                                  handleBillableDetailChange(event.target.value, index, 'quantity', detailIndex)
                                }}
                                errorStr={''}
                                type="number"
                                isRequired={false}
                                style={{ width: '60px' }}
                              /></TableCell>
                              <TableCell key={'bodyrow_dtl_cell_cost-' + index + '_' + detailIndex}>
                                <TextFieldComponent
                                  label=""
                                  keyName={'costFormBillable_' + index}
                                  name={'costFormBillable_' + index}
                                  value={detailItem.cost}
                                  onChange={(event: any) => {
                                    handleBillableDetailChange(event.target.value, index, 'cost', detailIndex)
                                  }}
                                  errorStr={''}
                                  type="number"
                                  isRequired={false}
                                  style={{ width: '80px' }}
                                />
                              </TableCell>
                              <TableCell key={'bodyrow_dtl_cell_unit-' + index + '_' + detailIndex}>{detailItem.unit}</TableCell>
                              <TableCell key={'bodyrow_dtl_cell_qty-ttl-' + index + '_' + detailIndex}>{parseFloat(detailItem.cost) * parseInt(detailItem.quantity)}</TableCell>
                              <TableCell style={{ width: '50px' }}>

                                <IconButton key={'bodyrow_dtl_cell_delet-icn-' + index + '_' + detailIndex} aria-label="delete" onClick={() => {
                                  removeDetailsItem(index, detailIndex);
                                }}>
                                  <DeleteForeverIcon key={'bodyrow_dtl_cell_only-icn-' + index + '_' + detailIndex} />
                                </IconButton>


                              </TableCell>

                            </TableRow>
                          })}
                        </TableBody>
                      })}

                      {/* </TableBody> */}
                    </Table>
                  </TableContainer>}



                </Grid>
                {/* Billable Items ends here */}
              <Grid size={12}>
              <TextFieldAreaComponent
                  name="notes"
                  value={projectForm.notes}
                  onChange={handleInputChange}
                  minRows={7}
                  keyName="notes"
                  errorStr={''}
                  isRequired={false}
                  label="Notes"
                />
              </Grid>
                


              <Grid
              size={12}
              style={{ textAlign: "right" }}
            >
                  
                  <ResetButtonComponent
                    keyName="rstBtn"
                    onClear={() => {
                      clearForm(false);
                    }}
                  />
                  <PrimaryButtonComponent
                    loading={loading}
                    onSave={handleSave}
                    errors={[]}
                    keyName="ProjectMainBtn"
                    currentRecord={currentRecord}
                  />
                  {errorMessage && <p className="text-danger">{errorMessage}</p>}
                </Grid>
              </Grid>
          
          {<SimpleDialogComponent title={'Billables'} dialogContent={dialogContent()} open={dialogOpen} handleClose={() => {
            setDialogOpen(false)
          }} />}
        </Paper>

      </Box>
    </Stack>
  );
};

export default ProjectForm;
