import { Box } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
interface DatePickerComponentProps {
  onChange: (event: any) => void;
  name: string;
  defaultValue: string;
  label: string;
  isRequired: boolean;
  keyName: string;
  errorStr: string;
}
const DatePickerComponent: React.FC<DatePickerComponentProps> = ({
  onChange,
  name,
  defaultValue,
  isRequired,
  label,
  keyName,
  errorStr,
}) => {
  return (
    <Box sx={{ mb: 3 }} key={"ele_" + keyName}>
      <Box sx={{ mb: 1 }} className="form-label" key={"lbk_" + keyName}>
        {label}
        {isRequired && (
          <small key={"sml_" + keyName} style={{ color: "red" }}>
            {" "}
            *
          </small>
        )}
      </Box>
      <Box>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            className="custom-dt-picker"
            format="MMM DD, YYYY"
            name={name}
            value={dayjs(defaultValue)}
            views={["year", "month", "day"]}
            onChange={onChange}
          />
        </LocalizationProvider>
      </Box>
      {errorStr && <span className="bad-field">{errorStr}</span>}
    </Box>
  );
};
export default DatePickerComponent;
