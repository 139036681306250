import * as React from 'react';
import Grid from "@mui/material/Grid2";
import TextFieldComponent from '../../../components/form/TextFieldComponent';
import TextFieldAreaComponent from '../../../components/form/TextFieldAreaComponent';
import SelectFieldComponent from '../../../components/form/SelectFieldComponent';
import SimpleAutoCompleteTextFieldComponent from '../../../components/form/SimpleAutoCompleteTextFieldComponent';
import DatePickerComponent from '../../../components/DatePickerComponent';
import AutoCompleteTextFieldComponent from '../../../components/form/AutoCompleteTextFieldComponent';
interface DetailStepProps {
  detailForm: any;
  currentCustomer: any;
  customerList: any[]
  inductionList: any[]
  handleDetailInputChange: (
    event: any
  ) => void;
  handleAutoCompleteChange: (
    e: any, dataValue: any, typeData: string
  ) => void;
  handleInductionChange: (
    e: any, dataValue: any
  ) => void;
  handleChangeDate: (
    value: any, name: string
  ) => void;
}
const DetailStep: React.FC<DetailStepProps> = ({ detailForm, currentCustomer, inductionList, customerList, handleDetailInputChange, handleAutoCompleteChange, handleChangeDate,handleInductionChange }) => {
  const [currentCustomerData, setCurrentCustomerData] = React.useState<any>(customerList.find((item: any) => item.id.toString() === detailForm.customerId.toString()));
  const handleCustomerChange = (e: any, value: any) => {
    setCurrentCustomerData(value);
    handleAutoCompleteChange(e, value, 'customerId');
  }
  

  return (
    (<Grid container spacing={2} style={{ marginTop: "30px", marginBottom: '50px' }}>
      {/* Section Header */}
      <Grid size={12}>
        <h1 className="form-label">{'Details'}</h1>
        <h2 className="form-label">{'Customer Details'}</h2>
      </Grid>
      <Grid size={6}>

        <SimpleAutoCompleteTextFieldComponent
          name="customer"
          value={currentCustomerData}
          isRequired={true}
          errorStr={''}
          keyName="res-customer"
          label="Customer"
          onChange={(e: any, value: any) => {
           // console.log(currentCustomer);
            handleCustomerChange(e, value);
          }}
          items={customerList}
        />
      </Grid>


      <Grid size={6}>
        <TextFieldComponent
          name="customerEmail"
          value={detailForm.customerEmail}
          isRequired={true}
          errorStr={''}
          keyName="customerEmail"
          label="Customer Email "
          onChange={handleDetailInputChange}
          placeholder=""
        />
      </Grid>

      <Grid size={6}>
        <TextFieldComponent
          name="mainContact"
          value={detailForm.mainContact}
          isRequired={true}
          errorStr={''}

          keyName="mainContact"
          label="Main Contact "
          onChange={handleDetailInputChange}
          placeholder=""
        />
      </Grid>


      <Grid size={6}>
        <TextFieldComponent
          name="contactNumber"
          value={detailForm.contactNumber}
          isRequired={true}
          errorStr={''}

          keyName="contactNumber"
          label="Main Contact Number "
          onChange={handleDetailInputChange}
          placeholder=""
        />
      </Grid>

      {/* Customer Sections Ends  */}
      {/* Project  Sections Starts  */}
      <Grid size={12}>
        <h6 className="form-label">{'Project Details'}</h6>
      </Grid>



      <Grid size={6}>
        <TextFieldComponent
          name="projectName"
          value={detailForm.projectName}
          isRequired={true}
          errorStr={''}
          keyName="projectName"
          label="Project Name"
          onChange={handleDetailInputChange}
          placeholder=""
        />
      </Grid>

      <Grid size={6}>
        <TextFieldComponent
          name="projectWorkCategory"
          value={detailForm.projectWorkCategory}
          isRequired={false}
          errorStr={''}

          keyName="projectWorkCategory"
          label="Category of work"
          onChange={handleDetailInputChange}
          placeholder=""
        />
      </Grid>


      <Grid size={12}>
        <TextFieldComponent
          name="projectSiteAddress"
          value={detailForm.projectSiteAddress}
          isRequired={false}
          errorStr={''}

          keyName="projectSiteAddress"
          label="Site Address"
          onChange={handleDetailInputChange}
          placeholder=""
        />
      </Grid>
      <Grid size={4}>
        <DatePickerComponent
          defaultValue={detailForm.estimateStartDate}
          errorStr=''
          isRequired={false}
          keyName='estimateStartDate'
          label='Estimated Start Date'
          name='estimateStartDate'
          onChange={(value: any) => {
            handleChangeDate(value, 'estimateStartDate')
          }}

        />
      </Grid>
      <Grid size={4}>
        <DatePickerComponent
          defaultValue={detailForm.submissionDueDate}
          errorStr=''
          isRequired={false}
          keyName='submissionDueDate'
          label='Quote Submission Due Date'
          name='submissionDueDate'
          onChange={(value: any) => {
            handleChangeDate(value, 'submissionDueDate')
          }}

        />
      </Grid>
      <Grid size={4}>
        <DatePickerComponent
          defaultValue={detailForm.validToDate}
          errorStr=''
          isRequired={false}
          keyName='validToDate'
          label='Quote Valid To'
          name='validToDate'
          onChange={(value: any) => {
            handleChangeDate(value, 'validToDate')
          }}

        />
      </Grid>
      <Grid size={12}>
        <AutoCompleteTextFieldComponent 
          label="Induction Required"
          isRequired={false}
          items={inductionList}
          keyName="quoteInductionIds"
          name="quoteInductionIds"
          value={detailForm.quoteInductionIds}
          onChange={handleInductionChange}
          errorStr=""
        />
      </Grid>

      <Grid size={12}>
        <TextFieldAreaComponent
          label="Internal Notes"
          isRequired={false}
          minRows={5}
          keyName="internalNotes"
          name="internalNotes"
          value={detailForm.internalNotes}
          onChange={handleDetailInputChange}
          errorStr=""
        />
      </Grid>

      {/* Projject Sections Ends  */}
    </Grid>
    )
  );
}
export default DetailStep;